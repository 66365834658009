import { Text } from '@chakra-ui/react'
import React from 'react'

type Props = {
  instructions: string
}

const PageInstructionParagraph = ({ instructions }: Props) => (
  <Text mb={5} fontSize={14} fontWeight={500}>
    {instructions}
  </Text>
)

export default PageInstructionParagraph
