import { extendTheme } from '@chakra-ui/react'

export const LightTheme = extendTheme({
  fonts: {
    body: 'Roboto',
    heading: 'Roboto'
  },
  colors: {
    black: '#39332D',
    primary: {
      500: '#F0710B',
      600: '#D0AB3C',
      700: '#C79C1D'
    },
    blue: {
      500: '#D4AF42'
    },
    gray: {
      100: 'black',
      200: '#F1EFE9',
      900: '#FAF9F7'
    },
    primaryBackground: '#FAF9F7',
    secondaryBackground: '#273045',
    white: {
      600: '#fff'
    },
    green: {
      400: '#009688',
      500: '#34c38f'
    }
  },
  components: {
    Button: {
      variants: {
        primary: {
          bg: '#D4AF42'
        }
      }
    },
    FormLabel: {
      baseStyle: {
        fontSize: 'sm',
        marginBottom: '1',
        color: '#fff'
      }
    },
    Input: {
      baseStyle: {
        color: '#fff'
      }
    }
  }
})
