import { AxiosResponse } from 'axios'
import { GetPaymentMethodsParams, PaymentMethodCryptoPriceResponse } from '../interfaces'
import { getInstance } from './config'
import { PaymentMethod } from '../models'

export const getPaymentMethods = (params?: GetPaymentMethodsParams): Promise<AxiosResponse<PaymentMethod[]>> => {
  return getInstance().get('/payment-methods', { params })
}

export const getPaymentMethodCryptoPricing = (id: number, productId?: number): Promise<AxiosResponse<PaymentMethodCryptoPriceResponse>> => {
  return getInstance().get(`/payment-methods/${id}/crypto-price`, { params: { productId } })
}
