/* eslint-disable camelcase */
import React from 'react'
import { Button, FormControl, FormErrorMessage, FormLabel, Input, Modal, ModalBody, ModalCloseButton, ModalContent, ModalFooter, ModalHeader, ModalOverlay, Select, Switch, Textarea, UseDisclosureProps, useToast } from '@chakra-ui/react'
import { Field, Form, Formik } from 'formik'
import { updateUser } from '../../../../api'
import { User } from '../../../../models'
import { accountsAddRoleToUser, accountsRemoveRoleToUser, accountsUpdateUserById } from '../../../../api/accounts'

type Props = {
  modal: UseDisclosureProps
  user: User
  reloadUsers?: boolean
  setReloadUsers?: (reload: boolean) => void
}

const AdminEditUserModal = ({ modal, user, reloadUsers, setReloadUsers }: Props) => {
  const toast = useToast()

  return (
    <Modal size="xl" isOpen={modal.isOpen || false} onClose={modal.onClose || (() => ({}))} closeOnOverlayClick={false} blockScrollOnMount={true}>
      <ModalOverlay />
      <ModalContent color="white" backgroundColor="secondaryBackground">
        <ModalHeader>Editar usuário</ModalHeader>
        <ModalCloseButton />
        <Formik
          initialValues={{
            name: user.name,
            email: user.email,
            reference_id: user.referenceId,
            password: '',
            is_admin: user.isAdmin
          }}
          validate={(values) => {
            const errors: {
              name?: string
              email?: string
              reference_id?: string
              is_admin?: boolean
            } = {}

            if (!values.name) {
              errors.name = 'Informe o nome'
            }

            if (!values.email) {
              errors.email = 'Informe o email'
            }

            return errors
          }}
          onSubmit={async (values, { setSubmitting }) => {
            setSubmitting(true)

            try {
              const { password, ...userPayload } = values
              await updateUser(user.id, userPayload)

              if (user.accountsUserId) {
                accountsUpdateUserById(user.accountsUserId, {
                  name: values.name,
                  email: values.email,
                  maxnivelCustomerId: values.reference_id,
                  password
                }).then(() => ({})).catch(() => ({}))

                if (values.is_admin) {
                  accountsAddRoleToUser(user.accountsUserId, '6080738ff5be6a7d3d7a2cdf').then(() => ({})).catch(() => ({}))
                } else {
                  accountsRemoveRoleToUser(user.accountsUserId, '6080738ff5be6a7d3d7a2cdf').then(() => ({})).catch(() => ({}))
                }
              }

              setSubmitting(false)
              toast({
                title: 'Tudo certo!',
                description: 'Os dados do usuário foi atualizado com sucesso!',
                status: 'success',
                position: 'top',
                isClosable: true
              })

              modal?.onClose?.()

              if (setReloadUsers) {
                setReloadUsers(!reloadUsers)
              }
            } catch (error) {
              setSubmitting(false)
              let title = 'Erro'
              let message = 'Houve um erro ao atualizar o usuário, tente novamente.'

              if (error?.response) {
                if (error.response?.status === 422) {
                  title = 'Informações inválidas'
                  message = 'Preencha os campos corretamente e tente novamente.'
                }

                if (error.response?.data?.error === 'E_USER_EMAIL_ALREADY_EXISTS') {
                  title = 'E-mail em uso'
                  message = 'Já existe uma conta utilizando este endereço de e-mail.'
                }
              }

              toast({
                title,
                description: message,
                status: 'error',
                position: 'top',
                isClosable: true
              })
            }
          }}
        >
          {(props: any) => (
            <Form>
              <ModalBody>
              <Field name="name">
                  {({ field, form }: any) => (
                    <FormControl isRequired isInvalid={form.errors.name && form.touched.name} mb={5}>
                      <FormLabel htmlFor="name">Nome completo</FormLabel>
                      <Input {...field} type="text" name="name" id="name" placeholder="Nome completo" />
                      <FormErrorMessage>{form.errors.name}</FormErrorMessage>
                    </FormControl>
                  )}
                </Field>
                <Field name="email">
                  {({ field, form }: any) => (
                    <FormControl isRequired isInvalid={form.errors.email && form.touched.email} mb={5}>
                      <FormLabel htmlFor="email">Endereço de e-mail</FormLabel>
                      <Input {...field} type="email" name="email" id="email" placeholder="Endereço de e-mail" />
                      <FormErrorMessage>{form.errors.email}</FormErrorMessage>
                    </FormControl>
                  )}
                </Field>
                <Field name="reference_id">
                  {({ field, form }: any) => (
                    <FormControl isRequired isInvalid={form.errors.reference_id && form.touched.reference_id} mb={5}>
                      <FormLabel htmlFor="reference_id">ID Max Nível</FormLabel>
                      <Input {...field} type="text" name="reference_id" id="reference_id" placeholder="ID Max Nível" />
                      <FormErrorMessage>{form.errors.reference_id}</FormErrorMessage>
                    </FormControl>
                  )}
                </Field>
                <Field name="password">
                  {({ field, form }: any) => (
                    <FormControl isInvalid={form.errors.password && form.touched.password} mb={5}>
                      <FormLabel htmlFor="password">Senha</FormLabel>
                      <Input {...field} type="password" name="password" id="password" placeholder="Nova senha" />
                      <FormErrorMessage>{form.errors.password}</FormErrorMessage>
                    </FormControl>
                  )}
                </Field>
                <Field name="is_admin">
                  {({ field, form }: any) => (
                    <FormControl isRequired isInvalid={form.errors.is_admin && form.touched.is_admin} mb={5}>
                      <FormLabel htmlFor="is_admin">Administrador?</FormLabel>
                      <Switch {...field} isChecked={field.value} />
                      <FormErrorMessage>{form.errors.is_admin}</FormErrorMessage>
                    </FormControl>
                  )}
                </Field>
              </ModalBody>
              <ModalFooter>
                <Button variant="ghost" onClick={modal.onClose}>Cancelar</Button>
                <Button type="submit" colorScheme="primary" ml={3} disabled={props.isSubmitting}>Salvar</Button>
              </ModalFooter>
            </Form>
          )}
        </Formik>
      </ModalContent>
    </Modal>
  )
}

export default AdminEditUserModal
