import { Box, Button, Text, useDisclosure, useToast } from '@chakra-ui/react'
import format from 'date-fns/format'
import React, { useEffect, useState } from 'react'
import { fetchArticle } from '../../../api'
import Card from '../../../components/card'
import Header from '../../../components/header'
import Page from '../../../components/page'
import AdminArticlesModal from './components/articles-modal'
import './fix-article-view.css'

const AdminArticlesPage = () => {
  const [reloadArticles, setReloadArticles] = useState<boolean>()
  const [text, setText] = useState<string>()
  const [lastUpdatedAt, setLastUpdatedAt] = useState<string>()
  const toast = useToast()
  const articleModal = useDisclosure()

  useEffect(() => {
    fetchArticle().then(({ data }) => {
      setText(data.text)
      setLastUpdatedAt(data.lastUpdateAt)
    }).catch(() => {
      toast({
        title: 'Erro',
        description: 'Não foi possível listar o anúncio atual, tente novamente.',
        isClosable: true,
        status: 'error',
        position: 'top'
      })
    })
  }, [reloadArticles])

  return (
    <>
      <Header />
      <Page>
        <Card
          title="Novidades"
          subHeader={(
            <Button onClick={articleModal.onOpen} colorScheme="primary" size="sm">Atualizar novidade</Button>
          )}
        >
          {text && (
            <>
              <Box py={2} overflowX="auto" fontSize={16} dangerouslySetInnerHTML={{ __html: text }}></Box>
            </>
          )}
        </Card>
      </Page>
      <AdminArticlesModal
        modal={articleModal}
        text={text}
        reloadArticles={reloadArticles}
        setReloadArticles={setReloadArticles}
      />
    </>
  )
}

export default AdminArticlesPage
