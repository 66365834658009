import React from 'react'
import { Box, HStack, Icon, Tooltip, Text } from '@chakra-ui/react'
import { FiInfo } from 'react-icons/fi'

type Props = {
  label: string
  value: string
  color?: string
  helpText?: string
  backgroundColor?: string,
  flag?: string
}

const WalletBalance = (props: Props) => (
  <Box width={{ base: '100%', sm: '100%', md: '47%', lg: '100%' }} height="90px" mr={{ lg: 5 }} backgroundColor={props.backgroundColor || '#273045'} borderRadius="8px" p={3} marginBottom={{ base: '18px', lg: '0' }}>
    <HStack marginBottom={1} lineHeight={1.2} alignItems="center">
      <Text fontSize={15} fontWeight={300} color="white">{props.label}</Text>
      {props.helpText && (
        <Box>
          <Tooltip label={props.helpText} hasArrow fontSize={12}>
            <Box>
              <Icon as={FiInfo} fontSize={15} color="green.500" />
            </Box>
          </Tooltip>
        </Box>
      )}
    </HStack>
    <Text fontSize={props.flag === 'totalProfit' ? 30 : 24} fontWeight={600} color={props.color || 'green'}>{props.value}</Text>
  </Box>
)

export default WalletBalance
