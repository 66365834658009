export const banks = [
  { code: 1, name: '1 - Banco do Brasil S.A.' },
  { code: 3, name: '3 - BANCO DA AMAZONIA S.A.' },
  { code: 4, name: '4 - Banco do Nordeste do Brasil S.A.' },
  { code: 7, name: '7 - BANCO NACIONAL DE DESENVOLVIMENTO ECONOMICO E SOCIAL' },
  { code: 10, name: '10 - CREDICOAMO CREDITO RURAL COOPERATIVA' },
  { code: 11, name: '11 - CREDIT SUISSE HEDGING-GRIFFO CORRETORA DE VALORES S.A' },
  { code: 12, name: '12 - Banco Inbursa S.A.' },
  { code: 14, name: '14 - STATE STREET BRASIL S.A. ? BANCO COMERCIAL' },
  { code: 15, name: '15 - UBS Brasil Corretora de Câmbio, Títulos e Valores Mobiliários S.A.              ' },
  { code: 16, name: '16 - COOPERATIVA DE CRÉDITO MÚTUO DOS DESPACHANTES DE TRÂNSITO DE SANTA CATARINA E RI' },
  { code: 17, name: '17 - BNY Mellon Banco S.A.' },
  { code: 18, name: '18 - Banco Tricury S.A.' },
  { code: 21, name: '21 - BANESTES S.A. BANCO DO ESTADO DO ESPIRITO SANTO' },
  { code: 24, name: '24 - Banco Bandepe S.A.' },
  { code: 25, name: '25 - Banco Alfa S.A.' },
  { code: 29, name: '29 - Banco Itaú Consignado S.A.' },
  { code: 33, name: '33 - BANCO SANTANDER (BRASIL) S.A.' },
  { code: 36, name: '36 - Banco Bradesco BBI S.A.' },
  { code: 37, name: '37 - Banco do Estado do Pará S.A.' },
  { code: 40, name: '40 - Banco Cargill S.A.' },
  { code: 41, name: '41 - Banco do Estado do Rio Grande do Sul S.A.' },
  { code: 47, name: '47 - Banco do Estado de Sergipe S.A.' },
  { code: 60, name: '60 - Confidence Corretora de Câmbio S.A.' },
  { code: 62, name: '62 - Hipercard Banco Múltiplo S.A.' },
  { code: 63, name: '63 - Banco Bradescard S.A.' },
  { code: 64, name: '64 - GOLDMAN SACHS DO BRASIL BANCO MULTIPLO S.A.' },
  { code: 65, name: '65 - Banco AndBank (Brasil) S.A.' },
  { code: 66, name: '66 - BANCO MORGAN STANLEY S.A.' },
  { code: 69, name: '69 - Banco Crefisa S.A.' },
  { code: 70, name: '70 - BRB - BANCO DE BRASILIA S.A.' },
  { code: 74, name: '74 - Banco J. Safra S.A.' },
  { code: 75, name: '75 - Banco ABN Amro S.A.' },
  { code: 76, name: '76 - Banco KDB do Brasil S.A.' },
  { code: 77, name: '77 - Banco Inter S.A.' },
  { code: 78, name: '78 - Haitong Banco de Investimento do Brasil S.A.' },
  { code: 79, name: '79 - Banco Original do Agronegócio S.A.' },
  { code: 80, name: '80 - B&T CORRETORA DE CAMBIO LTDA.' },
  { code: 81, name: '81 - BancoSeguro S.A.' },
  { code: 82, name: '82 - BANCO TOPÁZIO S.A.' },
  { code: 83, name: '83 - Banco da China Brasil S.A.' },
  { code: 84, name: '84 - UNIPRIME NORTE DO PARANÁ - COOPERATIVA DE CRÉDITO LTDA' },
  { code: 85, name: '85 - Cooperativa Central de Crédito - Ailos' },
  { code: 88, name: '88 - BANCO RANDON S.A.' },
  { code: 89, name: '89 - CREDISAN COOPERATIVA DE CRÉDITO' },
  { code: 91, name: '91 - CENTRAL DE COOPERATIVAS DE ECONOMIA E CRÉDITO MÚTUO DO ESTADO DO RIO GRANDE DO S' },
  { code: 92, name: '92 - BRK S.A. Crédito, Financiamento e Investimento                                  ' },
  { code: 93, name: '93 - PÓLOCRED   SOCIEDADE DE CRÉDITO AO MICROEMPREENDEDOR E À EMPRESA DE PEQUENO PORT' },
  { code: 94, name: '94 - Banco Finaxis S.A.' },
  { code: 95, name: '95 - Travelex Banco de Câmbio S.A.' },
  { code: 96, name: '96 - Banco B3 S.A.' },
  { code: 97, name: '97 - Credisis - Central de Cooperativas de Crédito Ltda.' },
  { code: 98, name: '98 - Credialiança Cooperativa de Crédito Rural' },
  { code: 99, name: '99 - UNIPRIME CENTRAL - CENTRAL INTERESTADUAL DE COOPERATIVAS DE CREDITO LTDA.' },
  { code: 100, name: '100 - Planner Corretora de Valores S.A.' },
  { code: 101, name: '101 - RENASCENCA DISTRIBUIDORA DE TÍTULOS E VALORES MOBILIÁRIOS LTDA' },
  { code: 102, name: '102 - XP INVESTIMENTOS CORRETORA DE CÂMBIO,TÍTULOS E VALORES MOBILIÁRIOS S/A          ' },
  { code: 104, name: '104 - CAIXA ECONOMICA FEDERAL' },
  { code: 105, name: '105 - Lecca Crédito, Financiamento e Investimento S/A                                 ' },
  { code: 107, name: '107 - Banco Bocom BBM S.A.' },
  { code: 108, name: '108 - PORTOCRED S.A. - CREDITO, FINANCIAMENTO E INVESTIMENTO                          ' },
  { code: 111, name: '111 - OLIVEIRA TRUST DISTRIBUIDORA DE TÍTULOS E VALORES MOBILIARIOS S.A.' },
  { code: 113, name: '113 - Magliano S.A. Corretora de Cambio e Valores Mobiliarios' },
  { code: 114, name: '114 - Central Cooperativa de Crédito no Estado do Espírito Santo - CECOOP' },
  { code: 117, name: '117 - ADVANCED CORRETORA DE CÂMBIO LTDA' },
  { code: 119, name: '119 - Banco Western Union do Brasil S.A.' },
  { code: 120, name: '120 - BANCO RODOBENS S.A.' },
  { code: 121, name: '121 - Banco Agibank S.A.' },
  { code: 122, name: '122 - Banco Bradesco BERJ S.A.' },
  { code: 124, name: '124 - Banco Woori Bank do Brasil S.A.' },
  { code: 125, name: '125 - BANCO GENIAL S.A.' },
  { code: 126, name: '126 - BR Partners Banco de Investimento S.A.' },
  { code: 127, name: '127 - Codepe Corretora de Valores e Câmbio S.A.' },
  { code: 128, name: '128 - MS Bank S.A. Banco de Câmbio' },
  { code: 129, name: '129 - UBS Brasil Banco de Investimento S.A.' },
  { code: 130, name: '130 - CARUANA S.A. - SOCIEDADE DE CRÉDITO, FINANCIAMENTO E INVESTIMENTO               ' },
  { code: 131, name: '131 - TULLETT PREBON BRASIL CORRETORA DE VALORES E CÂMBIO LTDA' },
  { code: 132, name: '132 - ICBC do Brasil Banco Múltiplo S.A.' },
  { code: 133, name: '133 - CONFEDERAÇÃO NACIONAL DAS COOPERATIVAS CENTRAIS DE CRÉDITO E ECONOMIA FAMILIAR E' },
  { code: 134, name: '134 - BGC LIQUIDEZ DISTRIBUIDORA DE TÍTULOS E VALORES MOBILIÁRIOS LTDA' },
  { code: 136, name: '136 - CONFEDERAÇÃO NACIONAL DAS COOPERATIVAS CENTRAIS UNICRED LTDA. - UNICRED DO BRASI' },
  { code: 138, name: '138 - Get Money Corretora de Câmbio S.A.' },
  { code: 139, name: '139 - Intesa Sanpaolo Brasil S.A. - Banco Múltiplo' },
  { code: 140, name: '140 - Easynvest - Título Corretora de Valores SA' },
  { code: 142, name: '142 - Broker Brasil Corretora de Câmbio Ltda.' },
  { code: 143, name: '143 - Treviso Corretora de Câmbio S.A.' },
  { code: 144, name: '144 - BEXS BANCO DE CÂMBIO S/A' },
  { code: 145, name: '145 - LEVYCAM - CORRETORA DE CAMBIO E VALORES LTDA.' },
  { code: 146, name: '146 - GUITTA CORRETORA DE CAMBIO LTDA.' },
  { code: 149, name: '149 - Facta Financeira S.A. - Crédito Financiamento e Investimento' },
  { code: 157, name: '157 - ICAP do Brasil Corretora de Títulos e Valores Mobiliários Ltda.' },
  { code: 159, name: '159 - Casa do Crédito S.A. Sociedade de Crédito ao Microempreendedor' },
  { code: 163, name: '163 - Commerzbank Brasil S.A. - Banco Múltiplo' },
  { code: 169, name: '169 - BANCO OLÉ CONSIGNADO S.A.' },
  { code: 173, name: '173 - BRL Trust Distribuidora de Títulos e Valores Mobiliários S.A.' },
  { code: 174, name: '174 - PEFISA S.A. - CRÉDITO, FINANCIAMENTO E INVESTIMENTO                             ' },
  { code: 177, name: '177 - Guide Investimentos S.A. Corretora de Valores' },
  { code: 180, name: '180 - CM CAPITAL MARKETS CORRETORA DE CÂMBIO, TÍTULOS E VALORES MOBILIÁRIOS LTDA      ' },
  { code: 183, name: '183 - SOCRED S.A. - SOCIEDADE DE CRÉDITO AO MICROEMPREENDEDOR E À EMPRESA DE PEQUENO P' },
  { code: 184, name: '184 - Banco Itaú BBA S.A.' },
  { code: 188, name: '188 - ATIVA INVESTIMENTOS S.A. CORRETORA DE TÍTULOS, CÂMBIO E VALORES                 ' },
  { code: 189, name: '189 - HS FINANCEIRA S/A CREDITO, FINANCIAMENTO E INVESTIMENTOS                        ' },
  { code: 190, name: '190 - SERVICOOP - COOPERATIVA DE CRÉDITO DOS SERVIDORES PÚBLICOS ESTADUAIS DO RIO GRAN' },
  { code: 191, name: '191 - Nova Futura Corretora de Títulos e Valores Mobiliários Ltda.' },
  { code: 194, name: '194 - PARMETAL DISTRIBUIDORA DE TÍTULOS E VALORES MOBILIÁRIOS LTDA' },
  { code: 196, name: '196 - FAIR CORRETORA DE CAMBIO S.A.' },
  { code: 197, name: '197 - Stone Pagamentos S.A.' },
  { code: 208, name: '208 - Banco BTG Pactual S.A.' },
  { code: 212, name: '212 - Banco Original S.A.' },
  { code: 213, name: '213 - Banco Arbi S.A.' },
  { code: 217, name: '217 - Banco John Deere S.A.' },
  { code: 218, name: '218 - Banco BS2 S.A.' },
  { code: 222, name: '222 - BANCO CRÉDIT AGRICOLE BRASIL S.A.' },
  { code: 224, name: '224 - Banco Fibra S.A.' },
  { code: 233, name: '233 - Banco Cifra S.A.' },
  { code: 237, name: '237 - Banco Bradesco S.A.' },
  { code: 241, name: '241 - BANCO CLASSICO S.A.' },
  { code: 243, name: '243 - Banco Máxima S.A.' },
  { code: 246, name: '246 - Banco ABC Brasil S.A.' },
  { code: 249, name: '249 - Banco Investcred Unibanco S.A.' },
  { code: 250, name: '250 - BCV - BANCO DE CRÉDITO E VAREJO S.A.' },
  { code: 253, name: '253 - Bexs Corretora de Câmbio S/A' },
  { code: 254, name: '254 - PARANÁ BANCO S.A.' },
  { code: 259, name: '259 - MONEYCORP BANCO DE CÂMBIO S.A.' },
  { code: 260, name: '260 - Nu Pagamentos S.A.' },
  { code: 265, name: '265 - Banco Fator S.A.' },
  { code: 266, name: '266 - BANCO CEDULA S.A.' },
  { code: 268, name: '268 - BARI COMPANHIA HIPOTECÁRIA' },
  { code: 269, name: '269 - BANCO HSBC S.A.' },
  { code: 270, name: '270 - Sagitur Corretora de Câmbio Ltda.' },
  { code: 271, name: '271 - IB Corretora de Câmbio, Títulos e Valores Mobiliários S.A.                      ' },
  { code: 272, name: '272 - AGK CORRETORA DE CAMBIO S.A.' },
  { code: 273, name: '273 - Cooperativa de Crédito Rural de São Miguel do Oeste - Sulcredi/São Miguel' },
  { code: 274, name: '274 - MONEY PLUS SOCIEDADE DE CRÉDITO AO MICROEMPREENDEDOR E A EMPRESA DE PEQUENO PORT' },
  { code: 276, name: '276 - BANCO SENFF S.A.' },
  { code: 278, name: '278 - Genial Investimentos Corretora de Valores Mobiliários S.A.' },
  { code: 279, name: '279 - COOPERATIVA DE CREDITO RURAL DE PRIMAVERA DO LESTE' },
  { code: 280, name: '280 - WILL FINANCEIRA S.A. CRÉDITO, FINANCIAMENTO E INVESTIMENTO                      ' },
  { code: 281, name: '281 - Cooperativa de Crédito Rural Coopavel' },
  { code: 283, name: '283 - RB INVESTIMENTOS DISTRIBUIDORA DE TITULOS E VALORES MOBILIARIOS LIMITADA' },
  { code: 285, name: '285 - Frente Corretora de Câmbio Ltda.' },
  { code: 286, name: '286 - COOPERATIVA DE CRÉDITO RURAL DE OURO   SULCREDI/OURO' },
  { code: 288, name: '288 - CAROL DISTRIBUIDORA DE TITULOS E VALORES MOBILIARIOS LTDA.' },
  { code: 289, name: '289 - DECYSEO CORRETORA DE CAMBIO LTDA.' },
  { code: 290, name: '290 - Pagseguro Internet S.A.' },
  { code: 292, name: '292 - BS2 Distribuidora de Títulos e Valores Mobiliários S.A.' },
  { code: 293, name: '293 - Lastro RDV Distribuidora de Títulos e Valores Mobiliários Ltda.' },
  { code: 296, name: '296 - VISION S.A. CORRETORA DE CAMBIO' },
  { code: 298, name: "298 - Vip's Corretora de Câmbio Ltda." },
  { code: 299, name: '299 - SOROCRED   CRÉDITO, FINANCIAMENTO E INVESTIMENTO S.A.                           ' },
  { code: 300, name: '300 - Banco de la Nacion Argentina' },
  { code: 301, name: '301 - BPP Instituição de Pagamento S.A.' },
  { code: 306, name: '306 - PORTOPAR DISTRIBUIDORA DE TITULOS E VALORES MOBILIARIOS LTDA.' },
  { code: 307, name: '307 - Terra Investimentos Distribuidora de Títulos e Valores Mobiliários Ltda.' },
  { code: 309, name: '309 - CAMBIONET CORRETORA DE CÂMBIO LTDA.' },
  { code: 310, name: '310 - VORTX DISTRIBUIDORA DE TITULOS E VALORES MOBILIARIOS LTDA.' },
  { code: 311, name: '311 - DOURADA CORRETORA DE CÂMBIO LTDA.' },
  { code: 312, name: '312 - HSCM - SOCIEDADE DE CRÉDITO AO MICROEMPREENDEDOR E À EMPRESA DE PEQUENO PORTE LT' },
  { code: 313, name: '313 - AMAZÔNIA CORRETORA DE CÂMBIO LTDA.' },
  { code: 315, name: '315 - PI Distribuidora de Títulos e Valores Mobiliários S.A.' },
  { code: 318, name: '318 - Banco BMG S.A.' },
  { code: 319, name: '319 - OM DISTRIBUIDORA DE TÍTULOS E VALORES MOBILIÁRIOS LTDA' },
  { code: 320, name: '320 - China Construction Bank (Brasil) Banco Múltiplo S/A' },
  { code: 321, name: '321 - CREFAZ SOCIEDADE DE CRÉDITO AO MICROEMPREENDEDOR E A EMPRESA DE PEQUENO PORTE LT' },
  { code: 322, name: '322 - Cooperativa de Crédito Rural de Abelardo Luz - Sulcredi/Crediluz' },
  { code: 323, name: '323 - MERCADOPAGO.COM REPRESENTACOES LTDA.' },
  { code: 324, name: '324 - CARTOS SOCIEDADE DE CRÉDITO DIRETO S.A.' },
  { code: 325, name: '325 - Órama Distribuidora de Títulos e Valores Mobiliários S.A.' },
  { code: 326, name: '326 - PARATI - CREDITO, FINANCIAMENTO E INVESTIMENTO S.A.                             ' },
  { code: 329, name: '329 - QI Sociedade de Crédito Direto S.A.' },
  { code: 330, name: '330 - BANCO BARI DE INVESTIMENTOS E FINANCIAMENTOS S.A.' },
  { code: 331, name: '331 - Fram Capital Distribuidora de Títulos e Valores Mobiliários S.A.' },
  { code: 332, name: '332 - Acesso Soluções de Pagamento S.A.' },
  { code: 335, name: '335 - Banco Digio S.A.' },
  { code: 336, name: '336 - Banco C6 S.A.' },
  { code: 340, name: '340 - Super Pagamentos e Administração de Meios Eletrônicos S.A.' },
  { code: 341, name: '341 - ITAÚ UNIBANCO S.A.' },
  { code: 342, name: '342 - Creditas Sociedade de Crédito Direto S.A.' },
  { code: 343, name: '343 - FFA SOCIEDADE DE CRÉDITO AO MICROEMPREENDEDOR E À EMPRESA DE PEQUENO PORTE LTDA.' },
  { code: 348, name: '348 - Banco XP S.A.' },
  { code: 349, name: '349 - AL5 S.A. CRÉDITO, FINANCIAMENTO E INVESTIMENTO                                  ' },
  { code: 350, name: '350 - COOPERATIVA DE CRÉDITO RURAL DE PEQUENOS AGRICULTORES E DA REFORMA AGRÁRIA DO CE' },
  { code: 352, name: '352 - TORO CORRETORA DE TÍTULOS E VALORES MOBILIÁRIOS LTDA' },
  { code: 354, name: '354 - NECTON INVESTIMENTOS  S.A. CORRETORA DE VALORES MOBILIÁRIOS E COMMODITIES' },
  { code: 355, name: '355 - ÓTIMO SOCIEDADE DE CRÉDITO DIRETO S.A.' },
  { code: 358, name: '358 - MIDWAY S.A. - CRÉDITO, FINANCIAMENTO E INVESTIMENTO                             ' },
  { code: 359, name: '359 - ZEMA CRÉDITO, FINANCIAMENTO E INVESTIMENTO S/A                                  ' },
  { code: 360, name: '360 - TRINUS CAPITAL DISTRIBUIDORA DE TÍTULOS E VALORES MOBILIÁRIOS S.A.' },
  { code: 362, name: '362 - CIELO S.A.' },
  { code: 363, name: '363 - SINGULARE CORRETORA DE TÍTULOS E VALORES MOBILIÁRIOS S.A.' },
  { code: 364, name: '364 - GERENCIANET S.A.' },
  { code: 365, name: '365 - SOLIDUS S.A. CORRETORA DE CAMBIO E VALORES MOBILIARIOS' },
  { code: 366, name: '366 - BANCO SOCIETE GENERALE BRASIL S.A.' },
  { code: 367, name: '367 - VITREO DISTRIBUIDORA DE TÍTULOS E VALORES MOBILIÁRIOS S.A.' },
  { code: 368, name: '368 - Banco CSF S.A.' },
  { code: 370, name: '370 - Banco Mizuho do Brasil S.A.' },
  { code: 371, name: '371 - WARREN CORRETORA DE VALORES MOBILIÁRIOS E CÂMBIO LTDA.' },
  { code: 373, name: '373 - UP.P SOCIEDADE DE EMPRÉSTIMO ENTRE PESSOAS S.A.' },
  { code: 374, name: '374 - REALIZE CRÉDITO, FINANCIAMENTO E INVESTIMENTO S.A.                              ' },
  { code: 376, name: '376 - BANCO J.P. MORGAN S.A.' },
  { code: 377, name: '377 - BMS SOCIEDADE DE CRÉDITO DIRETO S.A.' },
  { code: 378, name: '378 - BBC LEASING S.A. - ARRENDAMENTO MERCANTIL' },
  { code: 379, name: '379 - COOPERFORTE - COOPERATIVA DE ECONOMIA E CRÉDITO MÚTUO DE FUNCIONÁRIOS DE INSTITU' },
  { code: 380, name: '380 - PICPAY SERVICOS S.A.' },
  { code: 381, name: '381 - BANCO MERCEDES-BENZ DO BRASIL S.A.' },
  { code: 382, name: '382 - FIDÚCIA SOCIEDADE DE CRÉDITO AO MICROEMPREENDEDOR E À EMPRESA DE PEQUENO PORTE L' },
  { code: 383, name: '383 - BOLETOBANCÁRIO.COM TECNOLOGIA DE PAGAMENTOS LTDA.' },
  { code: 384, name: '384 - GLOBAL FINANÇAS SOCIEDADE DE CRÉDITO AO MICROEMPREENDEDOR E À EMPRESA DE PEQUENO' },
  { code: 386, name: '386 - NU FINANCEIRA S.A. - Sociedade de Crédito, Financiamento e Investimento         ' },
  { code: 387, name: '387 - Banco Toyota do Brasil S.A.' },
  { code: 389, name: '389 - Banco Mercantil do Brasil S.A.' },
  { code: 390, name: '390 - BANCO GM S.A.' },
  { code: 391, name: '391 - COOPERATIVA DE CREDITO RURAL DE IBIAM - SULCREDI/IBIAM' },
  { code: 393, name: '393 - Banco Volkswagen S.A.' },
  { code: 394, name: '394 - Banco Bradesco Financiamentos S.A.' },
  { code: 395, name: "395 - F.D'GOLD - DISTRIBUIDORA DE TÍTULOS E VALORES MOBILIÁRIOS LTDA." },
  { code: 396, name: '396 - HUB PAGAMENTOS S.A' },
  { code: 397, name: '397 - LISTO SOCIEDADE DE CREDITO DIRETO S.A.' },
  { code: 398, name: '398 - IDEAL CORRETORA DE TÍTULOS E VALORES MOBILIÁRIOS S.A.' },
  { code: 399, name: '399 - Kirton Bank S.A. - Banco Múltiplo' },
  { code: 400, name: '400 - COOPERATIVA DE CRÉDITO, POUPANÇA E SERVIÇOS FINANCEIROS DO CENTRO OESTE         ' },
  { code: 402, name: '402 - COBUCCIO SOCIEDADE DE CRÉDITO DIRETO S.A.' },
  { code: 403, name: '403 - CORA SOCIEDADE DE CRÉDITO DIRETO S.A.' },
  { code: 404, name: '404 - SUMUP SOCIEDADE DE CRÉDITO DIRETO S.A.' },
  { code: 406, name: '406 - ACCREDITO - SOCIEDADE DE CRÉDITO DIRETO S.A.' },
  { code: 407, name: '407 - ÍNDIGO INVESTIMENTOS DISTRIBUIDORA DE TÍTULOS E VALORES MOBILIÁRIOS LTDA.' },
  { code: 408, name: '408 - BÔNUSCRED SOCIEDADE DE CRÉDITO DIRETO S.A.' },
  { code: 410, name: '410 - PLANNER SOCIEDADE DE CRÉDITO AO MICROEMPREENDEDOR S.A.' },
  { code: 411, name: '411 - Via Certa Financiadora S.A. - Crédito, Financiamento e Investimentos            ' },
  { code: 412, name: '412 - BANCO CAPITAL S.A.' },
  { code: 422, name: '422 - Banco Safra S.A.' },
  { code: 456, name: '456 - Banco MUFG Brasil S.A.' },
  { code: 464, name: '464 - Banco Sumitomo Mitsui Brasileiro S.A.' },
  { code: 473, name: '473 - Banco Caixa Geral - Brasil S.A.' },
  { code: 477, name: '477 - Citibank N.A.' },
  { code: 479, name: '479 - Banco ItauBank S.A.' },
  { code: 487, name: '487 - DEUTSCHE BANK S.A. - BANCO ALEMAO' },
  { code: 488, name: '488 - JPMorgan Chase Bank, National Association                                       ' },
  { code: 492, name: '492 - ING Bank N.V.' },
  { code: 495, name: '495 - Banco de La Provincia de Buenos Aires' },
  { code: 505, name: '505 - Banco Credit Suisse (Brasil) S.A.' },
  { code: 545, name: '545 - SENSO CORRETORA DE CAMBIO E VALORES MOBILIARIOS S.A' },
  { code: 600, name: '600 - Banco Luso Brasileiro S.A.' },
  { code: 604, name: '604 - Banco Industrial do Brasil S.A.' },
  { code: 610, name: '610 - Banco VR S.A.' },
  { code: 611, name: '611 - Banco Paulista S.A.' },
  { code: 612, name: '612 - Banco Guanabara S.A.' },
  { code: 613, name: '613 - Omni Banco S.A.' },
  { code: 623, name: '623 - Banco Pan S.A.' },
  { code: 626, name: '626 - BANCO C6 CONSIGNADO S.A.' },
  { code: 630, name: '630 - Banco Smartbank S.A.' },
  { code: 633, name: '633 - Banco Rendimento S.A.' },
  { code: 634, name: '634 - BANCO TRIANGULO S.A.' },
  { code: 637, name: '637 - BANCO SOFISA S.A.' },
  { code: 643, name: '643 - Banco Pine S.A.' },
  { code: 652, name: '652 - Itaú Unibanco Holding S.A.' },
  { code: 653, name: '653 - BANCO INDUSVAL S.A.' },
  { code: 654, name: '654 - BANCO DIGIMAIS S.A.' },
  { code: 655, name: '655 - Banco Votorantim S.A.' },
  { code: 707, name: '707 - Banco Daycoval S.A.' },
  { code: 712, name: '712 - Banco Ourinvest S.A.' },
  { code: 720, name: '720 - BANCO RNX S.A.' },
  { code: 739, name: '739 - Banco Cetelem S.A.' },
  { code: 741, name: '741 - BANCO RIBEIRAO PRETO S.A.' },
  { code: 743, name: '743 - Banco Semear S.A.' },
  { code: 745, name: '745 - Banco Citibank S.A.' },
  { code: 746, name: '746 - Banco Modal S.A.' },
  { code: 747, name: '747 - Banco Rabobank International Brasil S.A.' },
  { code: 748, name: '748 - BANCO COOPERATIVO SICREDI S.A.' },
  { code: 751, name: '751 - Scotiabank Brasil S.A. Banco Múltiplo' },
  { code: 752, name: '752 - Banco BNP Paribas Brasil S.A.' },
  { code: 753, name: '753 - Novo Banco Continental S.A. - Banco Múltiplo' },
  { code: 754, name: '754 - Banco Sistema S.A.' },
  { code: 755, name: '755 - Bank of America Merrill Lynch Banco Múltiplo S.A.' },
  { code: 756, name: '756 - BANCO COOPERATIVO DO BRASIL S.A. - BANCOOB' },
  { code: 757, name: '757 - BANCO KEB HANA DO BRASIL S.A.' }
]
