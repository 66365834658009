import React from 'react'
import { Box, Button, useDisclosure } from '@chakra-ui/react'
import Card from '../../components/card'
import CompleteProfileModal from '../../components/complete-profile-modal'
import Header from '../../components/header'
import Page from '../../components/page'
import PageInstructionParagraph from '../../components/page-instructions-paragraph'
import ConfirmCancelContractModal from './components/confirm-modal'

const WithdrawnAllPage = () => {
  const completeProfileModal = useDisclosure()
  const confirmModal = useDisclosure()

  async function onProfileCompleted () {
    completeProfileModal.onClose()
    confirmModal.onOpen()
  }

  return (
    <>
      <Header />
      <Page>
        <Box maxW="3xl" margin="auto">
          <Card
            title="Cancelar contrato"
          >
            <PageInstructionParagraph
              instructions="Ao solicitar a quebra de contrato, devolveremos os seus produtos, descontando o valor já recebido e os custos de frete para o seu endereço cadastrado."
            />
            <Button colorScheme="red" onClick={completeProfileModal.onOpen}>
              Sim, quero encerrar o contrato
            </Button>
          </Card>
        </Box>
      </Page>
      <CompleteProfileModal
        modal={completeProfileModal}
        title="Confirme os seus dados para continuar"
        formCallback={onProfileCompleted}
      />
      <ConfirmCancelContractModal
        modal={confirmModal}
      />
    </>
  )
}

export default WithdrawnAllPage
