import { format } from 'date-fns'
import { Badge, Button, Table, Tbody, Td, Th, Thead, Tr, useToast, Link, useDisclosure, Tooltip, Text, Box } from '@chakra-ui/react'
import React, { MouseEventHandler, useEffect, useState } from 'react'
import { getAllWithdrawals } from '../../../api'
import Card from '../../../components/card'
import Header from '../../../components/header'
import Page from '../../../components/page'
import { Withdrawn } from '../../../models'
import { toBrl } from '../../../utils'
import { Link as RouterLink } from 'react-router-dom'
import AdminEditWithdrawnModal from './components/edit-modal'
import { CSVLink } from 'react-csv'

const AdminWithdrawalsPage = () => {
  const [withdrawals, setWithdrawals] = useState<Withdrawn[]>()
  const [currentWithdrawal, setCurrentWithdrawal] = useState<Withdrawn>()
  const [reloadWithdrawals, setReloadWithdrawals] = useState<boolean>()
  const [exportationData, setExportationData] = useState<any[] | null>()
  const toast = useToast()
  const modal = useDisclosure()

  useEffect(() => {
    getAllWithdrawals().then(({ data }) => {
      setWithdrawals(data)
    }).catch(() => {
      toast({
        title: 'Erro',
        description: 'Houve um erro ao listar as solicitações de saque, tente novamente.',
        status: 'error',
        position: 'top',
        isClosable: true
      })
    })
  }, [reloadWithdrawals])

  useEffect(() => {
    exportPending()
  }, [withdrawals])

  function getStatusDescription (status: 'PENDING' | 'COMPLETED' | 'ERROR'): string {
    const statuses = {
      PENDING: 'Pendente',
      COMPLETED: 'Concluído',
      ERROR: 'Erro'
    }

    return statuses?.[status] || status
  }

  function handleEdit (withdrawn: Withdrawn) {
    setCurrentWithdrawal(withdrawn)
    modal.onOpen()
  }

  function exportPending () {
    const pendingWithdrawals = withdrawals?.filter(el => el.status === 'PENDING')?.map((el) => ({
      ID: el.id,
      Usuário: el.user?.name,
      'ID Usuário': el.user?.id,
      Email: el.user?.email,
      Telefone: el.user?.phone,
      'ID Maxnível': el.user?.referenceId,
      Valor: toBrl(String(el.amount)),
      'Solicitado em': format((new Date(el.createdAt)), 'dd/MM/yyyy \'às\' HH:mm'),
      'Tipo de conta': el.userBankAccount?.accountType,
      Banco: el.userBankAccount?.bankName,
      Agência: el.userBankAccount?.agencyCode,
      Conta: el.userBankAccount?.accountNumber,
      'Tipo de chave': el.userBankAccount?.pixKeyType,
      'Chave PIX': el.userBankAccount?.pixKeyValue,
      Titular: el.userBankAccount?.holderName,
      'Documento titular': el.userBankAccount?.holderTaxNumber,
      Status: getStatusDescription(el.status)
    }))

    if (pendingWithdrawals && pendingWithdrawals.length > 0) {
      setExportationData(pendingWithdrawals)
    }
  }

  return (
    <>
      <Header />
      <Page>
        <Card
          title="Solicitações de saque"
          subHeader={(
            <Button as={CSVLink} data={exportationData || []} colorScheme="primary" size="sm" filename="Solicitações de saque.csv">
              Exportar pendentes
            </Button>
          )}
        >
          <Box mt={8}>
            <Table>
              <Thead>
                <Tr>
                  <Th color="primary.600">Usuário</Th>
                  <Th color="primary.600">Valor</Th>
                  <Th color="primary.600">Conta bancária</Th>
                  <Th color="primary.600">Status</Th>
                  <Th color="primary.600">Solicitado em</Th>
                  <Th color="primary.600"></Th>
                </Tr>
              </Thead>
              <Tbody>
                {withdrawals?.map((withdrawn) => (
                  <Tr key={withdrawn.id}>
                    <Td>
                      <Button
                        colorScheme="primary"
                        variant="link"
                        as={RouterLink}
                        to={`/admin/users/${withdrawn.userId}`}
                      >{withdrawn?.user?.name}</Button>
                    </Td>
                    <Td>{toBrl(String(withdrawn.amount))}</Td>
                    <Td>
                      {withdrawn?.userBankAccount?.accountType === 'PIX'
                        ? (
                          <>
                            <Text>Tipo de chave: {withdrawn?.userBankAccount?.pixKeyType}</Text>
                            <Text>Chave PIX: {withdrawn?.userBankAccount?.pixKeyValue}</Text>
                          </>
                          )
                        : (
                          <>
                            <Text>Banco: {withdrawn?.userBankAccount?.bankName}</Text>
                            <Text>Agência: {withdrawn?.userBankAccount?.agencyCode}</Text>
                            <Text>Conta: {withdrawn?.userBankAccount?.accountNumber} ({withdrawn?.userBankAccount?.accountType})</Text>
                            <Text>Titular: {withdrawn?.userBankAccount?.holderName} ({withdrawn?.userBankAccount?.holderTaxNumber})</Text>
                          </>
                          )}
                    </Td>
                    <Td>
                      {withdrawn.status === 'ERROR'
                        ? (
                            <Tooltip label={`Motivo: ${withdrawn.errorMessage || '-'}`}>
                              <Badge colorScheme="red">Erro</Badge>
                            </Tooltip>
                          )
                        : (
                            <Badge colorScheme={withdrawn.status === 'COMPLETED' ? 'green' : 'gray'}>
                              {getStatusDescription(withdrawn.status)}
                            </Badge>
                          )}
                    </Td>
                    <Td>{format((new Date(withdrawn.createdAt)), 'dd/MM/yyyy \'às\' HH:mm')}</Td>
                    <Td>
                      {withdrawn.status === 'PENDING' && <Button colorScheme="primary" size="xs" onClick={() => handleEdit(withdrawn)}>Editar</Button>}
                    </Td>
                  </Tr>
                ))}
              </Tbody>
            </Table>
          </Box>
        </Card>
      </Page>
      {currentWithdrawal && (
        <AdminEditWithdrawnModal
          modal={modal}
          withdrawn={currentWithdrawal}
          reloadWithdrawals={reloadWithdrawals}
          setReloadWithdrawals={setReloadWithdrawals}
        />
      )}
    </>
  )
}

export default AdminWithdrawalsPage
