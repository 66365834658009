import { addYears } from 'date-fns'
import { useEffect } from 'react'

type SetCookie = (name: string, value: string, options?: any) => void

const handleAffiliate = (setCookie: SetCookie) => {
  const params = new URLSearchParams(window.location.search)

  if (params.has('ref')) {
    const ref = params.get('ref') as string
    setCookie('sponsor_id', ref, { expires: addYears((new Date()), 1) })
  }
}

export const useAffiliate = (setCookie: SetCookie) => useEffect(() => handleAffiliate(setCookie), [])
