import React from 'react'
import { Box, Heading, HStack, Text } from '@chakra-ui/react'
import { format, isFuture, parseISO } from 'date-fns'
import { Badge } from '@chakra-ui/react'
import { Tooltip } from '@chakra-ui/react'
import { toBrl } from '../../utils'

type Props = {
  title: string
  transactionAt: string
  amount: string
  type?: 'INCOME' | 'OUTCOME'
  status: 'SUCCEED' | 'FAILED' | 'CANCELED'
  cancelReason?: string
  customFields?: any
}

const TransactionItem = (props: Props) => {
  const date = new Date(props.transactionAt)

  return (
    <Box mb={5}>
      <HStack spacing={5} justifyContent="space-between" opacity={props.status === 'SUCCEED' ? 1 : 0.5}>
        <Box>
          <Heading fontWeight={500} as="h4" fontSize={16}>
            {props.title}
            {props.status === 'CANCELED' && (
              <Tooltip label={props.cancelReason} hasArrow>
                <Badge colorScheme="red" ml={2}>Cancelado</Badge>
              </Tooltip>
            )}
            {props.status === 'FAILED' && (
              <Tooltip label={props.cancelReason} hasArrow>
                <Badge colorScheme="red" ml={2}>Falha</Badge>
              </Tooltip>
            )}
          </Heading>
          {props?.customFields?.termStartsAt && isFuture(parseISO(props.customFields.termStartsAt)) && (
            <Box>
              <Text fontSize={13} color="gray.700">
                Produto em fase de fabricação • Rendimentos entram em vigor a partir de {format(parseISO(props.customFields.termStartsAt), 'dd/MM/yyyy \'às\' HH:mm')}.
              </Text>
            </Box>
          )}
          <Text fontSize={13} fontWeight={500} color="gray.500">{format(date, 'dd/MM/yyyy \'às\' HH:mm')}</Text>
        </Box>
        <Box>
          <Text fontWeight={600} fontSize={16} color={(props?.type || 'INCOME') === 'INCOME' ? 'green.300' : 'red.300'}>
            {(props?.type || 'INCOME') === 'INCOME' ? '+' : '-'} {toBrl(props.amount)}
          </Text>
        </Box>
      </HStack>
    </Box>
  )
}

export default TransactionItem
