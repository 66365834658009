import { Box, Container, Text } from '@chakra-ui/layout'
import { Spinner } from '@chakra-ui/spinner'
import { addDays } from 'date-fns'
import React, { useEffect } from 'react'
import { useHistory } from 'react-router'
import { login } from '../../api'
import { ACCOUNTS_TOKEN_KEY, ACCOUNTS_URL, AUTH_TOKEN_KEY, CLIENT_ID } from '../../config'
import { useUser } from '../../contexts'
import { cookies } from '../../utils'

const AuthorizePage = () => {
  const { setIsAuthenticated } = useUser()
  const history = useHistory()

  useEffect(() => {
    const params = new URLSearchParams(window.location.search)
    const token = params.get('token')

    if (!token) {
      window.location.replace(`${ACCOUNTS_URL}/authorize?client=${CLIENT_ID}`)
      return
    }

    login({ token }).then(({ data }) => {
      const cookieOptions = { expires: addDays(new Date(), 1) }
      cookies.set(AUTH_TOKEN_KEY, data.access_token, cookieOptions)
      cookies.set(ACCOUNTS_TOKEN_KEY, data.accounts_token, cookieOptions)
      setIsAuthenticated(true)
      history.push('/')
    }).catch(() => {
      window.location.replace(`${ACCOUNTS_URL}/authorize?client=${CLIENT_ID}&invalid_credentials=true`)
    })
  }, [])

  return (
    <Container minWidth="sm" py={20}>
      <Box p={10} boxShadow="lg" borderRadius={8} backgroundColor="secondaryBackground" textAlign="center">
        <Spinner color="primary.500" size="lg" marginBottom="10px" />
        <Text fontSize={18} fontWeight={600}>Autenticando...</Text>
      </Box>
    </Container>
  )
}

export default AuthorizePage
