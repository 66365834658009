import React from 'react'
import ReactDatePicker from 'react-date-picker'
import './styles.css'

interface DatePickerProps {
  onChange: (date: Date | Date[]) => void;
  value: Date;
}

const DatePicker = ({ onChange, value }: DatePickerProps) => {
  return (
    <ReactDatePicker
      onChange={onChange}
      value={value}
      locale="pt-BR"
    />
  )
}

export default DatePicker
