import { AxiosResponse } from 'axios'
import { CreateProductRequest, GetProductsParams } from '../interfaces'
import { Product } from '../models'
import { getInstance } from './config'

export const getProducts = (params?: GetProductsParams): Promise<AxiosResponse<Product[]>> => {
  return getInstance().get('/products', { params })
}

export const createProduct = (data: CreateProductRequest): Promise<AxiosResponse<Product>> => {
  return getInstance().post('/products', data)
}

export const updateProduct = (id: number, data: Partial<CreateProductRequest>): Promise<AxiosResponse<Product>> => {
  return getInstance().patch(`/products/${id}`, data)
}
