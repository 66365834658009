import { AxiosResponse } from 'axios'
import { getAccountsInstance } from './config'

export const accountsUpdateUser = (data: any): Promise<AxiosResponse<any>> => {
  return getAccountsInstance().patch('/user', data)
}

export const accountsUpdatePassword = (data: any): Promise<AxiosResponse<any>> => {
  return getAccountsInstance().patch('/user/password', data)
}

export const accountsUpdateUserById = (id: string, data: any): Promise<AxiosResponse<any>> => {
  return getAccountsInstance().patch(`/users/${id}`, data)
}

export const accountsAddRoleToUser = (user: string, role: string): Promise<AxiosResponse<any>> => {
  return getAccountsInstance().post(`/users/${user}/${role}`)
}

export const accountsRemoveRoleToUser = (user: string, role: string): Promise<AxiosResponse<any>> => {
  return getAccountsInstance().delete(`/users/${user}/${role}`)
}

export const accountsRevokeSession = (): Promise<AxiosResponse<any>> => {
  return getAccountsInstance().delete('/session')
}
