import React from 'react'
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import ReactDateRangePicker from '@wojtekmaj/react-daterange-picker'
import './styles.css'

interface DateRangePickerProps {
  onChange: (days: [Date, Date]) => void;
  value: [Date, Date];
}

const DateRangePicker = ({ onChange, value }: DateRangePickerProps) => {
  return (
    <ReactDateRangePicker
      onChange={onChange}
      value={value}
      locale="pt-BR"
      clearIcon={null}
    />
  )
}

export default DateRangePicker
