import React, { useEffect, useState } from 'react'
import { Button, Table, Tbody, Td, Th, Thead, Tr, useDisclosure, useToast } from '@chakra-ui/react'
import { FiPlus } from 'react-icons/fi'
import Card from '../../../components/card'
import Header from '../../../components/header'
import Page from '../../../components/page'
import { Product } from '../../../models'
import { getProducts } from '../../../api'
import { toBrl } from '../../../utils'
import AddProductModal from './components/add-modal'
import EditProductModal from './components/edit-modal'

const AdminProductsPage = () => {
  const [currentProduct, setCurrentProduct] = useState<Product>()
  const [reloadProducts, setReloadProducts] = useState<boolean>(false)
  const [products, setProducts] = useState<Product[]>()
  const toast = useToast()
  const addModal = useDisclosure()
  const editModal = useDisclosure()

  useEffect(() => {
    getProducts({
      show_all_fields: true,
      show_inactive: true,
      order_column: 'products.created_at',
      order_type: 'desc'
    }).then(({ data }) => {
      setProducts(data)
    }).catch(() => {
      toast({
        title: 'Erro',
        description: 'Houve um erro ao listar os produtos, tente novamente.',
        status: 'error',
        position: 'top',
        isClosable: true
      })
    })
  }, [reloadProducts])

  function handleEdit (product: Product) {
    setCurrentProduct(product)
    editModal.onOpen()
  }

  return (
    <>
      <Header />
      <Page>
        <Card
          title="Produtos"
          subHeader={(
            <Button onClick={addModal.onOpen} colorScheme="primary" leftIcon={<FiPlus />} size="sm">Novo produto</Button>
          )}
        >
          <Table>
            <Thead>
              <Tr>
                <Th color="primary.600">Nome</Th>
                <Th color="primary.600">Valor</Th>
                <Th color="primary.600">Ativo</Th>
                <Th color="primary.600">Vigência</Th>
                <Th color="primary.600">Carência</Th>
                <Th></Th>
              </Tr>
            </Thead>
            <Tbody>
              {products?.map((product) => (
                <Tr key={product.id}>
                  <Td>{product.name}</Td>
                  <Td>{toBrl(String(product.amount))}</Td>
                  <Td>{product.isActive ? 'Sim' : 'Não'}</Td>
                  <Td>{product.termPeriod} dias</Td>
                  <Td>{product.gracePeriod} dias</Td>
                  <Td>
                    <Button size="xs" colorScheme="primary" onClick={() => handleEdit(product)}>
                      Editar
                    </Button>
                  </Td>
                </Tr>
              ))}
            </Tbody>
          </Table>
        </Card>
      </Page>
      <AddProductModal
        modal={addModal}
        reloadProducts={reloadProducts}
        setReloadProducts={setReloadProducts}
      />
      {currentProduct && (
        <EditProductModal
          modal={editModal}
          product={currentProduct}
          reloadProducts={reloadProducts}
          setReloadProducts={setReloadProducts}
        />
      )}
    </>
  )
}

export default AdminProductsPage
