import React from 'react'
import { Button, FormControl, FormErrorMessage, FormLabel, Input, Modal, ModalBody, ModalCloseButton, ModalContent, ModalFooter, ModalHeader, ModalOverlay, UseDisclosureProps, useToast } from '@chakra-ui/react'
import { Field, Form, Formik } from 'formik'
import { createYield } from '../../../../api'

type Props = {
  modal: UseDisclosureProps
  reloadYields?: boolean
  setReloadYields?: (reload: boolean) => void
}

const YieldModal = ({ modal, reloadYields, setReloadYields }: Props) => {
  const toast = useToast()

  return (
    <Modal size="xl" isOpen={modal.isOpen || false} onClose={modal.onClose || (() => ({}))} closeOnOverlayClick={false} blockScrollOnMount={true}>
      <ModalOverlay />
      <ModalContent color="white" backgroundColor="secondaryBackground">
        <ModalHeader>Atualizar índice de lucro mensal</ModalHeader>
        <ModalCloseButton />
        <Formik
          initialValues={{
            index: ''
          }}
          validate={(values) => {
            const errors: { index?: string } = {}

            if (!values.index) {
              errors.index = 'Informe o índice'
            }

            return errors
          }}
          onSubmit={async (values, { setSubmitting }) => {
            setSubmitting(true)

            try {
              await createYield(values)
              setSubmitting(false)
              toast({
                title: 'Tudo certo!',
                description: 'O índice de lucro foi atualizado com sucesso!',
                status: 'success',
                position: 'top',
                isClosable: true
              })

              modal?.onClose?.()

              if (setReloadYields) {
                setReloadYields(!reloadYields)
              }
            } catch (error) {
              setSubmitting(false)
              let title = 'Erro'
              let message = 'Houve um erro ao atualizar o índice de lucro, tente novamente.'

              if (error?.response) {
                if (error.response?.status === 422) {
                  title = 'Informações inválidas'
                  message = 'Preencha os campos corretamente e tente novamente.'
                }
              }

              toast({
                title,
                description: message,
                status: 'error',
                position: 'top',
                isClosable: true
              })
            }
          }}
        >
          {(props: any) => (
            <Form>
              <ModalBody>
                <Field name="index">
                  {({ field, form }: any) => (
                    <FormControl isRequired isInvalid={form.errors.index && form.touched.index} mb={5}>
                      <FormLabel htmlFor="index">Índice de lucro mensal</FormLabel>
                      <Input {...field} type="number" id="index" placeholder="Índice" />
                      <FormErrorMessage>{form.errors.index}</FormErrorMessage>
                    </FormControl>
                  )}
                </Field>
              </ModalBody>
              <ModalFooter>
                <Button variant="ghost" onClick={modal.onClose}>Cancelar</Button>
                <Button type="submit" colorScheme="primary" ml={3} disabled={props.isSubmitting}>Atualizar índice</Button>
              </ModalFooter>
            </Form>
          )}
        </Formik>
      </ModalContent>
    </Modal>
  )
}

export default YieldModal
