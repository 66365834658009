import { AxiosResponse } from 'axios'
import { } from '../interfaces'
import { GetActiveTermResponse } from '../interfaces'
import { Term } from '../models'
import { getInstance } from './config'

export const getActiveTerm = (): Promise<AxiosResponse<GetActiveTermResponse>> => {
  return getInstance().get('/terms')
}

export const getTerms = (): Promise<AxiosResponse<Term[]>> => {
  return getInstance().get('/terms/history')
}

export const createTerms = (data: Partial<Term>): Promise<AxiosResponse<Term>> => {
  return getInstance().post('/terms', data)
}
