import React from 'react'
import { Box } from '@chakra-ui/react'
import Header from '../../components/header'
import Page from '../../components/page'
import Card from '../../components/card'
import PageInstructionParagraph from '../../components/page-instructions-paragraph'
import ProductsList from '../../components/products-list'

const DepositPage = () => {
  return (
    <>
      <Header />
      <Page>
        <Box margin="auto">
          <Card title="Comprar agora">
            <PageInstructionParagraph
              instructions="Escolha um de nossos pacotes abaixo para comprar:"
            />
            <ProductsList />
          </Card>
        </Box>
      </Page>
    </>
  )
}

export default DepositPage
