import React, { useEffect } from 'react'
import { Container, Heading, Spinner } from '@chakra-ui/react'
import { useUser } from '../../contexts'
import { accountsRevokeSession } from '../../api/accounts'
import { cookies } from '../../utils'
import { ACCOUNTS_URL, CLIENT_ID } from '../../config'

const LogoutPage = () => {
  const { setIsAuthenticated } = useUser()

  function redirectToLogoutPostPage () {
    cookies.removeAll()
    window.location.replace(`${ACCOUNTS_URL}/logout?client=${CLIENT_ID}`)
    setIsAuthenticated(false)
  }

  useEffect(() => {
    accountsRevokeSession().then(() => {
      redirectToLogoutPostPage()
    }).catch(() => {
      redirectToLogoutPostPage()
    })
  }, [])

  return (
    <Container d="flex" alignItems="center" justifyContent="center" flexDirection="column" p={40}>
      <Spinner color="primary.500" size="xl" />
      <Heading fontSize={18} fontWeight={500} as="h4" mt={5}>Saindo...</Heading>
    </Container>
  )
}

export default LogoutPage
