import axios from 'axios'
import { API_URL, ACCOUNTS_API_URL } from '../config'
import { getAccountsAuthToken, getAuthToken } from '../utils'

export const getInstance = () => {
  const token = getAuthToken()
  const headers: { [key: string]: string } = {}

  if (token) {
    headers.Authorization = `Bearer ${token}`
  }

  return axios.create({
    baseURL: API_URL,
    headers
  })
}

export const getAccountsInstance = () => {
  const token = getAccountsAuthToken()
  const headers: { [key: string]: string } = {}

  if (token) {
    headers.Authorization = `Bearer ${token}`
  }

  return axios.create({ baseURL: ACCOUNTS_API_URL, headers })
}
