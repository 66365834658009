import { AxiosResponse } from 'axios'
import { AuthTokenRequest, AuthTokenResponse, AuthSignupRequest, AuthSignupResponse, AuthRecoverRequest, AuthRecoverResponse, AuthResetResponse, AuthResetRequest, SessionTokenResponse } from '../interfaces'
import { getInstance, getAccountsInstance } from './config'

export const login = (data: AuthTokenRequest): Promise<AxiosResponse<AuthTokenResponse>> => {
  return getInstance().post('/auth/token', data)
}

export const signup = (data: AuthSignupRequest): Promise<AxiosResponse<AuthSignupResponse>> => {
  return getInstance().post('/auth/signup', data)
}

export const recover = (data: AuthRecoverRequest): Promise<AxiosResponse<AuthRecoverResponse>> => {
  return getInstance().post('/auth/recover', data)
}

export const reset = (token: string, data: AuthResetRequest): Promise<AxiosResponse<AuthResetResponse>> => {
  return getInstance().post(`/auth/reset/${token}`, data)
}

export const getSessionToken = (token: string): Promise<AxiosResponse<SessionTokenResponse>> => {
  return getAccountsInstance().post('/session-token', { token })
}
