/* eslint-disable camelcase */
import { Box } from '@chakra-ui/react'
import React from 'react'
import Header from '../../components/header'
import Page from '../../components/page'
import Card from '../../components/card'
import PasswordForm from './components/form'

const PasswordPage = () => {
  return (
    <>
      <Header />
      <Page>
        <Box maxW={{ sm: '100%', lg: '3xl' }} margin="auto">
          <Card title="Alterar senha">
            <PasswordForm />
          </Card>
        </Box>
      </Page>
    </>
  )
}

export default PasswordPage
