export enum PaymentMethodType {
  MAXIVEL_PAGSEGURO = 'MAXIVEL_PAGSEGURO',
  CRYPTO_BITCOIN = 'CRYPTO_BITCOIN'
}

export type PaymentMethod = {
  id?: number
  name: string
  type: PaymentMethodType
  customFields?: {
    cryptoCode?: string
    walletAddress?: string
  }
  isActive: boolean
}
