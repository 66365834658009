import { AxiosResponse } from 'axios'
import { CreateBankAccountRequest } from '../interfaces'
import { BankAccount } from '../models'
import { getInstance } from './config'

export const getBankAccounts = (): Promise<AxiosResponse<BankAccount[]>> => {
  return getInstance().get('/user/bank-accounts')
}

export const createBankAccount = (data: CreateBankAccountRequest): Promise<AxiosResponse<BankAccount>> => {
  return getInstance().post('/user/bank-accounts', data)
}

export const deleteBankAccount = (id: string): Promise<AxiosResponse<{ success: true }>> => {
  return getInstance().delete(`/user/bank-accounts/${id}`)
}
