import { Button, Stat, StatHelpText, StatLabel, StatNumber, Table, Tbody, Td, Th, Thead, Tr, useDisclosure, useToast } from '@chakra-ui/react'
import { format } from 'date-fns'
import React, { useEffect, useState } from 'react'
import { getActiveTerm, getTerms } from '../../../api'
import Card from '../../../components/card'
import Header from '../../../components/header'
import Page from '../../../components/page'
import { Term } from '../../../models'
import TermModal from './components/term-modal'

const AdminTermsPage = () => {
  const [terms, setTerms] = useState<Term[]>()
  const [currentTerm, setCurrentTerm] = useState<Term>()
  const toast = useToast()
  const termModal = useDisclosure()
  const [reloadTerms, setReloadTerms] = useState<boolean>()

  useEffect(() => {
    getActiveTerm().then(({ data }) => {
      setCurrentTerm({
        id: '-1',
        title: data.title,
        terms: data.terms,
        createdAt: data.lastUpdate,
        isActive: true
      })
    }).catch(() => {
      toast({
        title: 'Erro',
        description: 'Não foi possível listar os termos de compra atual, tente novamente.',
        isClosable: true,
        status: 'error',
        position: 'top'
      })
    })
  }, [reloadTerms])

  useEffect(() => {
    getTerms().then(({ data }) => {
      setTerms(data)
    }).catch(() => {
      toast({
        title: 'Erro',
        description: 'Não foi possível listar os termos de compra, tente novamente.',
        isClosable: true,
        status: 'error',
        position: 'top'
      })
    })
  }, [reloadTerms])

  return (
    <>
      <Header />
      <Page>
        <Card
          title="Termos de compra"
          subHeader={(
            <Button onClick={termModal.onOpen} colorScheme="primary" size="sm">Atualizar termo de compra</Button>
          )}
        >
          <Stat marginY={8}>
            <StatLabel>Termo Atual</StatLabel>
            <StatNumber>{currentTerm?.title}</StatNumber>
            <StatHelpText>Atualizado em {currentTerm?.createdAt ? format((new Date(currentTerm?.createdAt)), 'dd/MM/yyyy \'às\' HH:mm') : '-'}</StatHelpText>
          </Stat>
          <Table variant="simple">
            <Thead>
              <Tr>
                <Th color="primary.600">Título</Th>
                <Th color="primary.600">Data de inclusão</Th>
              </Tr>
            </Thead>
            <Tbody>
              {terms?.map((item) => (
                <Tr key={item.id}>
                  <Td>{item.title}</Td>
                  <Td>{item?.createdAt ? format((new Date(item?.createdAt)), 'dd/MM/yyyy \'às\' HH:mm') : '-'}</Td>
                </Tr>
              ))}
            </Tbody>
          </Table>
        </Card>
      </Page>
      <TermModal
        modal={termModal}
        currentTerms={currentTerm}
        reloadTerms={reloadTerms}
        setReloadTerms={setReloadTerms}
      />
    </>
  )
}

export default AdminTermsPage
