/* eslint-disable camelcase */
import { Button, FormControl, FormErrorMessage, FormLabel, Grid, Input, Select, useToast } from '@chakra-ui/react'
import { Field, Form, Formik } from 'formik'
import React from 'react'
import { User } from '../../../models'
import { getCep, states, updateMe } from '../../../api'
import { accountsUpdateUser } from '../../../api/accounts'

type Props = {
  user: User,
  setUser: (user: User) => void
  formCallback?: () => void
}

const ProfileForm = ({ user, setUser, formCallback }: Props) => {
  const toast = useToast()

  async function handleCompleteAddressByCep (value: string, setFieldValue: any) {
    const cep = value.replace(/\D+/g, '')

    if (cep.length === 8) {
      try {
        const { data } = await getCep(cep)
        setFieldValue('address_street', data.logradouro)
        setFieldValue('address_neighborhood', data.bairro)
        setFieldValue('address_city', data.localidade)
        setFieldValue('address_state', data.uf)
      } catch (error) {
        //
      }
    }
  }

  return (
    <Formik
      enableReinitialize={true}
      initialValues={{
        name: user?.name,
        email: user?.email,
        phone: user?.phone,
        tax_number: user?.taxNumber,
        zipcode: user?.zipcode,
        address_street: user?.addressStreet,
        address_number: user?.addressNumber,
        address_neighborhood: user?.addressNeighborhood,
        address_observations: user?.addressObservations,
        address_city: user?.addressCity,
        address_state: user?.addressState
      }}
      validate={(values) => {
        const errors: {
          name?: string,
          email?: string,
          phone?: string,
          tax_number?: string,
          zipcode?: string,
          address_street?: string,
          address_number?: string,
          address_neighborhood?: string,
          address_observations?: string,
          address_city?: string,
          address_state?: string,
        } = {}

        if (!values.name) {
          errors.name = 'Informe o seu nome completo'
        } else if (values.name.length < 3) {
          errors.name = 'O nome deve conter no mínimo 3 caracteres'
        }

        if (!values.email) {
          errors.email = 'Informe o seu endereço de e-mail'
        } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)) {
          errors.email = 'Informe um e-mail válido'
        }

        if (!values.phone) {
          errors.phone = 'Informe seu número de telefone'
        }

        if (!values.tax_number) {
          errors.tax_number = 'Informe seu CPF'
        }

        if (!values.zipcode) {
          errors.zipcode = 'Informe seu CEP'
        }

        if (!values.address_street) {
          errors.address_street = 'Informe seu endereço'
        }

        if (!values.address_number) {
          errors.address_number = 'Informe seu número'
        }

        if (!values.address_neighborhood) {
          errors.address_neighborhood = 'Informe seu bairro'
        }

        if (!values.address_observations) {
          errors.address_observations = 'Informe seu complemento'
        }

        if (!values.address_city) {
          errors.address_city = 'Informe sua cidade'
        }

        if (!values.address_state) {
          errors.address_state = 'Informe seu estado'
        }

        return errors
      }}
      onSubmit={async (values, { setSubmitting }) => {
        setSubmitting(true)

        try {
          const response = await updateMe(values)

          if (user.accountsUserId) {
            accountsUpdateUser({
              name: values.name,
              taxNumber: values.tax_number,
              email: values.email,
              phone: values.phone,
              zipcode: values.zipcode,
              street: values.address_street,
              number: values.address_number,
              neighborhood: values.address_neighborhood,
              observations: values.address_observations,
              city: values.address_city,
              state: values.address_state
            })
              .then(() => ({}))
              .catch(() => ({}))
          }

          setUser(response.data)
          setSubmitting(false)
          toast({
            title: 'Tudo certo!',
            description: 'Seus dados foram atualizados com sucesso!',
            status: 'success',
            position: 'top',
            isClosable: true
          })

          if (formCallback) {
            formCallback()
          }
        } catch (error) {
          setSubmitting(false)
          let title = 'Erro'
          let message = 'Houve um erro ao atualizar seus dados, tente novamente.'

          if (error?.response) {
            if (error.response?.status === 422) {
              title = 'Informações inválidas'
              message = 'Preencha os campos corretamente e tente novamente.'
            }

            if (error.response?.data?.error === 'E_USER_EMAIL_ALREADY_EXISTS') {
              title = 'E-mail em uso'
              message = 'Já existe uma conta utilizando este endereço de e-mail.'
            }

            if (error.response?.status === 400 && error?.response?.data?.message) {
              title = 'Ops!'
              message = error?.response?.data?.message
            }
          }

          toast({
            title,
            description: message,
            status: 'error',
            position: 'top',
            isClosable: true
          })
        }
      }}
    >
      {(props) => (
        <Form>
          <Field name="name">
            {({ field, form }: any) => (
              <FormControl isRequired isInvalid={form.errors.name && form.touched.name} mb={5}>
                <FormLabel htmlFor="name">Nome completo</FormLabel>
                <Input {...field} type="text" id="name" placeholder="Seu nome completo" />
                <FormErrorMessage>{form.errors.name}</FormErrorMessage>
              </FormControl>
            )}
          </Field>
          <Grid templateColumns={{ sm: '1fr', md: '1fr 1fr' }} gap={5}>
            <Field name="tax_number">
              {({ field, form }: any) => (
                <FormControl isRequired isInvalid={form.errors.tax_number && form.touched.tax_number} mb={5}>
                  <FormLabel htmlFor="tax_number">CPF</FormLabel>
                  <Input {...field} type="tel" id="tax_number" placeholder="CPF" />
                  <FormErrorMessage>{form.errors.tax_number}</FormErrorMessage>
                </FormControl>
              )}
            </Field>
            <Field name="email">
              {({ field, form }: any) => (
                <FormControl isRequired isInvalid={form.errors.email && form.touched.email} mb={5}>
                  <FormLabel htmlFor="email">Endereço de e-mail</FormLabel>
                  <Input {...field} type="email" id="email" placeholder="Seu endereço de e-mail" />
                  <FormErrorMessage>{form.errors.email}</FormErrorMessage>
                </FormControl>
              )}
            </Field>
          </Grid>
          <Grid templateColumns={{ sm: '1fr', md: '1fr 1fr' }} gap={5}>
            <Field name="phone">
              {({ field, form }: any) => (
                <FormControl isRequired isInvalid={form.errors.phone && form.touched.phone} mb={5}>
                  <FormLabel htmlFor="phone">Telefone</FormLabel>
                  <Input {...field} type="tel" id="phone" placeholder="Telefone" />
                  <FormErrorMessage>{form.errors.phone}</FormErrorMessage>
                </FormControl>
              )}
            </Field>
            <Field name="zipcode">
              {({ field, form }: any) => (
                <FormControl isRequired isInvalid={form.errors.zipcode && form.touched.zipcode} mb={5}>
                  <FormLabel htmlFor="zipcode">CEP</FormLabel>
                  <Input {...field} type="tel" id="zipcode" placeholder="CEP" onChangeCapture={(val) => handleCompleteAddressByCep(val.currentTarget.value, props.setFieldValue)} />
                  <FormErrorMessage>{form.errors.zipcode}</FormErrorMessage>
                </FormControl>
              )}
            </Field>
          </Grid>
          <Grid templateColumns={{ sm: '1fr', md: '2fr 1fr' }} gap={5}>
            <Field name="address_street">
              {({ field, form }: any) => (
                <FormControl isRequired isInvalid={form.errors.address_street && form.touched.address_street} mb={5}>
                  <FormLabel htmlFor="address_street">Endereço</FormLabel>
                  <Input {...field} type="text" id="address_street" placeholder="Endereço" />
                  <FormErrorMessage>{form.errors.address_street}</FormErrorMessage>
                </FormControl>
              )}
            </Field>
            <Field name="address_number">
              {({ field, form }: any) => (
                <FormControl isRequired isInvalid={form.errors.address_number && form.touched.address_number} mb={5}>
                  <FormLabel htmlFor="address_number">Número</FormLabel>
                  <Input {...field} type="text" id="address_number" placeholder="Número" />
                  <FormErrorMessage>{form.errors.address_number}</FormErrorMessage>
                </FormControl>
              )}
            </Field>
          </Grid>
          <Grid templateColumns={{ sm: '1fr', md: '1fr 1fr' }} gap={5}>
            <Field name="address_neighborhood">
              {({ field, form }: any) => (
                <FormControl isRequired isInvalid={form.errors.address_neighborhood && form.touched.address_neighborhood} mb={5}>
                  <FormLabel htmlFor="address_neighborhood">Bairro</FormLabel>
                  <Input {...field} type="text" id="address_neighborhood" placeholder="Bairro" />
                  <FormErrorMessage>{form.errors.address_neighborhood}</FormErrorMessage>
                </FormControl>
              )}
            </Field>
            <Field name="address_observations">
              {({ field, form }: any) => (
                <FormControl isRequired isInvalid={form.errors.address_observations && form.touched.address_observations} mb={5}>
                  <FormLabel htmlFor="address_observations">Complemento</FormLabel>
                  <Input {...field} type="text" id="address_observations" placeholder="Complemento" />
                  <FormErrorMessage>{form.errors.address_observations}</FormErrorMessage>
                </FormControl>
              )}
            </Field>
          </Grid>
          <Grid templateColumns={{ sm: '1fr', md: '1fr 1fr' }} gap={5}>
            <Field name="address_city">
              {({ field, form }: any) => (
                <FormControl isRequired isInvalid={form.errors.address_city && form.touched.address_city} mb={5}>
                  <FormLabel htmlFor="address_city">Cidade</FormLabel>
                  <Input {...field} type="text" id="address_city" placeholder="Cidade" />
                  <FormErrorMessage>{form.errors.address_city}</FormErrorMessage>
                </FormControl>
              )}
            </Field>
            <Field name="address_state">
              {({ field, form }: any) => (
                <FormControl isRequired isInvalid={form.errors.address_state && form.touched.address_state} mb={5}>
                  <FormLabel htmlFor="address_state">Estado</FormLabel>
                  <Select {...field} id="address_state">
                    <option value="">Selecione</option>
                    {states.map((state: { id: string, name: string, uf: string}) => <option key={state.id} value={state.uf}>{state.name}</option>)}
                  </Select>
                  <FormErrorMessage>{form.errors.address_state}</FormErrorMessage>
                </FormControl>
              )}
            </Field>
          </Grid>
          <Button
            type="submit"
            isFullWidth
            colorScheme="primary"
            disabled={props.isSubmitting}
          >
            Salvar
          </Button>
        </Form>
      )}
    </Formik>
  )
}

export default ProfileForm
