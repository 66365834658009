import { createContext, useContext } from 'react'

type overlayContextType = {
  blur: string | undefined,
  setBlur: (blur: string) => void
}

const initialValue = {
  blur: 'blur(0px)',
  setBlur: () => ({})
}

export const OverlayContext = createContext<overlayContextType>(initialValue)
export const useOverlay = () => useContext(OverlayContext)
