import { createContext, useContext } from 'react'
import { User } from '../models'

type UserContextType = {
  user: User | undefined
  isAuthenticated: boolean | undefined
  setUser: (user: User) => void
  setIsAuthenticated: (authenticated: boolean) => void
}

const defaultValue: UserContextType = {
  user: undefined,
  isAuthenticated: undefined,
  setUser: () => ({}),
  setIsAuthenticated: () => ({})
}

export const UserContext = createContext<UserContextType>(defaultValue)
export const useUser = () => useContext(UserContext)
