import React, { useEffect, useState } from 'react'
import { Router, Switch } from 'react-router-dom'
import { createBrowserHistory } from 'history'
import { GuardedRoute, PublicRoute } from './guards'
import WalletPage from './pages/Wallet'
import LogoutPage from './pages/Logout'
import { useCookies } from 'react-cookie'
import DepositPage from './pages/Deposit'
import WithdrawalsPage from './pages/Withdrawals'
import BankAccountsPage from './pages/BankAccounts'
import ProfilePage from './pages/Profile'
import PasswordPage from './pages/Password'
import { ProductsContext, TermsContext, UserContext, WalletContext, PaymentMethodsContext, OverlayContext } from './contexts'
import { PaymentMethod, Product, User, Wallet } from './models'
import { getMe } from './api'
import WithdrawnAllPage from './pages/WithdrawnAll'
import AdminYieldsPage from './pages/Admin/Yields'
import AdminTermsPage from './pages/Admin/Terms'
import HistoryPage from './pages/History'
import AdminProductsPage from './pages/Admin/Products'
import AdminWithdrawalsPage from './pages/Admin/Withdrawals'
import AdminUsersPage from './pages/Admin/Users'
import { useAffiliate } from './utils'
import { GetActiveTermResponse } from './interfaces'
import SupportPage from './pages/Support'
import AdminArticlesPage from './pages/Admin/Articles'
import AdminOrdersPage from './pages/Admin/Orders'
import AuthorizePage from './pages/Authorize'
import RedirectToAccountsPage from './pages/RedirectToAccounts'
import { AUTH_TOKEN_KEY } from './config'

const history = createBrowserHistory()

export const App = () => {
  const [cookies, setCookie] = useCookies([AUTH_TOKEN_KEY])
  const hasCookie = cookies?.[AUTH_TOKEN_KEY]
  const [user, setUser] = useState<User>()
  const [terms, setTerms] = useState<GetActiveTermResponse>()
  const [products, setProducts] = useState<Product[]>()
  const [isAuthenticated, setIsAuthenticated] = useState<boolean>()
  const [wallet, setWallet] = useState<Wallet>()
  const [paymentMethods, setPaymentMethods] = useState<PaymentMethod[]>()
  const [blur, setBlur] = useState<string>()

  useAffiliate(setCookie)

  useEffect(() => {
    if (isAuthenticated) {
      getMe().then(({ data }) => {
        setUser(data)
      }).catch(() => {
        //
      })
    }
  }, [isAuthenticated])

  useEffect(() => {
    setIsAuthenticated(!!hasCookie)
  }, [hasCookie])

  return (
    <OverlayContext.Provider value={{ blur, setBlur }} >
      <UserContext.Provider value={{ user, isAuthenticated, setUser, setIsAuthenticated }}>
        <TermsContext.Provider value={{ terms, setTerms }}>
          <ProductsContext.Provider value={{ products, setProducts }}>
            <WalletContext.Provider value={{ wallet, setWallet }}>
              <PaymentMethodsContext.Provider value={{ paymentMethods, setPaymentMethods }}>
                <Router history={history}>
                  <Switch>
                    <GuardedRoute path="/" exact component={WalletPage} />
                    <PublicRoute path="/authorize" component={AuthorizePage} />
                    <PublicRoute path="/login/forgot" component={RedirectToAccountsPage} />
                    <PublicRoute path="/login/reset-password" component={RedirectToAccountsPage} />
                    <PublicRoute path="/login" component={RedirectToAccountsPage} />
                    <PublicRoute path="/signup" component={RedirectToAccountsPage} />
                    <GuardedRoute path="/logout" component={LogoutPage} />
                    <GuardedRoute path="/history" component={HistoryPage} />
                    <GuardedRoute path="/deposit" component={DepositPage} />
                    <GuardedRoute path="/withdraw/cancel" component={WithdrawnAllPage} />
                    <GuardedRoute path="/withdraw" component={WithdrawalsPage} />
                    <GuardedRoute path="/profile/bank-accounts" component={BankAccountsPage} />
                    <GuardedRoute path="/profile" component={ProfilePage} />
                    <GuardedRoute path="/password" component={PasswordPage} />
                    <GuardedRoute path="/support" component={SupportPage} />
                  </Switch>
                  <Switch>
                    <GuardedRoute path="/admin/yields" component={AdminYieldsPage} />
                    <GuardedRoute path="/admin/terms" component={AdminTermsPage} />
                    <GuardedRoute path="/admin/products" component={AdminProductsPage} />
                    <GuardedRoute path="/admin/withdrawals" component={AdminWithdrawalsPage} />
                    <GuardedRoute path="/admin/users" component={AdminUsersPage} />
                    <GuardedRoute path="/admin/articles" component={AdminArticlesPage} />
                    <GuardedRoute path="/admin/orders" component={AdminOrdersPage} />
                  </Switch>
                </Router>
              </PaymentMethodsContext.Provider>
            </WalletContext.Provider>
          </ProductsContext.Provider>
        </TermsContext.Provider>
      </UserContext.Provider>
    </OverlayContext.Provider>
  )
}
