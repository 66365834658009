import React from 'react'
import { Container } from '@chakra-ui/react'
import Footer from '../footer'

type PropTypes = {
  children: React.ReactNode
}

const Page = (props: PropTypes) => {
  return (
  <>
    <Container minW={{ lg: '85%' }} py={10}>
      {props.children}
      <Footer />
    </Container>
  </>)
}

export default Page
