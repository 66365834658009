import { createContext, useContext } from 'react'
import { Wallet } from '../models'

type WalletContextType = {
  wallet: Wallet | undefined
  setWallet: (wallet: Wallet) => void
}

const defaultValue: WalletContextType = {
  wallet: undefined,
  setWallet: () => ({})
}

export const WalletContext = createContext<WalletContextType>(defaultValue)
export const useWallet = () => useContext(WalletContext)
