export const API_URL = process.env.REACT_APP_ENV === 'production' ? 'https://api.nextgoinvest.com/v1' : 'http://localhost:5000/v1'
export const CLIENT_ID = '607e5465ac1b037f629a622c'
export const AUTH_TOKEN_KEY = '__token'
export const ACCOUNTS_TOKEN_KEY = '__accounts_token'
export const ACCOUNTS_API_PRODUCTION_URL = 'https://api.conta.nextgomarketing.com/v1'
export const ACCOUNTS_API_STAGING_URL = 'https://api.conta-staging.nextgomarketing.com/v1'
export const ACCOUNTS_API_URL = process.env.REACT_APP_ENV === 'production' ? ACCOUNTS_API_PRODUCTION_URL : ACCOUNTS_API_STAGING_URL
export const ACCOUNTS_CLIENT_PRODUCTION_URL = 'https://conta.nextgomarketing.com'
export const ACCOUNTS_CLIENT_STAGING_URL = 'https://conta-staging.nextgomarketing.com'
export const ACCOUNTS_URL = process.env.REACT_APP_ENV === 'production' ? ACCOUNTS_CLIENT_PRODUCTION_URL : ACCOUNTS_CLIENT_STAGING_URL
export const WITHDRAWN_TAX = 13
export const WITHDRAWN_MINIMUM_AMOUNT = 100
