import React, { useRef, useState } from 'react'
import { AlertDialog, AlertDialogBody, AlertDialogContent, AlertDialogFooter, AlertDialogHeader, AlertDialogOverlay, Button, IconButton, Menu, MenuButton, MenuItem, MenuList, Table, Tbody, Td, Th, Thead, Tr, useToast } from '@chakra-ui/react'
import { FiMoreVertical, FiTrash } from 'react-icons/fi'
import { BankAccount } from '../../../models'
import { deleteBankAccount } from '../../../api'

type Props = {
  bankAccounts: BankAccount[]
  setBankAccounts: (bankAccounts: BankAccount[]) => void
}

const BankAccountsTable = ({ bankAccounts, setBankAccounts }: Props) => {
  const toast = useToast()
  const [currentAccount, setCurrentAccount] = useState<BankAccount | null>()
  const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState<boolean>(false)
  const onDeleteDialogClose = () => setIsDeleteDialogOpen(false)
  const cancelRef = useRef<any>()

  function handleOpenDeleteDialog (bankAccount?: BankAccount | null) {
    setIsDeleteDialogOpen(true)
    setCurrentAccount(bankAccount)
  }

  async function handleDelete (bankAccount?: BankAccount | null) {
    if (bankAccount) {
      try {
        await deleteBankAccount(bankAccount.id)
        setBankAccounts(bankAccounts?.filter(el => el.id !== bankAccount.id))
        toast({
          title: 'Tudo certo!',
          description: 'A conta bancária foi excluída com sucesso!',
          status: 'success',
          position: 'top',
          isClosable: true
        })
        setIsDeleteDialogOpen(false)
        setCurrentAccount(null)
      } catch (error) {
        setIsDeleteDialogOpen(false)
        setCurrentAccount(null)
        toast({
          title: 'Ops!',
          description: 'Houve um erro ao excluir a conta bancária, tente novamente.',
          status: 'error',
          position: 'top',
          isClosable: true
        })
      }
    }
  }

  return (
    <>
      <Table variant="simple">
        <Thead>
          <Tr>
            <Th color="primary.600">Tipo</Th>
            <Th color="primary.600">Banco/Agência/Conta</Th>
            <Th color="primary.600">Chave PIX</Th>
            <Th color="primary.600">Titular</Th>
            <Th color="primary.600"></Th>
          </Tr>
        </Thead>
        <Tbody>
          {bankAccounts?.map((bankAccount) => (
            <Tr key={bankAccount.id}>
              <Td>{bankAccount.accountType}</Td>
              <Td>
                {bankAccount.accountType !== 'PIX'
                  ? `${bankAccount.bankName} / Ag.: ${bankAccount.agencyCode} / Conta: ${bankAccount.accountNumber}`
                  : '-'
                }
              </Td>
              <Td>
                {bankAccount.accountType === 'PIX'
                  ? `${bankAccount.pixKeyValue} (${bankAccount.pixKeyType})`
                  : '-'
                }
              </Td>
              <Td>{bankAccount.holderName}</Td>
              <Td>
                <Menu placement="bottom-end">
                  <MenuButton
                    as={IconButton}
                    aria-label="Ações"
                    icon={<FiMoreVertical />}
                    variant="ghost"
                  />
                  <MenuList>
                    <MenuItem icon={<FiTrash />} onClick={() => handleOpenDeleteDialog(bankAccount)}>
                      Excluir
                    </MenuItem>
                  </MenuList>
                </Menu>
              </Td>
            </Tr>
          ))}
        </Tbody>
      </Table>
      <AlertDialog
        isOpen={isDeleteDialogOpen}
        leastDestructiveRef={cancelRef}
        onClose={onDeleteDialogClose}
      >
        <AlertDialogOverlay>
          <AlertDialogContent>
            <AlertDialogHeader fontSize="lg" fontWeight="bold">
              Excluir conta bancária
            </AlertDialogHeader>
            <AlertDialogBody>
              Você tem certeza que deseja excluir esta conta bancária?
            </AlertDialogBody>
            <AlertDialogFooter>
              <Button ref={cancelRef} onClick={onDeleteDialogClose} variant="ghost">
                Cancelar
              </Button>
              <Button colorScheme="red" onClick={() => handleDelete(currentAccount)} ml={3}>
                Excluir
              </Button>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>
    </>
  )
}

export default BankAccountsTable
