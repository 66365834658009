import { AxiosResponse } from 'axios'
import { GetPerformancesByRangeParams } from '../interfaces'
import { getInstance } from './config'
import { Performance } from '../models'

export const getPerformancesByRange = (params?: GetPerformancesByRangeParams): Promise<AxiosResponse<Performance[]>> => {
  return getInstance().get('/performances', { params })
}

interface CreatePerformance extends Performance {
  updated?: boolean;
}

export const createPerformance = (data: Partial<Performance>): Promise<AxiosResponse<CreatePerformance>> => {
  return getInstance().post('/performances', data)
}
