import { Box, Button, Divider, FormControl, FormErrorMessage, FormLabel, Input, ModalBody, ModalFooter, Textarea, useToast } from '@chakra-ui/react'
import { Field, Form, Formik } from 'formik'
import React, { useEffect } from 'react'
import { createTicket } from '../../api'
import Card from '../../components/card'
import Header from '../../components/header'
import Page from '../../components/page'
import PageInstructionParagraph from '../../components/page-instructions-paragraph'
import { useUser } from '../../contexts'

const SupportPage = () => {
  const { user } = useUser()
  const toast = useToast()

  return (
    <>
      <Header />
      <Page>
        <Box maxW={{ sm: '100%', lg: '3xl' }} margin="auto">
          <Card title="Suporte">
            <PageInstructionParagraph instructions="Preencha o formulário abaixo para entrar em contato conosco:" />
            <Divider mt={5} mb={5} />
            <Formik
              initialValues={{
                name: user?.name,
                email: user?.email,
                phone: user?.phone,
                message: ''
              }}
              validate={(values) => {
                const errors: {
                  name?: string,
                  email?: string,
                  phone?: string,
                  message?: string,
                } = {}

                if (!values.name) {
                  errors.name = 'Informe o seu nome'
                }

                if (!values.email) {
                  errors.email = 'Informe o seu endereço de e-mail'
                }

                if (!values.phone) {
                  errors.phone = 'Informe o seu telefone'
                }

                if (!values.message) {
                  errors.message = 'Escreva a sua mensagem'
                }

                return errors
              }}
              onSubmit={async (values, { setSubmitting, resetForm }) => {
                setSubmitting(true)

                try {
                  await createTicket(values)
                  setSubmitting(false)
                  resetForm()
                  toast({
                    title: 'Tudo certo!',
                    description: 'Sua mensagem foi enviada com sucesso e em breve entraremos em contato!',
                    status: 'success',
                    position: 'top',
                    isClosable: true
                  })
                } catch (error) {
                  setSubmitting(false)
                  let title = 'Erro'
                  let message = 'Houve um erro ao enviar sua mensagem, tente novamente.'

                  if (error?.response) {
                    if (error.response?.status === 422) {
                      title = 'Informações inválidas'
                      message = 'Preencha os campos corretamente e tente novamente.'
                    }
                  }

                  toast({
                    title,
                    description: message,
                    status: 'error',
                    position: 'top',
                    isClosable: true
                  })
                }
              }}
            >
              {(props: any) => (
                <Form>
                  <Field name="name">
                    {({ field, form }: any) => (
                      <FormControl isRequired isInvalid={form.errors.name && form.touched.name} mb={5}>
                        <FormLabel htmlFor="name">Seu nome completo</FormLabel>
                        <Input {...field} type="text" id="name" placeholder="Nome completo" />
                        <FormErrorMessage>{form.errors.name}</FormErrorMessage>
                      </FormControl>
                    )}
                  </Field>
                  <Field name="phone">
                    {({ field, form }: any) => (
                      <FormControl isRequired isInvalid={form.errors.phone && form.touched.phone} mb={5}>
                        <FormLabel htmlFor="phone">Telefone</FormLabel>
                        <Input {...field} type="tel" id="phone" placeholder="Telefone" />
                        <FormErrorMessage>{form.errors.phone}</FormErrorMessage>
                      </FormControl>
                    )}
                  </Field>
                  <Field name="email">
                    {({ field, form }: any) => (
                      <FormControl isRequired isInvalid={form.errors.email && form.touched.email} mb={5}>
                        <FormLabel htmlFor="email">Endereço de e-mail</FormLabel>
                        <Input {...field} type="email" id="email" placeholder="Endereço de e-mail" />
                        <FormErrorMessage>{form.errors.email}</FormErrorMessage>
                      </FormControl>
                    )}
                  </Field>
                  <Field name="message">
                    {({ field, form }: any) => (
                      <FormControl isRequired isInvalid={form.errors.message && form.touched.message} mb={5}>
                        <FormLabel htmlFor="message">Sua mensagem</FormLabel>
                        <Textarea {...field} id="message" minHeight={120} placeholder="Mensagem" />
                        <FormErrorMessage>{form.errors.message}</FormErrorMessage>
                      </FormControl>
                    )}
                  </Field>
                  <Button
                    type="submit"
                    colorScheme="primary"
                    disabled={props.isSubmitting}
                    isFullWidth
                  >Enviar</Button>
                </Form>
              )}
            </Formik>
          </Card>
        </Box>
      </Page>
    </>
  )
}

export default SupportPage
