import React, { useState } from 'react'
import { Button, Checkbox, Modal, ModalBody, ModalCloseButton, ModalContent, ModalFooter, ModalHeader, ModalOverlay, UseDisclosureProps, useToast } from '@chakra-ui/react'
import { cancelContract } from '../../../api'
import { useHistory } from 'react-router-dom'

type Props = {
  modal: UseDisclosureProps
}

const ConfirmCancelContractModal = ({ modal }: Props) => {
  const toast = useToast()
  const history = useHistory()
  const [termsAccepted, setTermsAccepted] = useState<boolean>()
  const [isLoading, setIsLoading] = useState<boolean>()

  async function onCancelContract () {
    setIsLoading(true)

    try {
      await cancelContract()
      setIsLoading(false)
      toast({
        title: 'Tudo certo!',
        description: 'Sua solicitação de cancelamento de contrato foi enviada com sucesso!',
        status: 'success',
        position: 'top',
        isClosable: true,
        duration: 10000
      })
      modal?.onClose?.()
      history.push('/')
    } catch (error) {
      setIsLoading(false)
      toast({
        title: 'Ops!',
        description: 'Houve um erro ao cancelar seu contrato. Caso persista, entre em contato com o suporte.',
        status: 'error',
        position: 'top',
        isClosable: true,
        duration: 10000
      })
    }
  }

  return (
    <Modal size="xl" isOpen={modal.isOpen || false} onClose={modal.onClose || (() => ({}))} closeOnOverlayClick={false} blockScrollOnMount={true}>
      <ModalOverlay />
      <ModalContent color="white" backgroundColor="secondaryBackground">
        <ModalHeader>Confirmar cancelamento de contrato</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <Checkbox alignItems="center" spacing={5} isChecked={termsAccepted} onChange={(e) => setTermsAccepted(e.target.checked)}>
            Tenho certeza que desejo cancelar meu contrato
          </Checkbox>
        </ModalBody>
        <ModalFooter>
          <Button variant="ghost" onClick={modal.onClose}>Voltar</Button>
          <Button type="submit" colorScheme="red" ml={3} disabled={!termsAccepted} onClick={onCancelContract} isLoading={isLoading}>Cancelar contrato</Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  )
}

export default ConfirmCancelContractModal
