import { Box, Button, Text } from '@chakra-ui/react'
import React, { useEffect, useState } from 'react'
import { getTransactions } from '../../api'
import Card from '../../components/card'
import Header from '../../components/header'
import Page from '../../components/page'
import TransactionItem from '../../components/transaction-item'
import { Transaction } from '../../models'

const HistoryPage = () => {
  const [total, setTotal] = useState<number>()
  const [page, setPage] = useState<number>()
  const [transactions, setTransactions] = useState<Transaction[]>()
  const [canNext, setCanNext] = useState<boolean>()
  const [canPrev, setCanPrev] = useState<boolean>()

  useEffect(() => {
    fetchTransactions(1)
  }, [])

  function fetchTransactions (pageNumber = 1) {
    getTransactions({
      page: pageNumber
    }).then(({ data }) => {
      const { meta } = data
      setTransactions(data.data)
      setTotal(meta?.total || 0)
      setCanNext(meta.page! < meta.totalPages!)
      setCanPrev(meta.page! > 1)
    })
  }

  const handleNext = () => {
    const nextPage = (page || 1) + 1
    setPage(nextPage)
    fetchTransactions(nextPage)
  }

  const handlePrev = () => {
    const previousPage = (page || 1) - 1
    setPage(previousPage > 0 ? previousPage : 1)
    fetchTransactions(previousPage)
  }

  return (
    <>
      <Header />
      <Page>
        <Card
          title={`Histórico de transações (${total || 0})`}
        >
          {(!transactions || transactions.length === 0) && <Text textAlign="center" paddingY={4} fontSize={14} fontWeight={500}>Você não possui nenhuma transação.</Text>}
          {transactions && transactions.length > 0 && (
            <>
              <Box>
                {transactions?.map((transaction) => (
                  <TransactionItem
                    key={transaction.id}
                    title={transaction.description as string}
                    transactionAt={transaction.createdAt as string}
                    amount={String(transaction.amount)}
                    type={transaction.type}
                    status={transaction.status}
                    cancelReason={transaction.cancelReason}
                    customFields={transaction.customFields}
                  />
                ))}
              </Box>
              <Box mt={12} display="flex" justifyContent="center">
                <Button variant="ghost" mr={2} disabled={!canPrev} onClick={() => handlePrev()}>
                  Anterior
                </Button>
                <Button variant="ghost" disabled={!canNext} onClick={() => handleNext()}>
                  Próxima
                </Button>
              </Box>
            </>
          )}
        </Card>
      </Page>
    </>
  )
}

export default HistoryPage
