/* eslint-disable camelcase */
// eslint-disable-next-line @typescript-eslint/triple-slash-reference
/// <reference path='../../../../@types/index.d.ts' />

import React, { ChangeEvent } from 'react'
import { Switch, Button, FormControl, FormErrorMessage, FormHelperText, FormLabel, Grid, Input, Modal, ModalBody, ModalCloseButton, ModalContent, ModalFooter, ModalHeader, ModalOverlay, Textarea, UseDisclosureProps, useToast } from '@chakra-ui/react'
import { Field, Form, Formik } from 'formik'
import { updateProduct } from '../../../../api'
import IntlCurrencyInput from 'react-intl-currency-input'
import { Product } from '../../../../models'

type Props = {
  modal: UseDisclosureProps
  product: Product
  reloadProducts?: boolean
  setReloadProducts?: (reload: boolean) => void
}

const EditProductModal = ({ modal, product, reloadProducts, setReloadProducts }: Props) => {
  const toast = useToast()

  const currencyConfig = {
    locale: 'pt-BR',
    formats: {
      number: {
        BRL: {
          style: 'currency',
          currency: 'BRL',
          minimumFractionDigits: 2,
          maximumFractionDigits: 2
        }
      }
    }
  }

  return (
    <Modal size="xl" isOpen={modal.isOpen || false} onClose={modal.onClose || (() => ({}))} closeOnOverlayClick={false} blockScrollOnMount={true}>
      <ModalOverlay />
      <ModalContent color="white" backgroundColor="secondaryBackground">
        <ModalHeader>Editar produto</ModalHeader>
        <ModalCloseButton />
        <Formik
          initialValues={{
            name: product.name || '',
            description: product.description || '',
            amount: String(product.amount) || '',
            term_period: String(product.termPeriod || ''),
            grace_period: String(product.gracePeriod || ''),
            cover: '',
            is_active: product.isActive || false,
            reference_id: product.referenceId || ''
          }}
          validate={(values) => {
            const errors: {
              name?: string,
              description?: string,
              amount?: string,
              term_period?: string,
              grace_period?: string,
              cover?: string,
              is_active?: string,
              reference_id?: string,
            } = {}

            if (!values.name) {
              errors.name = 'Informe o nome do produto'
            }

            if (!values.description) {
              errors.description = 'Informe a descrição do produto'
            }

            if (!values.amount) {
              errors.amount = 'Informe o valor do produto'
            }

            if (!values.term_period) {
              errors.term_period = 'Informe o período de vigência do produto'
            }

            if (!values.grace_period) {
              errors.grace_period = 'Informe o período de carência do produto'
            }

            if (!values.reference_id) {
              errors.reference_id = 'Informe o ID de referência Max Nível'
            }

            return errors
          }}
          onSubmit={async (values, { setSubmitting }) => {
            setSubmitting(true)

            try {
              await updateProduct(product.id, values)
              setSubmitting(false)
              toast({
                title: 'Tudo certo!',
                description: 'O produto foi salvo com sucessi!',
                status: 'success',
                position: 'top',
                isClosable: true
              })

              modal?.onClose?.()

              if (setReloadProducts) {
                setReloadProducts(!reloadProducts)
              }
            } catch (error) {
              setSubmitting(false)
              let title = 'Erro'
              let message = 'Houve um erro ao salvar o produto, tente novamente.'

              if (error?.response) {
                if (error.response?.status === 422) {
                  title = 'Informações inválidas'
                  message = 'Preencha os campos corretamente e tente novamente.'
                }
              }

              toast({
                title,
                description: message,
                status: 'error',
                position: 'top',
                isClosable: true
              })
            }
          }}
        >
          {(props: any) => (
            <Form>
              <ModalBody>
                <Field name="name">
                  {({ field, form }: any) => (
                    <FormControl isRequired isInvalid={form.errors.name && form.touched.name} mb={5}>
                      <FormLabel htmlFor="name">Nome do produto</FormLabel>
                      <Input {...field} name="name" id="name" placeholder="Nome do produto" />
                      <FormErrorMessage>{form.errors.name}</FormErrorMessage>
                    </FormControl>
                  )}
                </Field>
                <Field name="description">
                  {({ field, form }: any) => (
                    <FormControl isRequired isInvalid={form.errors.description && form.touched.description} mb={5}>
                      <FormLabel htmlFor="description">Descrição</FormLabel>
                      <Textarea {...field} name="description" id="description" placeholder="Descrição" />
                      <FormErrorMessage>{form.errors.description}</FormErrorMessage>
                    </FormControl>
                  )}
                </Field>
                <Field name="amount">
                  {({ field, form }: any) => (
                    <FormControl isRequired isInvalid={form.errors.amount && form.touched.amount} mb={5}>
                      <FormLabel htmlFor="amount">Valor do produto</FormLabel>
                      <Input {...field} onChange={(event: ChangeEvent, value: number) => props.setFieldValue('amount', value)} type="tel" as={IntlCurrencyInput} currency="BRL" config={currencyConfig} name="amount" id="amount" placeholder="Valor do produto" />
                      <FormErrorMessage>{form.errors.amount}</FormErrorMessage>
                    </FormControl>
                  )}
                </Field>
                <Grid gridTemplateColumns="1fr 1fr" gap={5}>
                  <Field name="term_period">
                    {({ field, form }: any) => (
                      <FormControl isRequired isInvalid={form.errors.term_period && form.touched.term_period} mb={5}>
                        <FormLabel htmlFor="term_period">Vigência (dias)</FormLabel>
                        <Input {...field} type="number" name="term_period" id="term_period" placeholder="Vigência" />
                        <FormHelperText>Período em dias que o produto estará disponível para ganhos de lucro.</FormHelperText>
                        <FormErrorMessage>{form.errors.term_period}</FormErrorMessage>
                      </FormControl>
                    )}
                  </Field>
                  <Field name="grace_period">
                    {({ field, form }: any) => (
                      <FormControl isRequired isInvalid={form.errors.grace_period && form.touched.grace_period} mb={5}>
                        <FormLabel htmlFor="grace_period">Carência (dias)</FormLabel>
                        <Input {...field} type="number" name="grace_period" id="grace_period" placeholder="Carência" />
                        <FormHelperText>Período em dias que o produto não obterá lucros após a compra do produto.</FormHelperText>
                        <FormErrorMessage>{form.errors.grace_period}</FormErrorMessage>
                      </FormControl>
                    )}
                  </Field>
                </Grid>
                <Grid gridTemplateColumns="1fr 1fr" gap={5}>
                  <Field name="reference_id">
                    {({ field, form }: any) => (
                      <FormControl isRequired isInvalid={form.errors.reference_id && form.touched.reference_id} mb={5}>
                        <FormLabel htmlFor="reference_id">ID de referência</FormLabel>
                        <Input {...field} type="text" name="reference_id" id="reference_id" placeholder="ID de referência" />
                        <FormHelperText>ID do produto no sistema Max Nível.</FormHelperText>
                        <FormErrorMessage>{form.errors.reference_id}</FormErrorMessage>
                      </FormControl>
                    )}
                  </Field>
                  <Field name="is_active">
                    {({ field, form }: any) => (
                      <FormControl isRequired isInvalid={form.errors.is_active && form.touched.is_active} mb={5}>
                        <FormLabel htmlFor="is_active">Ativo</FormLabel>
                        <Switch mb={1} mt={1} {...field} id="is_active" size="lg" isChecked={field?.value || false} />
                        <FormHelperText>Os produtos inativos não são exibidos para os usuários.</FormHelperText>
                        <FormErrorMessage>{form.errors.is_active}</FormErrorMessage>
                      </FormControl>
                    )}
                  </Field>
                </Grid>
              </ModalBody>
              <ModalFooter>
                <Button variant="ghost" onClick={modal.onClose}>Cancelar</Button>
                <Button type="submit" colorScheme="primary" ml={3} disabled={props.isSubmitting}>Salvar</Button>
              </ModalFooter>
            </Form>
          )}
        </Formik>
      </ModalContent>
    </Modal>
  )
}

export default EditProductModal
