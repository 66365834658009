/* eslint-disable camelcase */
import { Button, FormControl, FormErrorMessage, FormHelperText, FormLabel, Input, useToast } from '@chakra-ui/react'
import { Field, Form, Formik } from 'formik'
import React from 'react'
import { updatePassword } from '../../../api'
import { accountsUpdatePassword } from '../../../api/accounts'
import { useUser } from '../../../contexts'

const PasswordForm = () => {
  const { user } = useUser()
  const toast = useToast()

  return (
    <Formik
      initialValues={{
        password: '',
        password_confirmation: ''
      }}
      validate={(values) => {
        const errors: {
          password?: string,
          password_confirmation?: string
        } = {}

        if (!values.password) {
          errors.password = 'Escolha sua nova senha'
        } else if (values.password.length < 6) {
          errors.password = 'Sua nova senha deve conter no mínimo 6 caracteres'
        }

        if (!values.password_confirmation) {
          errors.password_confirmation = 'Confirme sua nova senha'
        } else if (values.password !== values.password_confirmation) {
          errors.password_confirmation = 'As senhas não coincidem'
        }

        return errors
      }}
      onSubmit={async (values, { setSubmitting, resetForm }) => {
        setSubmitting(true)

        try {
          await updatePassword(values)

          if (user?.accountsUserId) {
            accountsUpdatePassword({
              password: values.password,
              passwordConfirmation: values.password
            }).then(() => ({})).catch(() => ({}))
          }

          toast({
            title: 'Tudo certo!',
            description: 'Sua senha foi alterada com sucesso!',
            status: 'success',
            position: 'top',
            isClosable: true
          })
          setSubmitting(false)
          resetForm()
        } catch (error) {
          setSubmitting(false)
          let title = 'Erro'
          let message = 'Houve um erro ao atualizar sua senha, tente novamente.'

          if (error?.response) {
            if (error.response?.status === 422) {
              title = 'Informações inválidas'
              message = 'Preencha os campos corretamente e tente novamente.'
            }

            if (error.response?.data?.error === 'E_INVALID_CURRENT_PASSWORD') {
              title = 'Senha atual inválida'
              message = 'Esta não é a sua senha atual, verifique o valor digitado e tente novamente.'
            }
          }

          toast({
            title,
            description: message,
            status: 'error',
            position: 'top',
            isClosable: true
          })
        }
      }}
    >
      {(props) => (
        <Form>
          <Field name="password">
            {({ field, form }: any) => (
              <FormControl isRequired isInvalid={form.errors.password && form.touched.password} mb={5}>
                <FormLabel htmlFor="password">Escolha sua nova senha</FormLabel>
                <Input {...field} type="password" id="password" placeholder="Sua nova senha" />
                <FormHelperText>Mínimo 6 caracteres.</FormHelperText>
                <FormErrorMessage>{form.errors.password}</FormErrorMessage>
              </FormControl>
            )}
          </Field>
          <Field name="password_confirmation">
            {({ field, form }: any) => (
              <FormControl isRequired isInvalid={form.errors.password_confirmation && form.touched.password_confirmation} mb={5}>
                <FormLabel htmlFor="password_confirmation">Confirme sua nova senha</FormLabel>
                <Input {...field} type="password" id="password_confirmation" placeholder="Confirme sua nova senha" />
                <FormErrorMessage>{form.errors.password_confirmation}</FormErrorMessage>
              </FormControl>
            )}
          </Field>
          <Button
            type="submit"
            isFullWidth
            colorScheme="primary"
            disabled={props.isSubmitting}
          >
            Salvar
          </Button>
        </Form>
      )}
    </Formik>
  )
}

export default PasswordForm
