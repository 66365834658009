import { Button, Stat, StatHelpText, StatLabel, StatNumber, Table, Tbody, Td, Th, Thead, Tr, useDisclosure, useToast } from '@chakra-ui/react'
import { format } from 'date-fns'
import React, { useEffect, useState } from 'react'
import { getActiveYield, getYields } from '../../../api'
import Card from '../../../components/card'
import Header from '../../../components/header'
import Page from '../../../components/page'
import { Yield } from '../../../models'
import YieldModal from './components/yield-modal'
import PerformanceModal from './components/performance-modal'

const AdminYieldsPage = () => {
  const [yields, setYields] = useState<Yield[]>()
  const [currentYield, setCurrentYield] = useState<Yield>()
  const toast = useToast()
  const yieldModal = useDisclosure()
  const performanceModal = useDisclosure()
  const [reloadYields, setReloadYields] = useState<boolean>()

  useEffect(() => {
    getActiveYield().then(({ data }) => {
      setCurrentYield({
        id: '-1',
        index: data.index,
        createdAt: data.lastUpdate,
        isActive: true
      })
    }).catch(() => {
      toast({
        title: 'Erro',
        description: 'Não foi possível listar o índice atual, tente novamente.',
        isClosable: true,
        status: 'error',
        position: 'top'
      })
    })
  }, [reloadYields])

  useEffect(() => {
    getYields().then(({ data }) => {
      setYields(data)
    }).catch(() => {
      toast({
        title: 'Erro',
        description: 'Não foi possível listar os índices, tente novamente.',
        isClosable: true,
        status: 'error',
        position: 'top'
      })
    })
  }, [reloadYields])

  function formatYield (value: string): string {
    return Number(value).toFixed(2).replace('.', ',')
  }

  return (
    <>
      <Header />
      <Page>
        <Card
          title="Índices de lucro"
          subHeader={(
            <>
              <Button onClick={yieldModal.onOpen} colorScheme="primary" size="sm">Atualizar índice atual</Button>
              <Button onClick={performanceModal.onOpen} colorScheme="primary" size="sm">Salvar performance</Button>
            </>
          )}
        >
          <Stat marginY={8}>
            <StatLabel>Índice Atual</StatLabel>
            <StatNumber>{formatYield(currentYield?.index as string)}%</StatNumber>
            <StatHelpText>Adicionado em {currentYield?.createdAt ? format((new Date(currentYield?.createdAt)), 'dd/MM/yyyy \'às\' HH:mm') : '-'}</StatHelpText>
          </Stat>
          <Table variant="simple">
            <Thead>
              <Tr>
                <Th color="primary.600">Índice</Th>
                <Th color="primary.600">Data de inclusão</Th>
              </Tr>
            </Thead>
            <Tbody>
              {yields?.map((item) => (
                <Tr key={item.id}>
                  <Td>{formatYield(item.index as string)}%</Td>
                  <Td>{item?.createdAt ? format((new Date(item?.createdAt)), 'dd/MM/yyyy \'às\' HH:mm') : '-'}</Td>
                </Tr>
              ))}
            </Tbody>
          </Table>
        </Card>
      </Page>
      <YieldModal
        modal={yieldModal}
        reloadYields={reloadYields}
        setReloadYields={setReloadYields}
      />
      <PerformanceModal
        modal={performanceModal}
      />
    </>
  )
}

export default AdminYieldsPage
