import React from 'react'
import { Box, Text, Heading, Button, useDisclosure } from '@chakra-ui/react'
import { Product } from '../../models'
import { toBrl } from '../../utils'
import CompleteProfileModal from '../complete-profile-modal'
import BuyProductModal from '../buy-product-modal'
import { useUser } from '../../contexts'

type Props = {
  product: Product
}

const ProductItem = (props: Props) => {
  const user = useUser()
  const completeProfileModal = useDisclosure()
  const buyProductModal = useDisclosure()

  function handleBuy () {
    if (!user.user?.referenceId) {
      completeProfileModal.onOpen()
    } else {
      buyProductModal.onOpen()
    }
  }

  function onProfileCompleted () {
    completeProfileModal.onClose()
    buyProductModal.onOpen()
  }

  return props.product
    ? (
        <>
          <Box display="flex" flexWrap={{ base: 'wrap', md: 'nowrap' }} justifyContent="center" backgroundColor="secondaryBackground" padding={{ base: '10px', md: '0' }} color="white" borderRadius="7px" boxShadow="0px 14px 32px 0px rgb(0 0 0 / 15%)">
            <Box mb="0" minWidth="max-content" textAlign="center" display="block">
              {props.product.cover && props.product.cover.length
                ? <img style={{ maxWidth: '300px' }} src={props.product.cover} />
                : <img style={{ maxWidth: '300px' }} src="/assets/images/logo.svg" />
              }
            </Box>
            <Box display="flex" flexDirection="column" justifyContent="space-between" margin="20px 15px 20px" width="100%">
              <Box lineHeight="1.2">
                <Heading as="h4" fontSize="22px" lineHeight="1.5" mb={5}>
                  {props.product.name}
                </Heading>
                {/* <Text fontSize={14} mb={0.5}>Valor: <span style={{ fontSize: '17px', fontWeight: 'bold' }}>{toBrl(String(props.product.amount))}</span></Text> */}
                <Text fontSize={16}>Vigência: {props.product.termPeriod} dias</Text>
              </Box>
              <Box mt="5" display="flex" alignItems="center">
                <Text fontWeight="bold" width="100%" fontSize={18}>{toBrl(String(props.product.amount))}</Text>
                <Button colorScheme="primary" isFullWidth onClick={handleBuy}>Comprar</Button>
              </Box>
            </Box>
          </Box>
          <CompleteProfileModal modal={completeProfileModal} formCallback={onProfileCompleted} />
          {props.product && (<BuyProductModal modal={buyProductModal} product={props.product} />)}
        </>
      )
    : null
}

export default ProductItem
