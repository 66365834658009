import { Modal, ModalBody, ModalCloseButton, ModalContent, ModalHeader, ModalOverlay, UseDisclosureProps } from '@chakra-ui/react'
import React from 'react'
import { useUser } from '../../contexts'
import ProfileForm from '../../pages/Profile/components/form'

type Props = {
  modal: UseDisclosureProps
  title?: string
  formCallback?: () => void
}

const CompleteProfileModal = ({ modal, title, formCallback }: Props) => {
  const user = useUser()

  return (
    <Modal size="xl" isOpen={modal.isOpen || false} onClose={modal.onClose || (() => ({}))} closeOnOverlayClick={false} blockScrollOnMount={true}>
      <ModalOverlay />
      <ModalContent color="white" backgroundColor="secondaryBackground">
        <ModalHeader>{title || 'Complete seu cadastro'}</ModalHeader>
        <ModalCloseButton />
        <ModalBody mb={3}>
          {user?.user && (<ProfileForm user={user.user} setUser={user.setUser} formCallback={formCallback} />)}
        </ModalBody>
      </ModalContent>
    </Modal>
  )
}

export default CompleteProfileModal
