/* eslint-disable camelcase */
// eslint-disable-next-line @typescript-eslint/triple-slash-reference
/// <reference path='../../../@types/index.d.ts' />

import React, { ChangeEvent, useEffect, useState } from 'react'
import { Alert, AlertDescription, Button, FormControl, FormErrorMessage, FormHelperText, FormLabel, Input, Modal, ModalBody, ModalCloseButton, ModalContent, ModalFooter, ModalHeader, ModalOverlay, Select, Text, UseDisclosureProps, useToast } from '@chakra-ui/react'
import { Field, Form, Formik } from 'formik'
import { createWithdrawals, getBankAccounts, getWallet } from '../../../api'
import { BankAccount } from '../../../models'
import { toBrl } from '../../../utils'
import IntlCurrencyInput from 'react-intl-currency-input'
import { WITHDRAWN_MINIMUM_AMOUNT, WITHDRAWN_TAX } from '../../../config'

type Props = {
  modal: UseDisclosureProps
  reloadWithdrawals?: boolean
  setReloadWithdrawals?: (reload: boolean) => void
}

const WithdrawnModal = ({ modal, reloadWithdrawals, setReloadWithdrawals }: Props) => {
  const toast = useToast()
  const [bankAccounts, setBankAccounts] = useState<BankAccount[]>()
  const [currentBalance, setCurrentBalance] = useState<number>()

  const currencyConfig = {
    locale: 'pt-BR',
    formats: {
      number: {
        BRL: {
          style: 'currency',
          currency: 'BRL',
          minimumFractionDigits: 2,
          maximumFractionDigits: 2
        }
      }
    }
  }

  useEffect(() => {
    getBankAccounts().then(({ data }) => {
      setBankAccounts(parseBankAccounts(data))
    }).catch(() => {
      toast({
        title: 'Erro',
        description: 'Houve um erro ao listar as suas contas bancárias, tente novamente.',
        status: 'error',
        position: 'bottom-left',
        isClosable: true
      })
    })
  }, [])

  useEffect(() => {
    getWallet().then(({ data }) => {
      let balanceAmount = data.balance as number
      balanceAmount = balanceAmount > WITHDRAWN_TAX ? balanceAmount - WITHDRAWN_TAX : 0
      setCurrentBalance(balanceAmount)
    }).catch(() => {
      //
    })
  }, [])

  function parseBankAccounts (data: BankAccount[]) {
    return data.map((bankAccount) => {
      const pixKeyTypes = {
        EMAIL: 'E-mail',
        PHONE: 'Telefone',
        TAX_NUMBER: 'CPF/CNPJ',
        RANDOM: 'Aleatória'
      }

      return {
        ...bankAccount,
        pixKeyType: pixKeyTypes[bankAccount.pixKeyType] || bankAccount.pixKeyType
      } as BankAccount
    })
  }

  return (
    <Modal size="xl" isOpen={modal.isOpen || false} onClose={modal.onClose || (() => ({}))} closeOnOverlayClick={false} blockScrollOnMount={true}>
      <ModalOverlay />
      <ModalContent color="white" backgroundColor="secondaryBackground">
        <ModalHeader>Solicitar novo saque</ModalHeader>
        <ModalCloseButton />
        <Formik
          initialValues={{
            bank_account_id: '',
            amount: 0
          }}
          validate={(values) => {
            const errors: {
              bank_account_id?: string
              amount?: string
            } = {}

            if (!values.bank_account_id) {
              errors.bank_account_id = 'Selecione a conta bancária'
            }

            if (!values.amount) {
              errors.amount = 'Informe o valor do saque'
            }

            if (Number(values.amount) < WITHDRAWN_MINIMUM_AMOUNT) {
              errors.amount = 'O valor precisa ser maior que o valor mínimo de saque'
            }

            return errors
          }}
          onSubmit={async (values, { setSubmitting }) => {
            setSubmitting(true)
            const dayNumber = new Date().getDate()

            if (dayNumber > 10) {
              if (!confirm('O seu pedido de saque será processado somente no próximo mês entre os dias 1 e 10, você tem certeza que deseja continuar?')) {
                setSubmitting(false)
                return
              }
            }

            try {
              await createWithdrawals(values)
              setSubmitting(false)
              toast({
                title: 'Tudo certo!',
                description: 'A solicitação de saque foi realizada com sucesso!',
                status: 'success',
                position: 'top',
                isClosable: true
              })

              modal?.onClose?.()

              if (setReloadWithdrawals) {
                setReloadWithdrawals(!reloadWithdrawals)
              }
            } catch (error) {
              setSubmitting(false)
              let title = 'Erro'
              let message = 'Houve um erro ao solicitar o saque, tente novamente.'

              if (error?.response) {
                if (error.response?.status === 422) {
                  title = 'Informações inválidas'
                  message = 'Preencha os campos corretamente e tente novamente.'
                }

                if (error.response?.data?.error === 'E_WITHDRAWN_INSUFFICIENT_BALANCE_ERROR') {
                  title = 'Saldo insuficiente'
                  message = 'Você não possui saldo suficiente para solicitar um saque com este valor.'
                }

                if (error.response?.data?.error === 'E_WITHDRAWN_ALREADY_PENDING_ERROR') {
                  title = 'Saque já solicitado'
                  message = 'Não é possível continuar porque você já possui uma solicitação de saque em andamento.'
                }

                if (error.response?.data?.error === 'E_WITHDRAWN_INSUFFICIENT_MINIMUM_AMOUNT') {
                  title = 'Valor inferior ao valor mínimo'
                  message = 'O valor do saque precisa ser maior que o valor mínimo.'
                }
              }

              toast({
                title,
                description: message,
                status: 'error',
                position: 'top',
                isClosable: true
              })
            }
          }}
        >
          {(props: any) => (
            <Form>
              <ModalBody>
                <Alert status="info" mb={5}>
                  <AlertDescription color="#000" fontSize={16} textAlign="center">As solicitações de saques são processadas entre os dias <strong>1 e 10</strong> de todo mês.</AlertDescription>
                </Alert>
                <Field name="bank_account_id">
                  {({ field, form }: any) => (
                    <FormControl isRequired isInvalid={form.errors.bank_account_id && form.touched.bank_account_id} mb={5}>
                      <FormLabel htmlFor="bank_account_id">Conta bancária</FormLabel>
                      <Select {...field} id="bank_account_id">
                        <option value="">Selecione</option>
                        {bankAccounts?.map(bankAccount => (
                          <option key={bankAccount.id} value={bankAccount.id}>
                            {bankAccount.accountType === 'PIX'
                              ? `${bankAccount.pixKeyType} - ${bankAccount.pixKeyValue}`
                              : `${bankAccount.bankName} - Ag: ${bankAccount.agencyCode} - Conta: ${bankAccount.accountNumber} - Tipo: ${bankAccount.accountType}`
                            }
                          </option>
                        ))}
                      </Select>
                      <FormErrorMessage>{form.errors.bank_account_id}</FormErrorMessage>
                    </FormControl>
                  )}
                </Field>
                <Field name="amount">
                  {({ field, form }: any) => (
                    <FormControl isRequired isInvalid={form.errors.amount && form.touched.amount} mb={5}>
                      <FormLabel htmlFor="amount">Valor</FormLabel>
                      <Input {...field} onChange={(event: ChangeEvent, value: number) => props.setFieldValue('amount', value)} type="tel" as={IntlCurrencyInput} currency="BRL" config={currencyConfig} id="amount" placeholder="Valor a ser resgatado" />
                      <FormHelperText>
                        *Saldo disponível para saque: {currentBalance !== undefined ? toBrl(String(currentBalance)) : '-'}
                      </FormHelperText>
                      <FormErrorMessage>{form.errors.amount}</FormErrorMessage>
                    </FormControl>
                  )}
                </Field>
                <Text fontSize={12}>
                  *Taxa de saque: <strong>R$ {WITHDRAWN_TAX.toFixed(2).replace('.', ',')}</strong>
                </Text>
                <Text fontSize={12}>
                  **Saque mínimo: <strong>R$ {WITHDRAWN_MINIMUM_AMOUNT.toFixed(2).replace('.', ',')}</strong>
                </Text>
              </ModalBody>
              <ModalFooter>
                <Button variant="ghost" onClick={modal.onClose}>Cancelar</Button>
                <Button type="submit" colorScheme="primary" ml={3} disabled={props.isSubmitting}>Solicitar saque</Button>
              </ModalFooter>
            </Form>
          )}
        </Formik>
      </ModalContent>
    </Modal>
  )
}

export default WithdrawnModal
