import { Button } from '@chakra-ui/button'
import { useDisclosure } from '@chakra-ui/hooks'
import { Box, Text } from '@chakra-ui/layout'
import { Modal, ModalBody, ModalCloseButton, ModalContent, ModalHeader, ModalOverlay } from '@chakra-ui/modal'
import { addYears } from 'date-fns'
import format from 'date-fns/format'
import React, { useEffect, useState } from 'react'
import { useCookies } from 'react-cookie'
import { fetchArticle } from '../../api'
import { useOverlay } from '../../contexts'

const NewsModal = () => {
  const { isOpen, onClose, onOpen } = useDisclosure()
  const [cookies, setCookie] = useCookies()
  const [text, setText] = useState<string>()
  const [lastUpdatedAt, setLastUpdatedAt] = useState<string>()
  const { setBlur } = useOverlay()

  useEffect(() => {
    const isDeny = cookies?.hide_announcements

    if (!isDeny) {
      fetchArticle().then(({ data }) => {
        setText(data.text)
        setLastUpdatedAt(data.lastUpdateAt)
        setBlur('blur(6px)')
        onOpen()
      }).catch(() => ({}))
    }
  }, [cookies])

  function handleAlwaysHide () {
    const expires = addYears((new Date()), 1)
    setCookie('hide_announcements', 1, { expires })
    handleClose()
  }

  function handleClose () {
    onClose()
    setBlur('blur(0px)')
  }

  return (
    <>
      {text && (
        <Modal isOpen={isOpen} onClose={() => handleClose()} size="xl">
          <ModalOverlay backgroundColor='#191F2D' opacity='0.5 !important' />
          <ModalContent color="white" backgroundColor="secondaryBackground">
            <ModalHeader>Novidades</ModalHeader>
            <ModalCloseButton />
            <ModalBody pb={6}>
              <Box py={2} overflowX="auto" fontSize={16} dangerouslySetInnerHTML={{ __html: text }}></Box>
              <Button variant="link" mt={4} size="sm" color="gray.400" onClick={handleAlwaysHide}>Não mostrar novamente</Button>
            </ModalBody>
          </ModalContent>
        </Modal>
      )}
    </>
  )
}

export default NewsModal
