import React from 'react'
import { Global } from '@emotion/react'

export const Fonts = () => (
  <Global styles={`
    @import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,300;0,400;0,500;0,700;0,900;1,100;1,300&display=swap');

    html, body {
      width: 100%;
      height: 100%;
    }

    body {
      background-color: #191F2E;
      color: #fff;
    }
  `}/>
)
