import React from 'react'
import { useCookies } from 'react-cookie'
import { Route, Redirect } from 'react-router-dom'
import { AUTH_TOKEN_KEY } from '../config'

type Props = {
  component: any
  [key: string]: any
}

export const GuardedRoute = ({ component: Component, ...rest }: Props) => {
  const [cookies] = useCookies([AUTH_TOKEN_KEY])
  const isAuthenticated = cookies?.[AUTH_TOKEN_KEY]
  const qs = (new URLSearchParams(window.location.search)).toString()

  return (
    <Route {...rest} render={(props) => (
      isAuthenticated
        ? <Component {...props} />
        : <Redirect to={{ pathname: '/login', search: qs }} />
    )} />
  )
}
