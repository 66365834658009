import { Badge, Table, Tbody, Td, Th, Thead, Tr } from '@chakra-ui/react'
import { format } from 'date-fns'
import React from 'react'
import { Withdrawn } from '../../../models'
import { toBrl } from '../../../utils'

type Props = {
  withdrawals: Withdrawn[]
}

const WithdrawalsTable = ({ withdrawals }: Props) => {
  function getStatusDescription (status: 'ERROR' | 'PENDING' | 'COMPLETED'): string {
    const statuses = {
      ERROR: 'Erro',
      PENDING: 'Em andamento',
      COMPLETED: 'Concluído'
    }

    return statuses?.[status] || status
  }

  return (
    <Table variant="simple">
      <Thead>
        <Tr>
          <Th color="primary.600">Valor</Th>
          <Th color="primary.600">Solicitado em</Th>
          <Th color="primary.600">Status</Th>
        </Tr>
      </Thead>
      <Tbody>
        {withdrawals?.map((withdrawn) => (
          <Tr key={withdrawn.id}>
            <Td>{toBrl(String(withdrawn.amount))}</Td>
            <Td>{withdrawn.createdAt ? format((new Date(withdrawn.createdAt)), 'dd/MM/yyyy \'às\' HH:mm') : ''}</Td>
            <Td>
              <Badge colorScheme={withdrawn.status === 'COMPLETED' ? 'green' : (withdrawn.status === 'ERROR' ? 'red' : 'gray')}>
                {getStatusDescription(withdrawn.status)}
                {withdrawn?.status === 'COMPLETED' && (withdrawn.withdrawnAt ? ` em ${format((new Date(withdrawn.withdrawnAt)), 'dd/MM/yyyy \'às\' HH:mm')}` : '')}
                {withdrawn?.status === 'ERROR' && withdrawn.errorMessage ? `: ${withdrawn.errorMessage}` : ''}
              </Badge>
            </Td>
          </Tr>
        ))}
      </Tbody>
    </Table>
  )
}

export default WithdrawalsTable
