import React from 'react'
import { Button, FormControl, FormErrorMessage, FormLabel, Modal, ModalBody, ModalCloseButton, ModalContent, ModalFooter, ModalHeader, ModalOverlay, Textarea, UseDisclosureProps, useToast } from '@chakra-ui/react'
import { Field, Form, Formik } from 'formik'
import { saveArticle } from '../../../../api'
import SunEditor, { buttonList } from 'suneditor-react'
import 'suneditor/dist/css/suneditor.min.css'

type Props = {
  modal: UseDisclosureProps
  text?: string
  reloadArticles?: boolean
  setReloadArticles?: (reload: boolean) => void
}

const AdminArticlesModal = ({ modal, text, reloadArticles, setReloadArticles }: Props) => {
  const toast = useToast()

  return (
    <Modal size="xl" isOpen={modal.isOpen || false} onClose={modal.onClose || (() => ({}))} closeOnOverlayClick={false} blockScrollOnMount={true}>
      <ModalOverlay />
      <ModalContent color="white" backgroundColor="secondaryBackground">
        <ModalHeader>Atualizar novidades</ModalHeader>
        <ModalCloseButton />
        <Formik
          initialValues={{ text }}
          validate={(values) => {
            const errors: { text?: string } = {}

            if (!values.text) {
              errors.text = 'Informe o texto'
            }

            return errors
          }}
          onSubmit={async (values, { setSubmitting }) => {
            setSubmitting(true)

            try {
              await saveArticle(values)
              setSubmitting(false)
              toast({
                title: 'Tudo certo!',
                description: 'O texto de novidades foi atualizado com sucesso!',
                status: 'success',
                position: 'top',
                isClosable: true
              })

              modal?.onClose?.()

              if (setReloadArticles) {
                setReloadArticles(!reloadArticles)
              }
            } catch (error) {
              setSubmitting(false)
              let title = 'Erro'
              let message = 'Houve um erro ao atualizar o texto das novidades, tente novamente.'

              if (error?.response) {
                if (error.response?.status === 422) {
                  title = 'Informações inválidas'
                  message = 'Preencha os campos corretamente e tente novamente.'
                }
              }

              toast({
                title,
                description: message,
                status: 'error',
                position: 'top',
                isClosable: true
              })
            }
          }}
        >
          {(props: any) => (
            <Form>
              <ModalBody>
                <Field name="text">
                  {({ field, form }: any) => (
                    <FormControl isRequired isInvalid={form.errors.text && form.touched.text} mb={5}>
                      <FormLabel htmlFor="text">Texto</FormLabel>
                      <SunEditor
                        {...field}
                        lang="pt_br"
                        name="text"
                        id="text"
                        defaultValue={text || ''}
                        height={400}
                        setOptions={{
                          buttonList: [
                            ['font', 'fontSize', 'formatBlock'],
                            ['bold', 'underline', 'italic'],
                            ['fontColor', 'hiliteColor', 'textStyle'],
                            ['removeFormat'],
                            ['align', 'list'],
                            ['link', 'image', 'video'],
                            ['showBlocks', 'codeView']
                          ]
                        }}
                        onChange={(text) => props.setFieldValue('text', text)}
                      />
                      <FormErrorMessage>{form.errors.text}</FormErrorMessage>
                    </FormControl>
                  )}
                </Field>
              </ModalBody>
              <ModalFooter>
                <Button variant="ghost" onClick={modal.onClose}>Cancelar</Button>
                <Button type="submit" colorScheme="primary" ml={3} disabled={props.isSubmitting}>Atualizar</Button>
              </ModalFooter>
            </Form>
          )}
        </Formik>
      </ModalContent>
    </Modal>
  )
}

export default AdminArticlesModal
