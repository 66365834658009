import { Icon, Box, Button, Checkbox, Divider, Heading, HStack, Modal, ModalBody, ModalCloseButton, ModalContent, ModalFooter, ModalHeader, ModalOverlay, Spinner, Text, UseDisclosureProps, useToast, FormControl, FormLabel, Input } from '@chakra-ui/react'
import React, { useEffect, useState } from 'react'
import { createOrder, getPaymentMethodCryptoPricing, updateOrderCryptoHash } from '../../api'
import { usePaymentMethods, useTerms, useWallet } from '../../contexts'
import { PaymentMethod, PaymentMethodType, Product } from '../../models'
import { toBrl } from '../../utils'
import { FaBtc, FaWallet } from 'react-icons/fa'

type Props = {
  modal: UseDisclosureProps
  product: Product
}

const BuyProductModal = ({ modal, product }: Props) => {
  const toast = useToast()
  const { terms } = useTerms()
  const [showTerms, setShowTerms] = useState<boolean>(false)
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [termsAccepted, setTermsAccepted] = useState<boolean>(false)
  const [useBalance, setUseBalance] = useState<boolean>(false)
  const [isRedirecting, setIsRedirecting] = useState<boolean>(false)
  const [paymentUrl, setPaymentUrl] = useState<string>()
  const [currentPaymentMethod, setCurrentPaymentMethod] = useState<PaymentMethod | null>()
  const [cryptoPrice, setCryptoPrice] = useState<number>()
  const [cryptoHash, setCryptoHash] = useState<string>()
  const [orderId, setOrderId] = useState<string>()
  const { wallet } = useWallet()
  const { paymentMethods } = usePaymentMethods()

  useEffect(() => {
    pickCurrentPaymentMethod()
  }, [])

  useEffect(() => {
    pickCurrentPaymentMethod()
  }, [paymentMethods])

  useEffect(() => {
    if (currentPaymentMethod?.customFields?.cryptoCode) {
      getPaymentMethodCryptoPricing(currentPaymentMethod?.id as number, product.id).then(({ data }) => {
        setCryptoPrice(Number(data.productPricing))
      }).catch(() => {
        toast({
          title: 'Ops!',
          description: 'Houve um erro ao obter os dados da criptomoeda, tente novamente.',
          position: 'top',
          isClosable: true,
          status: 'error'
        })

        onClose()
      })
    }
  }, [currentPaymentMethod])

  function pickCurrentPaymentMethod () {
    if (!currentPaymentMethod && paymentMethods && paymentMethods?.length > 0) {
      const [paymentMethod] = paymentMethods
      setCurrentPaymentMethod(paymentMethod)
    }
  }

  function handleToggleTerms () {
    setShowTerms(!showTerms)
  }

  async function handleBuy () {
    if (!currentPaymentMethod) {
      toast({
        title: 'Ops!',
        description: 'Você precisa selecionar um método de pagamento para continuar.',
        status: 'error',
        isClosable: true,
        position: 'top'
      })

      return
    }

    if (!termsAccepted) {
      toast({
        title: 'Ops!',
        description: 'Você precisa aceitar os termos de compra para continuar.',
        status: 'error',
        isClosable: true,
        position: 'top'
      })

      return
    }

    setIsLoading(true)

    try {
      const { data } = await createOrder({
        product_id: product.id,
        term_id: terms?.id,
        use_balance: useBalance,
        payment_method_id: currentPaymentMethod.id as number
      })

      if (data?.meta?.alreadyPaid) {
        toast({
          title: 'Compra realizada!',
          description: 'Sua compra foi realizada com sucesso e seu pagamento foi confirmado! Em instantes o valor do pacote comprado será compensado em sua carteira.',
          status: 'success',
          isClosable: true,
          duration: 15000,
          position: 'top'
        })
        setTimeout(() => { window.location.reload() }, 1500)
        return
      }

      setOrderId(data.id)
      setIsLoading(false)
      setIsRedirecting(true)

      if (data?.meta?.url) {
        setPaymentUrl(data?.meta?.url)

        toast({
          title: 'Compra realizada!',
          description: 'Sua compra foi realizada com sucesso! Você será redirecionado para pagamento em instantes.',
          status: 'success',
          isClosable: true,
          duration: 15000,
          position: 'top'
        })

        setTimeout(() => { handleRedirect(data.meta?.url) }, 200)
      }
    } catch (error) {
      console.error(error)
      setIsLoading(false)

      let message = 'Houve um erro ao processar a sua compra, tente novamente mais tarde.'

      if (error?.response?.status === 400) {
        if (error?.response.data?.code === 'E_USER_MISSING_REFERENCE') {
          message = 'Por favor, complete os dados do seu perfil para continuar com a compra do pacote.'
        }

        if (error?.response.data?.code === 'E_INVALID_SUBSCRIPTION') {
          message = 'Você precisa estar com assinatura em dia para comprar um pacote de investimento.'
        }
      }

      if (error?.response?.status === 422) {
        message = 'Dados inválidos, reinicie a página e tente novamente.'
      }

      toast({
        title: 'Ops!',
        description: message,
        status: 'error',
        isClosable: true,
        position: 'top'
      })
    }
  }

  function handleRedirect (url: string) {
    window.location.href = url
  }

  function onClose () {
    setShowTerms(false)
    setIsLoading(false)
    setIsRedirecting(false)
    setTermsAccepted(false)
    setPaymentUrl('')
    setCurrentPaymentMethod(null)
    setCryptoHash('')
    setOrderId('')
    modal?.onClose?.()
  }

  function handleSelectPaymentMethod (paymentMethod: PaymentMethod) {
    setCurrentPaymentMethod(paymentMethod)
  }

  function getIconByPaymentMethodType (type: PaymentMethodType) {
    const icons = {
      [PaymentMethodType.CRYPTO_BITCOIN]: FaBtc,
      [PaymentMethodType.MAXIVEL_PAGSEGURO]: FaWallet
    }

    if (!icons?.[type]) {
      return
    }

    return <Icon as={icons[type]} w={4} h={4} mr={2} />
  }

  async function handleSaveCryptoTransaction () {
    if (!cryptoHash || cryptoHash.length === 0) {
      toast({
        title: 'Ops!',
        description: 'Preencha o hash da transação para continuar.',
        status: 'error',
        position: 'top',
        isClosable: true
      })

      return
    }

    setIsLoading(true)

    try {
      await updateOrderCryptoHash(orderId as string, cryptoHash)
      toast({
        title: 'Tudo certo!',
        description: 'Sua compra foi realizada com sucesso! Seu pagamento irá para análise e será confirmado em até 24 horas úteis.',
        status: 'success',
        position: 'top',
        isClosable: true
      })

      onClose()
    } catch (error) {
      toast({
        title: 'Ops!',
        description: 'Houve um erro ao salvar a hash da transação, tente novamente.',
        status: 'error',
        position: 'top',
        isClosable: true
      })

      setIsLoading(false)
    }
  }

  return (
    <Modal size="xl" isOpen={modal.isOpen || false} onClose={onClose} closeOnOverlayClick={false} blockScrollOnMount={true}>
      <ModalOverlay />
      <ModalContent color="white" backgroundColor="secondaryBackground">
        <ModalHeader>Comprar</ModalHeader>
        {!isRedirecting && <ModalCloseButton />}
        {!isRedirecting
          ? (
          <ModalBody>
            <Box>
              <Heading as="h4" fontSize={12} textTransform="uppercase" fontWeight={600} mb={1}>
                Detalhes
              </Heading>
              <Heading as="h4" fontSize={18}>{product.name}</Heading>
              <Text fontSize={18}>{product.description}</Text>
              <Text fontSize={14} color="green.500">{toBrl(String(product.amount))}/válido por {product.termPeriod} dias/carência de {product.gracePeriod} dia(s)</Text>
            </Box>
            <Box mt={8}>
              <Heading as="h4" fontSize={12} textTransform="uppercase" fontWeight={600} mb={1}>
                Método de pagamento
              </Heading>
              <HStack gap={5}>
                {paymentMethods?.map((paymentMethod) => (
                  <Box
                    key={paymentMethod?.id}
                    textAlign="center"
                    padding={5}
                    borderWidth={2}
                    cursor="pointer"
                    width="100%"
                    borderRadius={5}
                    borderColor={currentPaymentMethod?.id === paymentMethod.id ? 'primary.500' : 'grey.900'}
                    color={currentPaymentMethod?.id === paymentMethod.id ? 'primary.500' : 'grey.900'}
                    fontWeight={500}
                    fontSize={14}
                    onClick={() => handleSelectPaymentMethod(paymentMethod)}
                    display="flex"
                    alignItems="center"
                    justifyContent="center"
                  >
                    {getIconByPaymentMethodType(paymentMethod?.type)}
                    {paymentMethod?.name}
                  </Box>
                ))}
              </HStack>
              {currentPaymentMethod?.customFields?.cryptoCode === 'BTC' && (
                <Box mt={2}>
                  <Text textAlign="center" fontSize={14} fontWeight={500}>
                    Valor em {currentPaymentMethod?.customFields?.cryptoCode}: {cryptoPrice || '...'}
                  </Text>
                </Box>
              )}
            </Box>
            <Checkbox alignItems="center" spacing={5} mt={8} isChecked={termsAccepted} onChange={(e) => setTermsAccepted(e.target.checked)}>
              Ao continuar com a compra deste pacote, eu concordo que li e aceito os <Button onClick={handleToggleTerms} variant="link" colorScheme="primary">termos de compra</Button>.
            </Checkbox>
            {wallet?.balance && wallet.balance > 0 && (
              <>
                <Divider mt={3} />
                <Checkbox size="sm" alignItems="center" spacing={5} mt={3} isChecked={useBalance} onChange={(e) => setUseBalance(e.target.checked)}>
                Quero utilizar meu lucro disponível de <strong style={{ color: '#D0AB3C' }}>{toBrl(wallet.balance as string)}</strong> para pagar.
                </Checkbox>
              </>
            )}
            {showTerms && (
              <Box p={2} borderRadius={8} backgroundColor="secondaryBackground" maxH={150} overflowY="auto" mt={6}>
                <Heading as="h6" fontSize={12} mb={2}>{terms?.title}</Heading>
                <Text fontSize={11}>{terms?.terms}</Text>
              </Box>
            )}
          </ModalBody>
            )
          : (
            <div>
              {currentPaymentMethod?.customFields?.walletAddress
                ? (
                    <>
                      <ModalBody mb={8}>
                        <Box>
                          <Text textAlign="center" fontWeight={600} mb={2}>Dados para pagamento</Text>
                          <Text textAlign="center"><strong>Carteira:</strong> {currentPaymentMethod?.customFields?.walletAddress || '-'}</Text>
                          <Text textAlign="center"><strong>Valor:</strong> {currentPaymentMethod?.customFields?.cryptoCode} {cryptoPrice}</Text>
                          <FormControl isRequired mb={2} mt={5}>
                            <FormLabel htmlFor="code">Código da transação (hash)</FormLabel>
                            <Input type="text" id="code" placeholder="Código da transação (hash)" onChange={(event) => setCryptoHash(event.target.value)} />
                          </FormControl>
                          <Text fontSize={11}>
                            O prazo para pagamento é de <strong>3 horas</strong> devido a alta instabilidade da moeda.
                          </Text>
                        </Box>
                      </ModalBody>
                      <ModalFooter>
                        <Button variant="ghost" onClick={onClose}>Continuar depois</Button>
                        <Button type="submit" colorScheme="primary" ml={3} isLoading={isLoading} onClick={handleSaveCryptoTransaction}>
                          Enviar
                        </Button>
                      </ModalFooter>
                    </>
                  )
                : (
                    <ModalBody mb={8}>
                      <Box textAlign="center">
                        <Spinner color="primary.500" size="lg" />
                        <Text>Redirecionando para o pagamento...</Text>
                        <Button variant="link" size="sm" colorScheme="primary" mt={2} onClick={() => handleRedirect(paymentUrl as string)}>
                          Clique aqui caso não seja redirecionado automaticamente
                        </Button>
                      </Box>
                    </ModalBody>
                  )
                }
              </div>
            )}
          {!isRedirecting && (
            <ModalFooter>
              <Button variant="ghost" onClick={onClose}>Cancelar</Button>
              <Button type="submit" colorScheme="primary" ml={3} isLoading={isLoading} onClick={handleBuy}>Comprar</Button>
            </ModalFooter>
          )}
        </ModalContent>
    </Modal>
  )
}

export default BuyProductModal
