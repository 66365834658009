export const states = [
  {
    id: '1',
    uf: 'AC',
    name: 'Acre'
  },
  {
    id: '2',
    uf: 'AL',
    name: 'Alagoas'
  },
  {
    id: '3',
    uf: 'AM',
    name: 'Amazonas'
  },
  {
    id: '4',
    uf: 'AP',
    name: 'Amapá'
  },
  {
    id: '5',
    uf: 'BA',
    name: 'Bahia'
  },
  {
    id: '6',
    uf: 'CE',
    name: 'Ceará'
  },
  {
    id: '7',
    uf: 'DF',
    name: 'Distrito Federal'
  },
  {
    id: '8',
    uf: 'ES',
    name: 'Espírito Santo'
  },
  {
    id: '9',
    uf: 'GO',
    name: 'Goiás'
  },
  {
    id: '10',
    uf: 'MA',
    name: 'Maranhão'
  },
  {
    id: '11',
    uf: 'MG',
    name: 'Minas Gerais'
  },
  {
    id: '12',
    uf: 'MS',
    name: 'Mato Grosso do Sul'
  },
  {
    id: '13',
    uf: 'MT',
    name: 'Mato Grosso'
  },
  {
    id: '14',
    uf: 'PA',
    name: 'Pará'
  },
  {
    id: '15',
    uf: 'PB',
    name: 'Paraíba'
  },
  {
    id: '16',
    uf: 'PE',
    name: 'Pernambuco'
  },
  {
    id: '17',
    uf: 'PI',
    name: 'Piauí'
  },
  {
    id: '18',
    uf: 'PR',
    name: 'Paraná'
  },
  {
    id: '19',
    uf: 'RJ',
    name: 'Rio de Janeiro'
  },
  {
    id: '20',
    uf: 'RN',
    name: 'Rio Grande do Norte'
  },
  {
    id: '21',
    uf: 'RO',
    name: 'Rondônia'
  },
  {
    id: '22',
    uf: 'RR',
    name: 'Roraima'
  },
  {
    id: '23',
    uf: 'RS',
    name: 'Rio Grande do Sul'
  },
  {
    id: '24',
    uf: 'SC',
    name: 'Santa Catarina'
  },
  {
    id: '25',
    uf: 'SE',
    name: 'Sergipe'
  },
  {
    id: '26',
    uf: 'SP',
    name: 'São Paulo'
  },
  {
    id: '27',
    uf: 'TO',
    name: 'Tocantins'
  }
]
