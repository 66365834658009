import { createContext, useContext } from 'react'
import { GetActiveTermResponse } from '../interfaces'

type TermsContextType = {
  terms: GetActiveTermResponse | undefined
  setTerms: (terms: GetActiveTermResponse) => void
}

const initialValue = {
  terms: undefined,
  setTerms: () => ({})
}

export const TermsContext = createContext<TermsContextType>(initialValue)
export const useTerms = () => useContext(TermsContext)
