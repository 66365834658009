import React, { useEffect } from 'react'
import { Grid, useToast } from '@chakra-ui/react'
import { getActiveTerm, getPaymentMethods, getProducts } from '../../api'
import { usePaymentMethods, useProducts, useTerms } from '../../contexts'
import ProductItem from '../product-item'

const ProductsList = () => {
  const toast = useToast()
  const { terms, setTerms } = useTerms()
  const { products, setProducts } = useProducts()
  const { paymentMethods, setPaymentMethods } = usePaymentMethods()

  useEffect(() => {
    if (!products) {
      getProducts({
        order_column: 'products.amount',
        order_type: 'asc'
      }).then(({ data }) => {
        setProducts(data)
      }).catch(() => {
        toast({
          title: 'Erro',
          description: 'Houve um erro ao obter os produtos, tente novamente.',
          status: 'error',
          position: 'bottom-left',
          isClosable: true
        })
      })
    }
  }, [])

  useEffect(() => {
    if (!terms) {
      getActiveTerm().then(({ data }) => {
        setTerms(data)
      }).catch(() => {
        toast({
          title: 'Erro',
          description: 'Houve um erro ao carregar os termos de compra, tente novamente',
          status: 'error',
          position: 'top',
          isClosable: true
        })
      })
    }
  }, [])

  useEffect(() => {
    if (!paymentMethods) {
      getPaymentMethods().then((response) => {
        setPaymentMethods(response.data)
      }).catch(() => {
        toast({
          title: 'Erro',
          description: 'Houve um erro ao obter os métodos de pagamento, tente novamente.',
          status: 'error',
          position: 'bottom-left',
          isClosable: true
        })
      })
    }
  }, [])

  return (
    <Grid gridTemplateColumns={{ lg: '1fr 1fr', md: '1fr', sm: '1fr', base: '1fr' }} justifyContent={{ sm: 'flex-start', lg: 'center' }} gap={5}>
      {products?.map((product) => <ProductItem key={product.id} product={product} />)}
    </Grid>
  )
}

export default ProductsList
