/* eslint-disable camelcase */
import { AlertDialog, AlertDialogBody, AlertDialogContent, AlertDialogFooter, AlertDialogHeader, AlertDialogOverlay, Box, Button, HStack, Icon, Input, Table, Tbody, Td, Th, Thead, Tr, useDisclosure, useToast } from '@chakra-ui/react'
import React, { useEffect, useRef, useState } from 'react'
import Card from '../../../components/card'
import Header from '../../../components/header'
import Page from '../../../components/page'
import { User } from '../../../models'
import { deleteUser, getUsers, loginAsUser } from '../../../api'
import { addDays, format } from 'date-fns'
import AdminEditUserModal from './components/edit-modal'
import { FiChevronLeft, FiChevronRight, FiSearch } from 'react-icons/fi'
import { cookies } from '../../../utils'
import { ACCOUNTS_TOKEN_KEY, AUTH_TOKEN_KEY } from '../../../config'

type UserParams = {
  page: number
  perPage?: number
  order_type?: string
  order_column?: string
  query?: string
}

type PagingMeta = {
  page?: number
  perPage?: number
  total?: number
  totalPages?: number
}

const AdminUsersPage = () => {
  const [currentUser, setCurrentUser] = useState<User | null>()
  const [reloadUsers, setReloadUsers] = useState<boolean>()
  const [users, setUsers] = useState<User[]>()
  const toast = useToast()
  const editModal = useDisclosure()
  const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState<boolean>(false)
  const onDeleteDialogClose = () => setIsDeleteDialogOpen(false)
  const cancelRef = useRef<any>()
  const [params, setParams] = useState<UserParams>({ page: 1, perPage: 20, query: '' })
  const [pagingMeta, setPagingMeta] = useState<PagingMeta>()
  const [query, setQuery] = useState<string>()

  useEffect(() => {
    getUsers(params).then(({ data }) => {
      setUsers(data.data)
      setPagingMeta(data.meta)
    }).catch(() => {
      toast({
        title: 'Erro',
        description: 'Houve um erro ao listar os usuários, tente novamente.',
        position: 'top',
        status: 'error',
        isClosable: true
      })
    })
  }, [reloadUsers])

  function handleEdit (user: User) {
    setCurrentUser(user)
    editModal.onOpen()
  }

  function handleOpenDeleteDialog (user: User | null | undefined) {
    setIsDeleteDialogOpen(true)
    setCurrentUser(user)
  }

  async function handleDelete (user: User | null | undefined) {
    if (user) {
      try {
        await deleteUser(user.id)
        setUsers(users?.filter(el => el.id !== user.id))
        toast({
          title: 'Tudo certo!',
          description: 'O usuário foi excluído com sucesso!',
          status: 'success',
          position: 'top',
          isClosable: true
        })
        setIsDeleteDialogOpen(false)
        setCurrentUser(null)
      } catch (error) {
        setIsDeleteDialogOpen(false)
        setCurrentUser(null)
        toast({
          title: 'Ops!',
          description: 'Houve um erro ao excluir o usuário, tente novamente.',
          status: 'error',
          position: 'top',
          isClosable: true
        })
      }
    }
  }

  async function handleLoginAs (user: User) {
    try {
      const { data } = await loginAsUser(user.id)
      cookies.set(AUTH_TOKEN_KEY, data.access_token, { expires: addDays(new Date(), 1) })
      cookies.remove(ACCOUNTS_TOKEN_KEY)
      window.location.href = '/'
    } catch (error) {
      toast({
        title: 'Erro',
        description: 'Houve um erro ao obter o token de autenticação, tente novamente.',
        position: 'top',
        status: 'error',
        isClosable: true
      })
    }
  }

  function handlePrev () {
    if (pagingMeta?.page && pagingMeta.page > 1) {
      setParams({ page: pagingMeta?.page - 1 })
      setReloadUsers(!reloadUsers)
    }
  }

  function handleNext () {
    if (pagingMeta?.page && pagingMeta?.totalPages && pagingMeta.page < pagingMeta?.totalPages) {
      setParams({ page: pagingMeta?.page + 1 })
      setReloadUsers(!reloadUsers)
    }
  }

  function handleSearch () {
    if (query && query.length) {
      setParams({ ...params, query })
      setReloadUsers(!reloadUsers)
    }
  }

  function handleClearSearch () {
    setQuery('')
    setParams({ ...params, query: '' })
    setReloadUsers(!reloadUsers)
  }

  return (
    <>
      <Header />
      <Page>
        <Card
          title={`Usuários (${pagingMeta?.total || 0})`}
        >
          <Box display="flex" maxW="600px" mb={5}>
            <Input
              type="search"
              placeholder="Pesquisar usuários"
              value={query}
              onChange={(event) => setQuery(event.target.value)}
              onKeyDown={(event) => { if (event.key === 'Enter') { handleSearch() } }}
            />
            <Button
              rightIcon={<Icon as={FiSearch} />}
              colorScheme="primary"
              marginLeft="5px"
              paddingLeft={7}
              paddingRight={7}
              onClick={handleSearch}
            >Pesquisar</Button>
            <Button
              colorScheme="clear"
              marginLeft="5px"
              paddingLeft={5}
              paddingRight={5}
              onClick={handleClearSearch}
            >Limpar</Button>
          </Box>
          <Table>
            <Thead>
              <Tr>
                <Th color="primary.600">Nome</Th>
                <Th color="primary.600">E-mail</Th>
                <Th color="primary.600">ID Max Nível</Th>
                <Th color="primary.600">Acesso</Th>
                <Th color="primary.600">Cadastro em</Th>
                <Th color="primary.600"></Th>
              </Tr>
            </Thead>
            <Tbody>
              {users?.map((user) => (
                <Tr key={user.id}>
                  <Td>{user.name}</Td>
                  <Td>{user.email}</Td>
                  <Td>{user.referenceId || '-'}</Td>
                  <Td>{user.isAdmin ? 'Admin' : 'Usuário'}</Td>
                  <Td>{user.createdAt ? format((new Date(user.createdAt)), 'dd/MM/yyyy \'às\' HH:mm') : ''}</Td>
                  <Td>
                    <Button colorScheme="primary" variant="outline" size="xs" mr={2} onClick={() => handleLoginAs(user)}>
                      Logar
                    </Button>
                    <Button colorScheme="primary" size="xs" mr={2} onClick={() => handleEdit(user)}>
                      Editar
                    </Button>
                    <Button colorScheme="red" size="xs" onClick={() => handleOpenDeleteDialog(user)}>
                      Excluir
                    </Button>
                  </Td>
                </Tr>
              ))}
            </Tbody>
          </Table>

          <Box mt={8}>
            <HStack justifyContent="center" gap={10}>
              <Button colorScheme="primary" variant="ghost" onClick={handlePrev} disabled={params.page === 1} leftIcon={<FiChevronLeft />}>Anterior</Button>
              <Button colorScheme="primary" variant="ghost" onClick={handleNext} disabled={(pagingMeta?.page || 1) >= (pagingMeta?.totalPages || 1)} rightIcon={<FiChevronRight />}>Próximo</Button>
            </HStack>
          </Box>
        </Card>
      </Page>
      {currentUser && (
        <AdminEditUserModal
          modal={editModal}
          user={currentUser}
          reloadUsers={reloadUsers}
          setReloadUsers={setReloadUsers}
        />
      )}
      <AlertDialog
        isOpen={isDeleteDialogOpen}
        leastDestructiveRef={cancelRef}
        onClose={onDeleteDialogClose}
      >
        <AlertDialogOverlay>
          <AlertDialogContent>
            <AlertDialogHeader fontSize="lg" fontWeight="bold">
              Excluir usuário
            </AlertDialogHeader>
            <AlertDialogBody>
              Você tem certeza que deseja excluir este usuário?
            </AlertDialogBody>
            <AlertDialogFooter>
              <Button ref={cancelRef} onClick={onDeleteDialogClose} variant="ghost">
                Cancelar
              </Button>
              <Button colorScheme="red" onClick={() => handleDelete(currentUser)} ml={3}>
                Excluir
              </Button>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>
    </>
  )
}

export default AdminUsersPage
