/* eslint-disable camelcase */
import React from 'react'
import { Button, FormControl, FormErrorMessage, FormLabel, Modal, ModalBody, ModalCloseButton, ModalContent, ModalFooter, ModalHeader, ModalOverlay, Select, Textarea, UseDisclosureProps, useToast } from '@chakra-ui/react'
import { Field, Form, Formik } from 'formik'
import { updateWithdrawn } from '../../../../api'
import { Withdrawn } from '../../../../models'

type Props = {
  modal: UseDisclosureProps
  withdrawn: Withdrawn
  reloadWithdrawals?: boolean
  setReloadWithdrawals?: (reload: boolean) => void
}

const AdminEditWithdrawnModal = ({ modal, withdrawn, reloadWithdrawals, setReloadWithdrawals }: Props) => {
  const toast = useToast()

  return (
    <Modal size="xl" isOpen={modal.isOpen || false} onClose={modal.onClose || (() => ({}))} closeOnOverlayClick={false} blockScrollOnMount={true}>
      <ModalOverlay />
      <ModalContent color="white" backgroundColor="secondaryBackground">
        <ModalHeader>Atualizar status do saque</ModalHeader>
        <ModalCloseButton />
        <Formik
          initialValues={{
            status: '',
            error_message: ''
          }}
          validate={(values) => {
            const errors: {
              status?: string
              error_message?: string
            } = {}

            if (!values.status) {
              errors.status = 'Selecione o status do saque'
            }

            if (values.status === 'ERROR' && !values.error_message) {
              errors.error_message = 'Informe o motivo do erro do saque'
            }

            return errors
          }}
          onSubmit={async (values, { setSubmitting }) => {
            setSubmitting(true)

            try {
              await updateWithdrawn(withdrawn.id, values)
              setSubmitting(false)
              toast({
                title: 'Tudo certo!',
                description: 'O status do saque foi atualizado com sucesso!',
                status: 'success',
                position: 'top',
                isClosable: true
              })

              modal?.onClose?.()

              if (setReloadWithdrawals) {
                setReloadWithdrawals(!reloadWithdrawals)
              }
            } catch (error) {
              setSubmitting(false)
              let title = 'Erro'
              let message = 'Houve um erro ao atualizar o saque, tente novamente.'

              if (error?.response) {
                if (error.response?.status === 422) {
                  title = 'Informações inválidas'
                  message = 'Preencha os campos corretamente e tente novamente.'
                }
              }

              toast({
                title,
                description: message,
                status: 'error',
                position: 'top',
                isClosable: true
              })
            }
          }}
        >
          {(props: any) => (
            <Form>
              <ModalBody>
                <Field name="status">
                  {({ field, form }: any) => (
                    <FormControl isRequired isInvalid={form.errors.status && form.touched.status} mb={5}>
                      <FormLabel htmlFor="status">Status</FormLabel>
                      <Select {...field}>
                        <option value="">Selecione</option>
                        <option value="COMPLETED">Finalizado</option>
                        <option value="ERROR">Erro</option>
                      </Select>
                      <FormErrorMessage>{form.errors.status}</FormErrorMessage>
                    </FormControl>
                  )}
                </Field>
                {props.values.status === 'ERROR' && (
                  <Field name="error_message">
                    {({ field, form }: any) => (
                      <FormControl isRequired isInvalid={form.errors.error_message && form.touched.error_message} mb={5}>
                        <FormLabel htmlFor="error_message">Motivo do erro</FormLabel>
                        <Textarea {...field} id="error_message" placeholder="Informe o motivo do erro" />
                        <FormErrorMessage>{form.errors.error_message}</FormErrorMessage>
                      </FormControl>
                    )}
                  </Field>
                )}
              </ModalBody>
              <ModalFooter>
                <Button variant="ghost" onClick={modal.onClose}>Cancelar</Button>
                <Button type="submit" colorScheme="primary" ml={3} disabled={props.isSubmitting}>Salvar</Button>
              </ModalFooter>
            </Form>
          )}
        </Formik>
      </ModalContent>
    </Modal>
  )
}

export default AdminEditWithdrawnModal
