import React from 'react'
import Chart from 'react-apexcharts'

export interface PerformanceDataChart {
  series: {
    name: string;
    data: number[];
  }[],
  xAxisCategories: string[];
  yAxisMax: number;
}

const PerformanceChart = ({ series, xAxisCategories, yAxisMax }: PerformanceDataChart) => (
  <Chart
    type="line"
    options={{
      chart: {
        zoom: {
          enabled: false
        },
        type: 'line',
        dropShadow: {
          enabled: true,
          color: '#FFF',
          top: 1,
          left: 1,
          blur: 10,
          opacity: 0.2
        },
        toolbar: {
          show: false
        }
      },
      colors: ['#D0AB3C', '#F0710B', '#34c38f'],
      dataLabels: {
        enabled: true
      },
      stroke: {
        curve: 'smooth'
      },
      grid: {
        row: {
          colors: ['#273045'],
          opacity: 1
        }
      },
      markers: {
        size: 1
      },
      xaxis: {
        labels: {
          style: {
            colors: '#FFF'
          }
        },
        categories: xAxisCategories
      },
      yaxis: {
        labels: {
          style: {
            colors: '#FFF'
          }
        },
        min: 0,
        max: yAxisMax
      },
      title: {
        style: {
          color: '#FFF'
        },
        text: '% de Lucro dos Produtos',
        align: 'left'
      },
      legend: {
        labels: {
          colors: '#FFF'
        },
        position: 'top',
        horizontalAlign: 'right'
      },
      tooltip: {
        y: {
          formatter: (name) => `${name} %`
        },
        enabled: true,
        theme: 'dark'
      }
    }}
    series={series}
    height={350}
  />
)

export default PerformanceChart
