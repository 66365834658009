import React, { useState } from 'react'
import {
  Button,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  UseDisclosureProps,
  useToast
} from '@chakra-ui/react'
import { Field, Form, Formik } from 'formik'
import DatePicker from '../../../../components/date-picker'
import { createPerformance } from '../../../../api'

type PerformanceModalProps = {
  modal: UseDisclosureProps
}

const PerformanceModal = ({ modal }: PerformanceModalProps) => {
  const [selectedDate, setSelectedDate] = useState<Date>(new Date())
  const toast = useToast()

  return (
    <Modal size="xl" isOpen={modal.isOpen || false} onClose={modal.onClose || (() => ({}))} closeOnOverlayClick={false} blockScrollOnMount={true}>
      <ModalOverlay />
      <ModalContent color="white" backgroundColor="secondaryBackground">
        <ModalHeader>Salvar performance diária</ModalHeader>
        <ModalCloseButton />
        <Formik
          initialValues={{
            date: selectedDate,
            supplements: 0,
            semiJewels: 0,
            infoProducts: 0
          }}
          validate={(values) => {
            const errors: {
              date?: string;
              infoProducts?: string;
              supplements?: string;
              semiJewels?: string;
            } = {}

            if (!values.date) {
              errors.date = 'Informe o dia'
            }

            if (!values.supplements && values.supplements !== 0) {
              errors.supplements = 'Informe a % de suplementos'
            }

            if (!values.semiJewels && values.semiJewels !== 0) {
              errors.semiJewels = 'Informe a % de Semijoias'
            }

            if (!values.infoProducts && values.infoProducts !== 0) {
              errors.infoProducts = 'Informe a % de Infoprodutos'
            }

            return errors
          }}
          onSubmit={async (values, { setSubmitting }) => {
            setSubmitting(true)

            try {
              const { data } = await createPerformance(values)

              if (data.updated) {
                toast({
                  title: 'Tudo certo!',
                  description: 'A performance foi atualizada!',
                  status: 'success',
                  position: 'top',
                  isClosable: true
                })
              } else {
                toast({
                  title: 'Tudo certo!',
                  description: 'A performance foi criada!',
                  status: 'success',
                  position: 'top',
                  isClosable: true
                })
              }

              setSubmitting(false)
              modal?.onClose?.()
            } catch (error) {
              setSubmitting(false)
              let title = 'Erro'
              let message = 'Houve um erro ao criar a performance, tente novamente.'

              if (error?.response) {
                if (error.response?.status === 400 &&
                  error.response?.data.code === 'E_PERFORMANCE_ALREADY_REGISTERED'
                ) {
                  title = 'Informações inválidas'
                  message = 'Performance já cadastrada'
                }
              }

              toast({
                title,
                description: message,
                status: 'error',
                position: 'top',
                isClosable: true
              })
            }
          }}
        >
          {(props: any) => (
            <Form>
              <ModalBody>
                <Field name="date">
                  {({ form }: any) => (
                    <FormControl isRequired isInvalid={form.errors.date && form.touched.date} mb={5}>
                      <FormLabel htmlFor="date">Data</FormLabel>
                      <DatePicker
                        onChange={(newDate) => {
                          form.values.date = newDate
                          setSelectedDate(newDate as Date)
                        }}
                        value={selectedDate}
                      />
                      <FormErrorMessage>{form.errors.date}</FormErrorMessage>
                    </FormControl>
                  )}
                </Field>
                <Field name="supplements">
                  {({ field, form }: any) => (
                    <FormControl isRequired isInvalid={form.errors.supplements && form.touched.supplements} mb={5}>
                      <FormLabel htmlFor="supplements">% de Suplementos</FormLabel>
                      <Input {...field} type="number" id="supplements" placeholder="Índice" />
                      <FormErrorMessage>{form.errors.supplements}</FormErrorMessage>
                    </FormControl>
                  )}
                </Field>
                <Field name="semiJewels">
                  {({ field, form }: any) => (
                    <FormControl isRequired isInvalid={form.errors.semiJewels && form.touched.semiJewels} mb={5}>
                      <FormLabel htmlFor="semiJewels">% de Semijoias</FormLabel>
                      <Input {...field} type="number" id="semiJewels" placeholder="Índice" />
                      <FormErrorMessage>{form.errors.semiJewels}</FormErrorMessage>
                    </FormControl>
                  )}
                </Field>
                <Field name="infoProducts">
                  {({ field, form }: any) => (
                    <FormControl isRequired isInvalid={form.errors.infoProducts && form.touched.infoProducts} mb={5}>
                      <FormLabel htmlFor="infoProducts">% de Infoprodutos</FormLabel>
                      <Input {...field} type="number" id="infoProducts" placeholder="Índice" />
                      <FormErrorMessage>{form.errors.infoProducts}</FormErrorMessage>
                    </FormControl>
                  )}
                </Field>
              </ModalBody>
              <ModalFooter>
                <Button variant="ghost" onClick={modal.onClose}>Cancelar</Button>
                <Button type="submit" colorScheme="primary" ml={3} disabled={props.isSubmitting}>Salvar performance</Button>
              </ModalFooter>
            </Form>
          )}
        </Formik>
      </ModalContent>
    </Modal>
  )
}

export default PerformanceModal
