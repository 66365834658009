import { Cookies } from 'react-cookie'
import { ACCOUNTS_TOKEN_KEY, AUTH_TOKEN_KEY } from '../config'

export const getAuthToken = (): string => {
  const cookies = new Cookies()
  return cookies.get(AUTH_TOKEN_KEY) as string
}

export const getAccountsAuthToken = (): string => {
  const cookies = new Cookies()
  return cookies.get(ACCOUNTS_TOKEN_KEY) as string
}
