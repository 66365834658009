import { Box, Text } from '@chakra-ui/react'
import React from 'react'

const Footer = () => {
  const year = (new Date()).getFullYear()

  return (
    <Box mt={14}>
      <Text fontSize={13} fontWeight={500} opacity={1} textAlign="center" color="white">
        Next Go Invest &copy; {year} - Todos os direitos reservados
      </Text>
    </Box>
  )
}

export default Footer
