import React, { useEffect, useState } from 'react'
import { Text, Icon, Button, Box, useToast, Flex } from '@chakra-ui/react'
import { FiArrowUp } from 'react-icons/fi'
import moment from 'moment'
import DateRangePicker from '../../components/date-range-picker'
import Header from '../../components/header'
import Page from '../../components/page'
import WalletBalance from './components/wallet-balance'
import PerformanceChart, { PerformanceDataChart } from './components/performance-chart'
import { Link } from 'react-router-dom'
import TransactionItem from '../../components/transaction-item'
import { Wallet, Transaction as ITransaction } from '../../models'
import { toBrl, getDaysInRange } from '../../utils'
import { getWallet, getActiveYield, getTransactions, getPerformancesByRange } from '../../api'
import Card from '../../components/card'
import ProductsList from '../../components/products-list'
import NewsModal from '../../components/news-modal'
import { useWallet, useOverlay } from '../../contexts'

const WalletPage = () => {
  const { wallet, setWallet } = useWallet()
  const [activeYield, setActiveYield] = useState<string>()
  const [earningsPercentage, setEarningsPercentage] = useState<string>()
  const [transactions, setTransactions] = useState<ITransaction[]>()
  const [performanceDataChart, setPerformanceDataChart] = useState<PerformanceDataChart>()
  const [selectedRangeDays, setSelectedRangeDays] = useState<[Date, Date]>(
    [moment().subtract(7, 'days').toDate(), moment().toDate()]
  )
  const toast = useToast()
  const { blur } = useOverlay()

  useEffect(() => {
    getWallet().then(({ data }) => {
      setWallet(data)
      parseWallet(data)
    }).catch(() => {
      toast({
        title: 'Erro',
        description: 'Houve um erro ao obter os dados da sua carteira, tente novamente.',
        status: 'error',
        position: 'bottom-left',
        isClosable: true
      })
    })
  }, [])

  useEffect(() => {
    getActiveYield().then(({ data }) => {
      setActiveYield(Number(data.index).toFixed(2).replace('.', ','))
    }).catch(() => {
      toast({
        title: 'Erro',
        description: 'Houve um erro ao obter o índice de rendimento, tente novamente.',
        status: 'error',
        position: 'bottom-left',
        isClosable: true
      })
    })
  }, [])

  useEffect(() => {
    getTransactions().then(({ data: { data } }) => {
      setTransactions(data)
    }).catch(() => {
      toast({
        title: 'Erro',
        description: 'Houve um erro ao obter o histórico de transações, tente novamente.',
        status: 'error',
        position: 'bottom-left',
        isClosable: true
      })
    })
  }, [])

  useEffect(() => {
    const chartDateRange = {
      fromDate: moment(selectedRangeDays[0]).startOf('day').toDate(),
      toDate: moment(selectedRangeDays[1]).endOf('day').toDate()
    }

    getPerformancesByRange(chartDateRange)
      .then(({ data }) => {
        const days = getDaysInRange(chartDateRange)
        const chartLabels = {
          supplements: {
            name: 'Suplementos',
            data: [] as number[]
          },
          semiJewels: {
            name: 'Semijoias',
            data: [] as number[]
          },
          infoProducts: {
            name: 'Infoprodutos',
            data: [] as number[]
          }
        }

        days.forEach(day => {
          const performanceOfDay = data.find(
            performance => moment(performance.date).format('DD/MM/YYYY') === day
          )

          Object.keys(chartLabels).forEach(key => {
            chartLabels[key as keyof typeof chartLabels].data.push(performanceOfDay
              ? performanceOfDay[key as keyof typeof chartLabels]
              : 0
            )
          })
        })

        const performanceDataChart = {
          series: Object.keys(chartLabels).map(key => ({ ...chartLabels[key as keyof typeof chartLabels] })),
          xAxisCategories: days,
          yAxisMax: activeYield ? parseInt(activeYield, 10) : 100
        }
        setPerformanceDataChart(performanceDataChart)
      })
      .catch(() => {
        toast({
          title: 'Erro',
          description: 'Houve um erro ao obter os dados de performance, tente novamente',
          status: 'error',
          position: 'bottom-left',
          isClosable: true
        })
      })
  }, [activeYield, selectedRangeDays])

  function parseWallet (data: Wallet): void {
    const percentage = getEarningsPercentage(Number(data?.totalInvestments), Number(data?.earnings)).toFixed(2)
    setEarningsPercentage(percentage.replace('.', ','))
  }

  function getEarningsPercentage (totalInvestments: number, earnings: number): number {
    return ((earnings || 0) / (totalInvestments || 1)) * 100
  }

  return (
    <>
      <div style={{ filter: blur }}>
        <Header />
        <Page>
          {
            performanceDataChart &&
            <>
              <Flex justifyContent="flex-end">
                <DateRangePicker
                  onChange={(days: [Date, Date]) => setSelectedRangeDays(days) }
                  value={selectedRangeDays}
                />
              </Flex>
              <PerformanceChart
                series={performanceDataChart.series}
                xAxisCategories={performanceDataChart.xAxisCategories}
                yAxisMax={performanceDataChart.yAxisMax}
              />
            </>
          }

          <Flex direction="column" gap={10}>
            <Card
              title="Meu negócio"
              subHeader={(
                <>
                  <Text fontSize={14} fontWeight={300}>Estimativa de lucro mensal</Text>
                  <Text color="green.400">{activeYield || '-'}%</Text>
                  <Icon color="green.400" fontSize="16px" as={FiArrowUp} />
                </>
              )}
              footer={(
                <>
                  <Button as={Link} to="/deposit" isFullWidth colorScheme="primary" height="60px" mr={2}>Comprar</Button>
                  <Button as={Link} to="/withdraw" isFullWidth colorScheme="primary" variant="ghost" height="60px" _hover={{ bg: 'secondaryBackground', color: 'white' }}>Solicitar saque</Button>
                </>
              )}
            >
              <Flex alignItems="center" justifyContent="space-between" wrap={{ lg: 'nowrap', md: 'wrap', base: 'wrap' }}>
                <WalletBalance flag="totalProfit" backgroundColor="secondaryBackground" label="Lucro total" value={wallet?.balance ? toBrl(wallet?.balance as string) : '-'} color="primary.500" helpText="Este é o valor total de lucros você possui disponível." />
                <WalletBalance label="Lucro (%)" value={(earningsPercentage || '0') + '%'} helpText="Esta é a porcentagem de lucros até agora." />
                <WalletBalance label="Total em produtos gerando rendimentos" value={wallet?.activeInvestments ? toBrl(wallet?.activeInvestments as string) : '-'} helpText="Este é o valor total de produtos adquiridos que estão ativos e não expiraram." />
                <WalletBalance label="Total adquirido em produtos" value={wallet?.totalInvestments ? toBrl(wallet?.totalInvestments as string) : '-'} helpText="Este é o valor total de produtos adquiridos." />
              </Flex>
            </Card>
            <Card title="Comprar agora">
              <ProductsList />
            </Card>
          </Flex>
          <Box mt={10}>
            <Card title="Últimas transações">
              {(!transactions || transactions.length === 0) && <Text textAlign="center" paddingY={4} fontSize={14} fontWeight={500} color="white">Você não possui nenhuma transação.</Text>}
              {transactions && transactions.length > 0 && (
                <>
                  {transactions?.map((transaction) => <TransactionItem
                    key={transaction.id}
                    title={transaction.description as string}
                    transactionAt={transaction.createdAt as string}
                    amount={String(transaction.amount)}
                    type={transaction.type}
                    status={transaction.status}
                    cancelReason={transaction.cancelReason}
                    customFields={transaction.customFields}
                  />)}
                  <Button as={Link} to="/history" isFullWidth colorScheme="gray" variant="ghost">Ver todas</Button>
                </>
              )}
            </Card>
          </Box>
        </Page>
        <NewsModal />
      </div>
    </>
  )
}

export default WalletPage
