import { Button } from '@chakra-ui/button'
import { Box, HStack, Text } from '@chakra-ui/layout'
import { Table, Tbody, Td, Th, Thead, Tr } from '@chakra-ui/table'
import { useToast } from '@chakra-ui/toast'
import { format } from 'date-fns/esm'
import React, { useEffect, useRef, useState } from 'react'
import { FiCheck, FiChevronLeft, FiChevronRight, FiX } from 'react-icons/fi'
import { getOrders, updateOrder } from '../../../api'
import Card from '../../../components/card'
import Header from '../../../components/header'
import Page from '../../../components/page'
import { GetOrdersMetaResponse, GetOrdersParams } from '../../../interfaces'
import { Order } from '../../../models'
import { toBrl } from '../../../utils'
import { Link as RouterLink } from 'react-router-dom'
import { AlertDialog, AlertDialogBody, AlertDialogContent, AlertDialogFooter, AlertDialogHeader, AlertDialogOverlay } from '@chakra-ui/modal'

type DialogType = 'approve' | 'cancel'

const AdminOrdersPage = () => {
  const [params, setParams] = useState<GetOrdersParams>({ page: 1 })
  const [meta, setMeta] = useState<GetOrdersMetaResponse>()
  const [orders, setOrders] = useState<Order[]>([])
  const [reload, setReload] = useState<boolean>()
  const toast = useToast()
  const [isOpen, setIsOpen] = React.useState(false)
  const onClose = () => setIsOpen(false)
  const cancelRef = useRef()
  const [dialogType, setDialogType] = useState<DialogType>()
  const [orderId, setOrderId] = useState<string>()
  const [isLoading, setIsLoading] = useState<boolean>()

  useEffect(() => {
    getOrders(params).then(({ data }) => {
      setOrders(data.data.map((order) => ({ ...order, statusDescription: getStatusDescription(order.status) })))
      setMeta(data.meta)
    }).catch(() => {
      toast({
        title: 'Ops!',
        description: 'Houve um erro ao exibir os pedidos, tente novamente.',
        status: 'error',
        position: 'top',
        isClosable: true
      })
    })
  }, [reload])

  function getStatusDescription (status: string): string {
    const statuses: { [key: string]: string } = {
      approved: 'Aprovado',
      pending: 'Pendente',
      canceled: 'Cancelado'
    }

    return statuses?.[status] || status
  }

  function handlePrev () {
    if (meta?.page && meta.page > 1) {
      setParams({ page: meta?.page - 1 })
      setReload(!reload)
    }
  }

  function handleNext () {
    if (meta?.page && meta?.totalPages && meta.page < meta?.totalPages) {
      setParams({ page: meta?.page + 1 })
      setReload(!reload)
    }
  }

  function handleApprove (order: Order) {
    setDialogType('approve')
    setOrderId(order.id)
    setIsOpen(true)
    setIsLoading(false)
  }

  function handleCancel (order: Order) {
    setDialogType('cancel')
    setOrderId(order.id)
    setIsOpen(true)
    setIsLoading(false)
  }

  async function handleApproveOrCancel () {
    if (orderId && dialogType) {
      setIsLoading(true)
      const status = dialogType === 'approve' ? 'approved' : 'canceled'

      try {
        await updateOrder(orderId, { status })
        setIsOpen(false)
        setReload(!reload)
        setIsLoading(false)
        toast({
          title: 'Tudo certo!',
          description: 'O status do pedido foi alterado com sucesso!',
          position: 'top',
          isClosable: true,
          status: 'success'
        })
      } catch (error) {
        setIsOpen(false)
        toast({
          title: 'Erro',
          description: 'Houve um erro ao atualizar o pedido, tente novamente.',
          position: 'top',
          isClosable: true,
          status: 'error'
        })
        setIsLoading(false)
      }
    }
  }

  return (
    <>
      <Header />
      <Page>
        <Card title={`Pedidos (${meta?.total || 0})`}>
          <Table mt={8}>
            <Thead >
              <Tr>
                <Th color="primary.600">Usuário</Th>
                <Th color="primary.600">Produto</Th>
                <Th color="primary.600">Valor</Th>
                <Th color="primary.600">Pagamento</Th>
                <Th color="primary.600">Referência</Th>
                <Th color="primary.600">Status</Th>
                <Th color="primary.600">Pedido em</Th>
                <Th color="primary.600"></Th>
              </Tr>
            </Thead>
            <Tbody>
              {orders && orders?.map((order) => (
                <Tr key={order.id}>
                  <Td>
                    {order?.user?.name || '-'}
                  </Td>
                  <Td>{order.product?.name || '-'}</Td>
                  <Td>
                    {toBrl(String(order.amount))}
                    {order?.paymentMethod?.customFields?.cryptoCode && order?.customFields?.cryptoAmount
                      ? (<Text fontSize={14} fontWeight={600}>{` (${order?.paymentMethod?.customFields?.cryptoCode} ${order?.customFields?.cryptoAmount})`}</Text>)
                      : ''}
                  </Td>
                  <Td>{order?.paymentMethod?.name}</Td>
                  <Td><Text fontSize={14} whiteSpace="pre-wrap" maxW={100}>{order.referenceId || '-'}</Text></Td>
                  <Td>{order?.statusDescription || order.status}</Td>
                  <Td>{format((new Date(order.createdAt)), 'dd/MM/yyyy \'às\' HH:mm')}</Td>
                  <Td>
                    {order.status === 'pending' && (
                      <>
                        <Button onClick={() => handleApprove(order)} size="sm" colorScheme="green.500" w="100%" mb={2}><FiCheck /></Button>
                        <Button onClick={() => handleCancel(order)} size="sm" variant="outline" colorScheme="red" w="100%"><FiX /></Button>
                      </>
                    )}
                  </Td>
                </Tr>
              ))}
            </Tbody>
          </Table>
          <Box mt={8}>
            <HStack justifyContent="center" gap={10}>
              <Button colorScheme="primary" variant="ghost" onClick={handlePrev} disabled={params.page === 1} leftIcon={<FiChevronLeft />}>Anterior</Button>
              <Button colorScheme="primary" variant="ghost" onClick={handleNext} disabled={(meta?.page || 1) >= (meta?.totalPages || 1)} rightIcon={<FiChevronRight />}>Próximo</Button>
            </HStack>
          </Box>
        </Card>
      </Page>
      {cancelRef && (
        <AlertDialog
          isOpen={isOpen}
          leastDestructiveRef={undefined}
          onClose={onClose}
        >
          <AlertDialogOverlay>
            <AlertDialogContent>
              <AlertDialogHeader fontSize="lg" fontWeight="bold">
                {dialogType === 'approve' ? 'Aprovar pedido' : 'Cancelar pedido'}
              </AlertDialogHeader>

              <AlertDialogBody>
                {dialogType === 'approve' ? 'Você tem certeza que deseja aprovar este pedido?' : 'Você tem certeza que deseja cancelar este pedido?' }
              </AlertDialogBody>

              <AlertDialogFooter>
                <Button onClick={onClose}>
                  Voltar
                </Button>
                <Button colorScheme={dialogType === 'approve' ? 'green' : 'red'} onClick={handleApproveOrCancel} ml={3}>
                  {dialogType === 'approve' ? 'Confirmar pedido' : 'Confirmar cancelamento'}
                </Button>
              </AlertDialogFooter>
            </AlertDialogContent>
          </AlertDialogOverlay>
        </AlertDialog>
      )}
    </>
  )
}

export default AdminOrdersPage
