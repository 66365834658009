/* eslint-disable camelcase */
import React from 'react'
import { Box } from '@chakra-ui/react'
import Header from '../../components/header'
import Page from '../../components/page'
import Card from '../../components/card'
import { useUser } from '../../contexts'
import ProfileForm from './components/form'

const ProfilePage = () => {
  const { user, setUser } = useUser()

  return user
    ? (
      <>
        <Header />
        <Page>
          <Box maxW={{ sm: '100%', lg: '3xl' }} margin="auto">
            <Card title="Meu dados">
              <ProfileForm
                user={user}
                setUser={setUser}
              />
            </Card>
          </Box>
        </Page>
      </>
      )
    : false
}

export default ProfilePage
