import { AxiosResponse } from 'axios'
import { UpdateUserRequest, UpdateUserPasswordRequest, AuthTokenResponse, GetUsersResponse } from '../interfaces'
import { User } from '../models'
import { getInstance } from './config'

export const getMe = (): Promise<AxiosResponse<User>> => {
  return getInstance().get('/user')
}

export const updateMe = (data: UpdateUserRequest): Promise<AxiosResponse<User>> => {
  return getInstance().patch('/user', data)
}

export const cancelContract = (): Promise<AxiosResponse<{ success: boolean }>> => {
  return getInstance().post('/user/cancel-contract')
}

export const updatePassword = (data: UpdateUserPasswordRequest): Promise<AxiosResponse<{ success: boolean }>> => {
  return getInstance().patch('/user/password', data)
}

export const getUsers = (params?: any): Promise<AxiosResponse<GetUsersResponse>> => {
  return getInstance().get('/users', { params })
}

export const updateUser = (id: string, data: Partial<User>): Promise<AxiosResponse<User>> => {
  return getInstance().patch(`/users/${id}`, data)
}

export const deleteUser = (id: string): Promise<AxiosResponse<any>> => {
  return getInstance().delete(`/users/${id}`)
}

export const loginAsUser = (id: string): Promise<AxiosResponse<AuthTokenResponse>> => {
  return getInstance().post(`/users/${id}/login-as`)
}
