import { AxiosResponse } from 'axios'
import { WithdrawalsRequest, UpdateWithdrawnRequest } from '../interfaces'
import { Withdrawn } from '../models'
import { getInstance } from './config'

export const getWithdrawals = (): Promise<AxiosResponse<Withdrawn[]>> => {
  return getInstance().get('/withdrawals')
}

export const getAllWithdrawals = (): Promise<AxiosResponse<Withdrawn[]>> => {
  return getInstance().get('/withdrawals/history')
}

export const createWithdrawals = (data: WithdrawalsRequest): Promise<AxiosResponse<Withdrawn>> => {
  return getInstance().post('/withdrawals', data)
}

export const createWithdrawalAllBalance = (data: Partial<WithdrawalsRequest>): Promise<AxiosResponse<Withdrawn>> => {
  return getInstance().post('/withdrawals', { ...data, all_balance: true })
}

export const updateWithdrawn = (id: string, data: UpdateWithdrawnRequest): Promise<AxiosResponse<Withdrawn>> => {
  return getInstance().patch(`/withdrawals/${id}`, data)
}
