/* eslint-disable camelcase */
import React, { useEffect, useState } from 'react'
import { Box, Button, HStack, Icon, Text, useDisclosure, useToast } from '@chakra-ui/react'
import Header from '../../components/header'
import Page from '../../components/page'
import Card from '../../components/card'
import { FiDollarSign } from 'react-icons/fi'
import { Withdrawn } from '../../models'
import { getWithdrawals } from '../../api'
import PageInstructionParagraph from '../../components/page-instructions-paragraph'
import WithdrawalsTable from './components/table'
import WithdrawnModal from './components/withdrawn-modal'
import { Link } from 'react-router-dom'

const WithdrawalsPage = () => {
  const [reloadWithdrawals, setReloadWithdrawals] = useState<boolean>()
  const [withdrawals, setWithdrawals] = useState<Withdrawn[]>()
  const toast = useToast()
  const withdrawnModal = useDisclosure()

  useEffect(() => {
    getWithdrawals().then(({ data }) => {
      setWithdrawals(data)
    }).catch(() => {
      toast({
        title: 'Erro',
        description: 'Houve um erro ao listar os saques, tente novamente.',
        status: 'error',
        position: 'bottom-left',
        isClosable: true
      })
    })
  }, [reloadWithdrawals])

  return (
    <>
      <Header />
      <Page>
        <Card
          title={(
            <>
              <HStack alignItems="center">
                <Box mr={2}>Solicitar saque</Box>
                <Button as={Link} variant="ghost" to="/withdraw/cancel" colorScheme="red" size="xs">Cancelar contrato</Button>
              </HStack>
            </>
          )}

          subHeader={(
            <>
              <Button onClick={withdrawnModal.onOpen} leftIcon={<Icon as={FiDollarSign} />} colorScheme="primary" size="sm">Novo saque</Button>
            </>
          )}
        >
          <>
            <PageInstructionParagraph instructions="Você pode acompanhar o status ou solicitar novos saques." />
            {(!withdrawals || withdrawals.length === 0) && <Text textAlign="center" paddingY={4} fontSize={14} fontWeight={500}>Você não possui solicitações de saque.</Text>}
            {withdrawals && withdrawals.length > 0 && <WithdrawalsTable withdrawals={withdrawals} />}
          </>
        </Card>
      </Page>
      <WithdrawnModal
        modal={withdrawnModal}
        reloadWithdrawals={reloadWithdrawals}
        setReloadWithdrawals={setReloadWithdrawals}
      />
    </>
  )
}

export default WithdrawalsPage
