import { createContext, useContext } from 'react'
import { PaymentMethod } from '../models'

type PaymentMethodsContextType = {
  paymentMethods: PaymentMethod[] | undefined
  setPaymentMethods: (paymentMethods: PaymentMethod[]) => void
}

const initialValue = {
  paymentMethods: undefined,
  setPaymentMethods: () => ({})
}

export const PaymentMethodsContext = createContext<PaymentMethodsContextType>(initialValue)
export const usePaymentMethods = () => useContext(PaymentMethodsContext)
