import { AxiosResponse } from 'axios'
import { FetchArticleResponse } from '../interfaces'
import { Article } from '../models'
import { getInstance } from './config'

export const fetchArticle = (): Promise<AxiosResponse<FetchArticleResponse>> => {
  return getInstance().get('/articles')
}

export const saveArticle = (data: Partial<Article>): Promise<AxiosResponse<Article>> => {
  return getInstance().post('/articles', data)
}
