import React, { useEffect } from 'react'
import { Box, Container } from '@chakra-ui/layout'
import { ACCOUNTS_URL, CLIENT_ID } from '../../config'
import { Spinner } from '@chakra-ui/spinner'
import { cookies } from '../../utils'

const RedirectToAccountsPage = () => {
  useEffect(() => {
    const sponsorReference = cookies.get('sponsor_id')
    window.location.replace(`${ACCOUNTS_URL}/authorize?client=${CLIENT_ID}${sponsorReference ? `&ref=${sponsorReference}` : ''}`)
  }, [])

  return (
    <Container minWidth="sm" py={20}>
      <Box p={10} boxShadow="lg" borderRadius={8} backgroundColor="secondaryBackground" textAlign="center">
        <Spinner color="primary.500" size="lg"/>
      </Box>
    </Container>
  )
}

export default RedirectToAccountsPage
