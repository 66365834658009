import React from 'react'
import { Button, FormControl, FormErrorMessage, FormLabel, Input, Modal, ModalBody, ModalCloseButton, ModalContent, ModalFooter, ModalHeader, ModalOverlay, Textarea, UseDisclosureProps, useToast } from '@chakra-ui/react'
import { Field, Form, Formik } from 'formik'
import { createTerms } from '../../../../api'
import { Term } from '../../../../models'

type Props = {
  modal: UseDisclosureProps
  currentTerms: Term | undefined
  reloadTerms?: boolean
  setReloadTerms?: (reload: boolean) => void
}

const TermModal = ({ modal, reloadTerms, setReloadTerms, currentTerms }: Props) => {
  const toast = useToast()

  return (
    <Modal size="xl" isOpen={modal.isOpen || false} onClose={modal.onClose || (() => ({}))} closeOnOverlayClick={false} blockScrollOnMount={true}>
      <ModalOverlay />
      <ModalContent color="white" backgroundColor="secondaryBackground">
        <ModalHeader>Atualizar termo de compra</ModalHeader>
        <ModalCloseButton />
        <Formik
          initialValues={{
            title: currentTerms?.title,
            terms: currentTerms?.terms
          }}
          validate={(values) => {
            const errors: { title?: string, terms?: string } = {}

            if (!values.title) {
              errors.title = 'Informe o título do termo de compra'
            }

            if (!values.terms) {
              errors.terms = 'Informe o termo de compra'
            }

            return errors
          }}
          onSubmit={async (values, { setSubmitting }) => {
            setSubmitting(true)

            try {
              await createTerms(values)
              setSubmitting(false)
              toast({
                title: 'Tudo certo!',
                description: 'O termo de compra foi atualizado com sucesso!',
                status: 'success',
                position: 'top',
                isClosable: true
              })

              modal?.onClose?.()

              if (setReloadTerms) {
                setReloadTerms(!reloadTerms)
              }
            } catch (error) {
              setSubmitting(false)
              let title = 'Erro'
              let message = 'Houve um erro ao atualizar o termo de compra, tente novamente.'

              if (error?.response) {
                if (error.response?.status === 422) {
                  title = 'Informações inválidas'
                  message = 'Preencha os campos corretamente e tente novamente.'
                }
              }

              toast({
                title,
                description: message,
                status: 'error',
                position: 'top',
                isClosable: true
              })
            }
          }}
        >
          {(props: any) => (
            <Form>
              <ModalBody>
                <Field name="title">
                  {({ field, form }: any) => (
                    <FormControl isRequired isInvalid={form.errors.title && form.touched.title} mb={5}>
                      <FormLabel htmlFor="title">Título</FormLabel>
                      <Input {...field} type="text" id="title" placeholder="Título" />
                      <FormErrorMessage>{form.errors.title}</FormErrorMessage>
                    </FormControl>
                  )}
                </Field>
                <Field name="terms">
                  {({ field, form }: any) => (
                    <FormControl isRequired isInvalid={form.errors.terms && form.touched.terms} mb={5}>
                      <FormLabel htmlFor="terms">Termo de compra</FormLabel>
                      <Textarea {...field} type="number" id="terms" placeholder="Termo de compra" />
                      <FormErrorMessage>{form.errors.terms}</FormErrorMessage>
                    </FormControl>
                  )}
                </Field>
              </ModalBody>
              <ModalFooter>
                <Button variant="ghost" onClick={modal.onClose}>Cancelar</Button>
                <Button type="submit" colorScheme="primary" ml={3} disabled={props.isSubmitting}>Atualizar termo de compra</Button>
              </ModalFooter>
            </Form>
          )}
        </Formik>
      </ModalContent>
    </Modal>
  )
}

export default TermModal
