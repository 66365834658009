import { AxiosResponse } from 'axios'
import { } from '../interfaces'
import { GetActiveYieldResponse } from '../interfaces'
import { Yield } from '../models'
import { getInstance } from './config'

export const getActiveYield = (): Promise<AxiosResponse<GetActiveYieldResponse>> => {
  return getInstance().get('/yields')
}

export const getYields = (): Promise<AxiosResponse<Yield[]>> => {
  return getInstance().get('/yields/history')
}

export const createYield = (data: Partial<Yield>): Promise<AxiosResponse<Yield>> => {
  return getInstance().post('/yields', data)
}
