/* eslint-disable camelcase */
import { Button, Icon, Text, useDisclosure, useToast } from '@chakra-ui/react'
import React, { useEffect, useState } from 'react'
import { FiPlus } from 'react-icons/fi'
import { getBankAccounts } from '../../api'
import Header from '../../components/header'
import Page from '../../components/page'
import { BankAccount } from '../../models'
import Card from '../../components/card'
import BankAccountsTable from './components/table'
import CreateBankAccountModal from './components/create-modal'
import PageInstructionParagraph from '../../components/page-instructions-paragraph'

const BankAccountsPage = () => {
  const [bankAccounts, setBankAccounts] = useState<BankAccount[]>()
  const modal = useDisclosure()
  const [reloadBankAccounts, setReloadBankAccounts] = useState<boolean>()
  const toast = useToast()

  useEffect(() => {
    getBankAccounts().then(({ data }) => {
      setBankAccounts(parseData(data) as BankAccount[])
    }).catch(() => {
      toast({
        title: 'Erro',
        description: 'Houve um erro ao listar as suas contas bancárias, tente novamente.',
        status: 'error',
        position: 'bottom-left',
        isClosable: true
      })
    })
  }, [reloadBankAccounts])

  function parseData (data: BankAccount[]) {
    return data.map((bankAccount) => {
      const accountTypes = {
        CORRENTE: 'Conta Corrente',
        POUPANCA: 'Conta Poupança',
        PIX: 'PIX'
      }

      const pixKeyTypes = {
        EMAIL: 'E-mail',
        PHONE: 'Telefone',
        TAX_NUMBER: 'CPF/CNPJ',
        RANDOM: 'Aleatória'
      }

      return {
        ...bankAccount,
        accountType: accountTypes[bankAccount.accountType] || bankAccount.accountType,
        pixKeyType: pixKeyTypes[bankAccount.pixKeyType] || bankAccount.pixKeyType
      }
    })
  }

  return (
    <>
      <Header />
      <Page>
        <Card
          title="Contas bancárias"
          subHeader={(
            <Button onClick={modal.onOpen} leftIcon={<Icon as={FiPlus} />} colorScheme="primary" size="sm">
              Adicionar conta
            </Button>
          )}
        >
          <>
            <PageInstructionParagraph instructions="Gerencie as suas contas bancárias." />
            {(!bankAccounts || bankAccounts.length === 0) && <Text textAlign="center" paddingY={4} fontSize={14} fontWeight={500}>Você não possui contas bancárias.</Text>}
            {bankAccounts && bankAccounts.length > 0 && <BankAccountsTable bankAccounts={bankAccounts} setBankAccounts={setBankAccounts} />}
          </>
        </Card>
      </Page>
      <CreateBankAccountModal
        modal={modal}
        reloadBankAccounts={reloadBankAccounts}
        setReloadBankAccounts={setReloadBankAccounts}
      />
    </>
  )
}

export default BankAccountsPage
