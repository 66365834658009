import React, { useContext } from 'react'
import { Box, Button, Container, HStack, Link, Menu, MenuButton, MenuItem, MenuList } from '@chakra-ui/react'
import { ChevronDownIcon } from '@chakra-ui/icons'
import { Link as RouterLink } from 'react-router-dom'
import { UserContext } from '../../contexts'

const Header = () => {
  const user = useContext(UserContext)

  return (
    <Box backgroundColor="#273045" boxShadow="base" w="100%">
      <Container className="header-inner" paddingY={5} maxW={{ lg: '85%', base: '100%' }}>
        <HStack align="center" flexDirection={{ lg: 'row', base: 'column' }}>
          <Box className="logo" mb={{ base: 4, lg: 0 }}>
            <RouterLink to="/">
              <img src="/assets/images/logo-header.svg" />
            </RouterLink>
          </Box>
          <Box pl={{ base: 0, lg: 4 }}>
            <HStack flexDirection={{ base: 'column', lg: 'row' }}>
              <Box mb={{ base: 3, lg: 0 }}><Link as={RouterLink} padding={3} color="white" fontWeight={300} to="/">Meu negócio</Link></Box>
              <Box mb={{ base: 3, lg: 0 }}><Link as={RouterLink} padding={3} color="white" fontWeight={300} to="/deposit">Comprar</Link></Box>
              <Box mb={{ base: 3, lg: 0 }}><Link as={RouterLink} padding={3} color="white" fontWeight={300} to="/withdraw">Solicitar saque</Link></Box>
              <Box mb={{ base: 3, lg: 0 }}><Link as={RouterLink} padding={3} color="white" fontWeight={300} to="/support">Suporte</Link></Box>
            </HStack>
          </Box>
          <HStack pt={{ base: 4, lg: 0 }} marginInlineStart={{ lg: 'auto !important', base: 0 }}>
            {user?.user?.isAdmin && (
              <Box>
                <Menu placement="bottom-end">
                  <MenuButton as={Button} rightIcon={<ChevronDownIcon />} variant="ghost" fontWeight={300} colorScheme="white">
                    Admin
                  </MenuButton>
                  <MenuList background="secondaryBackground" color="white">
                    <MenuItem _hover={{ background: '#191F2E' }} _focus={{ background: '#191F2E' }} as={RouterLink} to="/admin/yields">Índices de lucro</MenuItem>
                    <MenuItem _hover={{ background: '#191F2E' }} _focus={{ background: '#191F2E' }} as={RouterLink} to="/admin/withdrawals">Solicitações de saque</MenuItem>
                    <MenuItem _hover={{ background: '#191F2E' }} _focus={{ background: '#191F2E' }} as={RouterLink} to="/admin/products">Produtos</MenuItem>
                    <MenuItem _hover={{ background: '#191F2E' }} _focus={{ background: '#191F2E' }} as={RouterLink} to="/admin/terms">Termos de compra</MenuItem>
                    <MenuItem _hover={{ background: '#191F2E' }} _focus={{ background: '#191F2E' }} as={RouterLink} to="/admin/users">Usuários</MenuItem>
                    <MenuItem _hover={{ background: '#191F2E' }} _focus={{ background: '#191F2E' }} as={RouterLink} to="/admin/articles">Novidades</MenuItem>
                    <MenuItem _hover={{ background: '#191F2E' }} _focus={{ background: '#191F2E' }} as={RouterLink} to="/admin/orders">Pedidos</MenuItem>
                  </MenuList>
                </Menu>
              </Box>
            )}
            <Box>
              <Menu placement="bottom-end">
                <MenuButton as={Button} rightIcon={<ChevronDownIcon />} fontWeight={300} colorScheme="white" variant="ghost">
                  {user?.user?.name || 'Minha conta'}
                </MenuButton>
                <MenuList background="secondaryBackground" color="white">
                  <MenuItem _hover={{ background: '#191F2E' }} _focus={{ background: '#191F2E' }} as={RouterLink} to="/profile" >Meus dados</MenuItem>
                  <MenuItem _hover={{ background: '#191F2E' }} _focus={{ background: '#191F2E' }} as={RouterLink} to="/profile/bank-accounts" >Contas bancárias</MenuItem>
                  <MenuItem _hover={{ background: '#191F2E' }} _focus={{ background: '#191F2E' }} as={RouterLink} to="/password" >Editar senha</MenuItem>
                  <MenuItem _hover={{ background: '#191F2E' }} _focus={{ background: '#191F2E' }} as={RouterLink} to="/logout" >Sair</MenuItem>
                </MenuList>
              </Menu>
            </Box>
          </HStack>
        </HStack>
      </Container>
    </Box>
  )
}

export default Header
