/* eslint-disable camelcase */
import { Radio, RadioGroup, Button, FormControl, FormErrorMessage, FormHelperText, FormLabel, Grid, Input, Modal, ModalBody, ModalCloseButton, ModalContent, ModalFooter, ModalHeader, ModalOverlay, Select, UseDisclosureProps, useToast, Stack } from '@chakra-ui/react'
import { Field, Form, Formik } from 'formik'
import React, { useState } from 'react'
import { createBankAccount, banks } from '../../../api'

type Props = {
  modal: UseDisclosureProps,
  reloadBankAccounts?: boolean
  setReloadBankAccounts?: (reload: boolean) => void
}

const CreateBankAccountModal = ({ modal, reloadBankAccounts, setReloadBankAccounts }: Props) => {
  const toast = useToast()
  const [transferType, setTransferType] = useState<string>('TRANSFER')

  return (
    <Modal size="xl" isOpen={modal.isOpen || false} onClose={modal.onClose || (() => ({}))} closeOnOverlayClick={false} blockScrollOnMount={true}>
      <ModalOverlay />
      <ModalContent color="white" backgroundColor="secondaryBackground">
        <ModalHeader>Adicionar conta bancária</ModalHeader>
        <ModalCloseButton />
        <Formik
          initialValues={{
            bank_name: '',
            account_type: '',
            agency_code: '',
            account_number: '',
            holder_name: '',
            holder_tax_number: '',
            pix_key_type: '',
            pix_key_value: ''
          }}
          validate={(values) => {
            const errors: {
              bank_name?: string
              account_type?: string
              agency_code?: string
              account_number?: string
              holder_name?: string
              holder_tax_number?: string
              pix_key_type?: string
              pix_key_value?: string
            } = {}

            if (transferType === 'TRANSFER') {
              if (!values.bank_name) {
                errors.bank_name = 'Informe o banco'
              }

              if (!values.account_type) {
                errors.account_type = 'Informe o tipo de conta'
              }

              if (!values.agency_code) {
                errors.agency_code = 'Informe o número da agência'
              }

              if (!values.account_number) {
                errors.account_number = 'Informe o número da conta'
              }
            }

            if (transferType === 'PIX') {
              if (!values.pix_key_type) {
                errors.pix_key_type = 'Informe o tipo da chave PIX'
              }

              if (!values.pix_key_value) {
                errors.pix_key_value = 'Informe o valor da chave PIX'
              }
            }

            if (!values.holder_name) {
              errors.holder_name = 'Informe o titular da conta'
            }

            if (!values.holder_tax_number) {
              errors.holder_tax_number = 'Informe o CPF/CNPJ do titular'
            }

            return errors
          }}
          onSubmit={async (values, { setSubmitting }) => {
            setSubmitting(true)

            try {
              await createBankAccount({
                ...values,
                account_type: transferType === 'PIX' ? 'PIX' : values.account_type
              })

              setSubmitting(false)
              toast({
                title: 'Tudo certo!',
                description: 'A sua conta bancária foi cadastrada com sucesso!',
                status: 'success',
                position: 'top',
                isClosable: true
              })
              modal?.onClose?.()

              if (setReloadBankAccounts) {
                setReloadBankAccounts(!reloadBankAccounts)
              }
            } catch (error) {
              setSubmitting(false)
              let title = 'Erro'
              let message = 'Houve um erro ao cadastrar a conta bancária, tente novamente.'

              if (error?.response) {
                if (error.response?.status === 422) {
                  title = 'Informações inválidas'
                  message = 'Preencha os campos corretamente e tente novamente.'
                }
              }

              toast({
                title,
                description: message,
                status: 'error',
                position: 'top',
                isClosable: true
              })
            }
          }}
        >
          {(props: any) => (
            <Form>
              <ModalBody>
                <RadioGroup onChange={(val) => setTransferType(val as string)} value={transferType} mb={5}>
                  <Stack spacing={5} direction="row">
                    <Radio value="TRANSFER">Transferência bancária</Radio>
                    <Radio value="PIX">PIX</Radio>
                  </Stack>
                </RadioGroup>
                {transferType === 'TRANSFER' && (
                  <>
                    <Grid gridTemplateColumns="2fr 1fr" gap={5}>
                      <Field name="bank_name">
                        {({ field, form }: any) => (
                          <FormControl isRequired isInvalid={form.errors.bank_name && form.touched.bank_name} mb={5}>
                            <FormLabel htmlFor="bank_name">Banco</FormLabel>
                            <Select {...field} id="bank_name">
                              <option value="">Selecione</option>
                              {banks.map((bank) => <option key={bank.code} value={bank.name}>{bank.name}</option>)}
                            </Select>
                            <FormErrorMessage>{form.errors.bank_name}</FormErrorMessage>
                          </FormControl>
                        )}
                      </Field>
                      <Field name="account_type">
                        {({ field, form }: any) => (
                          <FormControl isRequired isInvalid={form.errors.account_type && form.touched.account_type} mb={5}>
                            <FormLabel htmlFor="account_type">Tipo de conta</FormLabel>
                            <Select {...field} id="account_type">
                              <option value="">Selecione</option>
                              <option value="CORRENTE">Conta Corrente</option>
                              <option value="POUPANCA">Conta Poupança</option>
                            </Select>
                            <FormErrorMessage>{form.errors.account_type}</FormErrorMessage>
                          </FormControl>
                        )}
                      </Field>
                    </Grid>
                    <Grid gridTemplateColumns="1fr 1.5fr" gap={5}>
                      <Field name="agency_code">
                        {({ field, form }: any) => (
                          <FormControl isRequired isInvalid={form.errors.agency_code && form.touched.agency_code} mb={5}>
                            <FormLabel htmlFor="agency_code">Agência</FormLabel>
                            <Input {...field} type="tel" id="agency_code" placeholder="Agência" />
                            <FormErrorMessage>{form.errors.agency_code}</FormErrorMessage>
                          </FormControl>
                        )}
                      </Field>
                      <Field name="account_number">
                        {({ field, form }: any) => (
                          <FormControl isRequired isInvalid={form.errors.account_number && form.touched.account_number} mb={5}>
                            <FormLabel htmlFor="account_number">Conta (com dígito)</FormLabel>
                            <Input {...field} type="tel" id="account_number" placeholder="Conta" />
                            <FormErrorMessage>{form.errors.account_number}</FormErrorMessage>
                          </FormControl>
                        )}
                      </Field>
                    </Grid>
                  </>
                )}
                {transferType === 'PIX' && (
                  <Grid gridTemplateColumns="1fr 1.5fr" gap={5}>
                    <Field name="pix_key_type">
                      {({ field, form }: any) => (
                        <FormControl isRequired isInvalid={form.errors.pix_key_type && form.touched.pix_key_type} mb={5}>
                          <FormLabel htmlFor="pix_key_type">Tipo de chave</FormLabel>
                          <Select {...field} id="pix_key_type">
                            <option value="">Selecione</option>
                            <option value="PHONE">Telefone</option>
                            <option value="EMAIL">E-mail</option>
                            <option value="TAX_NUMBER">CPF/CNPJ</option>
                            <option value="RANDOM">Aleatória</option>
                          </Select>
                          <FormErrorMessage>{form.errors.pix_key_type}</FormErrorMessage>
                        </FormControl>
                      )}
                    </Field>
                    <Field name="pix_key_value">
                      {({ field, form }: any) => (
                        <FormControl isRequired isInvalid={form.errors.pix_key_value && form.touched.pix_key_value} mb={5}>
                          <FormLabel htmlFor="pix_key_value">Chave PIX</FormLabel>
                          <Input {...field} type="tel" id="pix_key_value" placeholder="Chave PIX" />
                          <FormErrorMessage>{form.errors.pix_key_value}</FormErrorMessage>
                        </FormControl>
                      )}
                    </Field>
                  </Grid>
                )}
                <Field name="holder_name">
                  {({ field, form }: any) => (
                    <FormControl isRequired isInvalid={form.errors.holder_name && form.touched.holder_name} mb={5}>
                      <FormLabel htmlFor="holder_name">Nome do titular</FormLabel>
                      <Input {...field} type="text" id="holder_name" placeholder="Nome do titular" />
                      <FormHelperText>Utilize a razão social para pessoa jurídica.</FormHelperText>
                      <FormErrorMessage>{form.errors.holder_name}</FormErrorMessage>
                    </FormControl>
                  )}
                </Field>
                <Field name="holder_tax_number">
                  {({ field, form }: any) => (
                    <FormControl isRequired isInvalid={form.errors.holder_tax_number && form.touched.holder_tax_number} mb={5}>
                      <FormLabel htmlFor="holder_tax_number">CPF/CNPJ do titular</FormLabel>
                      <Input {...field} type="text" id="holder_tax_number" placeholder="CPF/CNPJ do titular" />
                      <FormErrorMessage>{form.errors.holder_tax_number}</FormErrorMessage>
                    </FormControl>
                  )}
                </Field>
              </ModalBody>
              <ModalFooter>
                <Button variant="ghost" onClick={modal.onClose}>Cancelar</Button>
                <Button type="submit" colorScheme="primary" ml={3} disabled={props.isSubmitting}>Salvar</Button>
              </ModalFooter>
            </Form>
          )}
        </Formik>
      </ModalContent>
    </Modal>
  )
}

export default CreateBankAccountModal
