import { AxiosResponse } from 'axios'
import { CreateOrderResponse, CreateOrderRequest, GetOrdersResponse, GetOrdersParams, UpdateOrderRequest } from '../interfaces'
import { Order } from '../models'
import { getInstance } from './config'

export const getOrders = (params?: GetOrdersParams): Promise<AxiosResponse<GetOrdersResponse>> => {
  return getInstance().get('/orders/history', { params })
}

export const createOrder = (data: CreateOrderRequest): Promise<AxiosResponse<CreateOrderResponse>> => {
  return getInstance().post('/orders', data)
}

export const updateOrder = (id: string, data: UpdateOrderRequest): Promise<AxiosResponse<Order>> => {
  return getInstance().patch(`/orders/${id}`, data)
}

export const updateOrderCryptoHash = (id: string, hash: string): Promise<AxiosResponse<Order>> => {
  return getInstance().post(`/orders/${id}/crypto-hash`, { hash })
}
