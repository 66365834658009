import moment from 'moment'

export const getDaysInRange = ({
  fromDate,
  toDate
}: {
  fromDate: Date;
  toDate: Date;
}) => {
  const dates = []
  const now = moment(fromDate).clone()

  while (now.isSameOrBefore(moment(toDate))) {
    dates.push(now.format('DD/MM/YYYY'))
    now.add(1, 'days')
  }

  return dates
}
