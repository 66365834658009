import React from 'react'
import { Box, Heading, HStack } from '@chakra-ui/react'

type Props = {
  title: string | React.ReactNode
  subHeader?: React.ReactNode
  children: React.ReactNode
  footer?: React.ReactNode
}

const Card = (props: Props) => (
  <Box backgroundColor="transparent" p={0} borderRadius="8px" boxShadow="base" mb={5}>
    <HStack marginBottom={5} justifyContent="space-between" spacing={5}>
      <Heading as="h4" size="sm" fontWeight={300} fontSize={20} color="white">{props.title}</Heading>
      {props?.subHeader && <HStack fontWeight="600" fontSize="sm" color="white" >{props.subHeader}</HStack>}
    </HStack>
    {props.children}
    {props?.footer && <HStack gap={5} mt={5}>{props.footer}</HStack>}
  </Box>
)

export default Card
